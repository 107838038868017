import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

const linkStyles = css`
  align-items: center;
  border-bottom: var(--border-pale);
  display: flex;
  font-size: var(--font-baseline);
  justify-content: space-between;

  /** @todo This fixed value will be replace with a css variable when that task is ready */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  padding: 20px;
  width: 100%;
`;

export const ExternalLink = styled.a`
  ${linkStyles}
`;

export const InternalLink = styled(Link)`
  ${linkStyles}
`;

export const Button = styled.button`
  ${linkStyles}
`;

export const LinkDisabled = styled.div`
  ${linkStyles}
  opacity: 0.5;
  pointer-events: none;
`;
