import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Stack } from '@mui/material';
import React, { FC, useId } from 'react';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

/**
 * Skeleton view for topic basic info,
 * visible above the feed
 *
 * @returns The component itself
 */
const SkeletonTopicBasic: FC = () => {
  const descriptionId = useId();

  return (
    <Styled.Wrapper aria-describedby={descriptionId} role="progressbar">
      <Stack alignItems="center" direction="row" spacing={1}>
        <LockOutlinedIcon />
        <Styled.SkeletonTitle />
      </Stack>
      <Styled.SkeletonDescription />
      <Styled.LoadingDescription id={descriptionId}>
        {translate('LOADING__TOPIC_BASIC')}
      </Styled.LoadingDescription>
    </Styled.Wrapper>
  );
};

export default SkeletonTopicBasic;
