/**
 * @file ROQ token cookie storage
 */

import cookie from 'js-cookie';

import { PREFERENCE_COOKIE_OPTIONS_COMMON } from '../../constants/preferences';
import { Hotel } from '../../generated/graphql';

/**
 * ROQ token cookie key
 */
const COOKIE_ROQ = 'roq-token';

/**
 * Generate ROQ token cookie key based on the hotel ID. This way
 * we can keep one token per hotel so that we don't have to
 * fetch a new one on every hotel change.
 *
 * @param hotelId ID of the hotel
 * @returns       cookie key
 */
const generateKey = (hotelId: Hotel['id']) => `${COOKIE_ROQ}:${hotelId}`;

/**
 * Get ROQ token from cookie
 *
 * @param hotelId ID of the hotel
 * @returns       ROQ token or undefined
 */
export const getRoqToken = (hotelId: Hotel['id'] | null) => {
  if (hotelId === null) {
    return undefined;
  }

  return cookie.get(generateKey(hotelId));
};

/**
 * Remove all ROQ tokens from cookies by prefix.
 */
export const removeAllRoqTokens = () => {
  const allCookies = cookie.get();
  Object.keys(allCookies).forEach(key => {
    if (key.startsWith(COOKIE_ROQ)) {
      cookie.remove(key);
    }
  });
};

/**
 * Remove ROQ token from cookie
 *
 * @param hotelId ID of the hotel
 * @returns       void
 */
export const removeRoqToken = (hotelId: Hotel['id']) => {
  return cookie.remove(generateKey(hotelId));
};

/**
 * Set ROQ token in cookie
 *
 * @param hotelId ID of the hotel
 * @param token   ROQ token
 * @returns       void
 */
export const setRoqToken = (hotelId: Hotel['id'], token: string) => {
  return cookie.set(
    generateKey(hotelId),
    token,
    PREFERENCE_COOKIE_OPTIONS_COMMON,
  );
};
