import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { TopicWithMembersFragment } from '../../../../generated/graphql';
import translate from '../../../../i18n/translate';
import { getCanUpdateTopic } from '../../../../utils/permissions/topic';
import LoadingTopicBasic from '../../../Loading/TopicBasic';
import TopicVisibilityIcon from '../TopicVisibilityIcon';

import * as Styled from './styled';

type Props = {
  isLoading: boolean;
  topic: TopicWithMembersFragment;
  topicDetailsUrl: string;
};

/**
 * Section for topic title, description and topic edit button
 *
 * @param props                 Props passed to the component
 * @param props.isLoading       Whether the data is being fetched
 * @param props.topic           Current topic
 * @param props.topicDetailsUrl Url to current topic details
 * @returns                     The component itself
 */
const TopicInfoSection: FC<Props> = ({ isLoading, topic, topicDetailsUrl }) => {
  const { description, isPrivate, title } = topic;

  return (
    <>
      {isLoading ? (
        <LoadingTopicBasic />
      ) : (
        <Styled.TopicInfoWrapper>
          <Stack alignItems="center" direction="row" spacing={1}>
            <TopicVisibilityIcon isPrivate={isPrivate} />
            <Typography variant="h2">{title}</Typography>
          </Stack>
          <Styled.TopicDescription>{description}</Styled.TopicDescription>
        </Styled.TopicInfoWrapper>
      )}
      {getCanUpdateTopic(topic) === true && (
        <Styled.EditButton
          aria-label={translate('TOPIC__EDIT')}
          component={RouterLink}
          to={topicDetailsUrl}
        >
          <EditOutlinedIcon />
        </Styled.EditButton>
      )}
    </>
  );
};

export default TopicInfoSection;
