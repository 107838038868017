import { Button, ButtonProps, styled } from '@mui/material';

import { LinkProps } from 'react-router-dom';

import breakpoints from '../../../constants/breakpoints';

export const Footer = styled('footer')(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, auto)',
  justifyItems: 'start',
  maxWidth: '26rem',
  // Between those breakpoints we need two rows for the links/button, because the
  // task cards get narrow
  [theme.breakpoints.between(breakpoints.tabletS, breakpoints.tabletL)]: {
    gridTemplateColumns: '1fr 1fr',
    maxWidth: 'none',
  },
}));

export const MuiButton = styled(Button)<ButtonProps & LinkProps>(
  ({ theme }) => ({
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
  }),
);
