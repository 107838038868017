import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'urql';

import FeedHeader from '../../components/Common/FeedHeader';
import {
  TopicWithMembersDocument,
  TopicWithMembersFragment,
} from '../../generated/graphql';
import useParamTopicId from '../../hooks/router/params/useParamTopicId';
import translate from '../../i18n/translate';
import { reportError } from '../../services/reporting';

import mapStateToProps from './mapStateToProps';
import { Props } from './props';

/**
 * Topic data to use while loading
 */
const fallbackTopic: TopicWithMembersFragment = {
  description: '',
  groups: [],
  id: '',
  isPrivate: true,
  members: [],
  title: '',
  unreadMessages: 0,
};

/**
 * The container for the topic header on the home page
 * Fetches the currently selected topic
 *
 * @param props                   props passed to the component
 * @param props.networkTopicBasic Whether to override GraphQL fetching state (debug option)
 * @returns                       The component itself
 */
const TopicHeaderContainer: FC<Props> = ({ networkTopicBasic }) => {
  const topicId = useParamTopicId();

  const [{ data, error, fetching }] = useQuery({
    query: TopicWithMembersDocument,
    variables: { id: topicId ?? '' },
  });

  if (error) {
    reportError(error);
    return <div>{translate('PLACEHOLDER__ERROR')}</div>;
  }

  const isLoading = fetching === true || networkTopicBasic === true;

  return (
    <FeedHeader isLoading={isLoading} topic={data?.topic ?? fallbackTopic} />
  );
};

export default connect(mapStateToProps)(TopicHeaderContainer);
