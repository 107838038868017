import React, { FC } from 'react';
import { useQuery } from 'urql';

import { ReservationsDocument } from '../../generated/graphql';
import translate from '../../i18n/translate';
import { reportError } from '../../services/reporting';

import * as Styled from './styled';

/**
 * The container for the HeaderMobile's right side
 *
 * @returns The component itself
 */
const HeaderRightSideContentContainer: FC = () => {
  const [{ error, fetching }, executeQuery] = useQuery({
    query: ReservationsDocument,
    variables: {},
  });

  if (error) {
    reportError(error);
    return null;
  }

  if (fetching) {
    return null;
  }

  return (
    <Styled.RefreshButton
      onClick={() => executeQuery({ requestPolicy: 'cache-and-network' })}
      type="button"
    >
      {translate('GENERAL__REFRESH')}
    </Styled.RefreshButton>
  );
};

export default HeaderRightSideContentContainer;
