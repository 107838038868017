import React, { FC } from 'react';
import { useQuery } from 'urql';

import SmartViewsContent from '../../components/CollapsibleLists/SmartViews/SmartViewsContent';
import SkeletonColumnList from '../../components/Loading/SkeletonList';
import {
  SearchQueriesDocument,
  SearchQueryType,
} from '../../generated/graphql';
import { reportError, reportInfo } from '../../services/reporting';
import { generateSmartViewsLinks } from '../../utils/smartViews';

import Error from './Error';

export type Props = {
  isActive: boolean;
};

/**
 * Content for the SmartViews component
 *
 * @param props          Props passed to the component
 * @param props.isActive Boolean that tells us if the collapsible is active
 * @returns              The component itself
 */
const SmartViewsContainer: FC<Props> = ({ isActive }) => {
  const [{ data, fetching, error }, executeQuery] = useQuery({
    query: SearchQueriesDocument,
    variables: { type: SearchQueryType.SmartView },
  });

  /**
   * Handles click/tap on reload button inside Error component
   * Tries to fetch smart views
   */
  const handleReload = () => {
    executeQuery({ requestPolicy: 'cache-and-network' });
  };

  if (fetching) {
    return <SkeletonColumnList numberOfLines={3} />;
  }

  if (error) {
    reportError(error);
    return <Error onReload={handleReload} />;
  }

  if (!data) {
    reportInfo(
      'There was no error, but there was also no data for the smart views search queries query',
    );
    return null;
  }

  /**
   * Smart views links created from the BE data
   */
  const smartViewsLinks = generateSmartViewsLinks(data.searchQueries);

  return (
    <SmartViewsContent isActive={isActive} smartViewsLinks={smartViewsLinks} />
  );
};

export default SmartViewsContainer;
