// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roq-chat-conversation-list-sidebar__header {
  font-size: var(--font-baseline);
  font-weight: var(--font-semibold);
  padding: var(--spacing-small);
}

.roq-chat-conversation-list-sidebar__header__top {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  gap: var(--spacing-small);
  justify-content: left;
}

.roq-chat-conversation-list-sidebar__header__top__action {
  --spacing-1-px: 1px;

  background-color: var(--color-button-disabled);
  border-radius: var(--border-radius-small);
  color: var(--color-primary-lighter);
  height: var(--size-indicator-icon);
  padding: var(--spacing-1-px);
  width: var(--size-indicator-icon);
}

.roq-chat-conversation-list-sidebar__header__top__action svg {
  transform: scale(0.8);
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/_sidebar-header.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,iCAAiC;EACjC,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,2BAA2B;EAC3B,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;;EAEnB,8CAA8C;EAC9C,yCAAyC;EACzC,mCAAmC;EACnC,kCAAkC;EAClC,4BAA4B;EAC5B,iCAAiC;AACnC;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".roq-chat-conversation-list-sidebar__header {\n  font-size: var(--font-baseline);\n  font-weight: var(--font-semibold);\n  padding: var(--spacing-small);\n}\n\n.roq-chat-conversation-list-sidebar__header__top {\n  align-items: center;\n  display: flex;\n  flex-direction: row-reverse;\n  gap: var(--spacing-small);\n  justify-content: left;\n}\n\n.roq-chat-conversation-list-sidebar__header__top__action {\n  --spacing-1-px: 1px;\n\n  background-color: var(--color-button-disabled);\n  border-radius: var(--border-radius-small);\n  color: var(--color-primary-lighter);\n  height: var(--size-indicator-icon);\n  padding: var(--spacing-1-px);\n  width: var(--size-indicator-icon);\n}\n\n.roq-chat-conversation-list-sidebar__header__top__action svg {\n  transform: scale(0.8);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
