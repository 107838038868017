import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';

import useParamTopicId from '../../../../hooks/router/params/useParamTopicId';

import { MessageCommon } from '../../../../models/message';
import { getMessageDetailsUrl } from '../../../../routes/urls/message';

import * as Styled from './styled';

export type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  hashValue: string;
  label: string;
  messageId: MessageCommon['id'];
  shouldBeLink: boolean;
};

/**
 * Show a single message in the feed
 *
 * @param props              Props passed to the component
 * @param props.children     children
 * @param props.className    styled-components generated class name, needed for styling
 * @param props.hashValue    Id of targeted html element
 * @param props.label        Aria label
 * @param props.messageId    Message id
 * @param props.shouldBeLink Whether the component should render as a link or not
 * @returns                  The component itself
 */
const CustomHashLink: FC<Props> = ({
  children,
  className,
  hashValue,
  label,
  messageId,
  shouldBeLink,
}) => {
  const location = useLocation();
  const topicId = useParamTopicId();
  const hotelId = useParamHotelId();

  if (shouldBeLink) {
    const baseUrl = getMessageDetailsUrl(hotelId, location, messageId, topicId);

    return (
      <Styled.CustomHashLink
        aria-label={label}
        className={className}
        to={baseUrl + hashValue}
      >
        {children}
      </Styled.CustomHashLink>
    );
  }

  return (
    <Styled.Wrapper aria-label={label} className={className}>
      {children}
    </Styled.Wrapper>
  );
};

export default CustomHashLink;
