/**
 * @file contains functions for creating random UUIDv4
 */

// UUIDv4 pattern
const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

/**
 * Generate a random number
 * If crypto is supported, use that; otherwise, use Math.random()
 *
 * @param max Maximum value to use
 * @returns   The random number
 */
const getRandomNumber = (max = 16) => {
  // Jest doesn't support globalThis.crypto
  if ('crypto' in globalThis && 'getRandomValues' in globalThis.crypto) {
    const array = new Uint32Array(1);
    crypto.getRandomValues(array) ?? Math.random();
    return array[0] % max;
  }

  return Math.random() * max;
};

/**
 * Generate UUIDv4 string using common APIs
 *
 * @returns Valid UUIDv4 string
 */
const uuidPolyfill = () => {
  let d1 = Date.now();
  let d2 = (globalThis?.performance.now() ?? 0) * 1000;

  return pattern.replace(/[xy]/g, function (c) {
    // random number between 0 and 16
    let r = getRandomNumber();

    if (d1 > 0) {
      // Use timestamp until depleted
      r = (d1 + r) % 16 | 0;
      d1 = Math.floor(d1 / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

/**
 * Generate UUIDv4 string,
 * using crypto where supported and common APIs where not (Safari)
 *
 * @returns Valid UUIDv4 string
 */
const generateUuid = (): string => {
  // Check if randomUUID is supported (currently not supported by safari)
  // Also, Jest doesn't support globalThis.crypto
  const isSupported =
    'crypto' in globalThis && 'randomUUID' in globalThis.crypto;

  const uuidGenerated = isSupported
    ? globalThis.crypto.randomUUID()
    : uuidPolyfill();

  return uuidGenerated;
};

export default generateUuid;
