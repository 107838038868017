import React, { FC } from 'react';

import LeftSidebar from '../components/LeftSidebar';
import SettingsNavigation from '../components/SettingsNavigation';

import HotelInfoContainer from './hotelInfo';

type Props = {
  shouldShowDesktop: boolean;
};

/**
 * The container for the left sidebar in the /settings
 *
 * @param props                   Props passed to te component
 * @param props.shouldShowDesktop Whether LeftSidebar should be visible on desktops or not
 * @returns                       The component itself
 */
const LeftSidebarSettingsContainer: FC<Props> = ({ shouldShowDesktop }) => {
  return (
    <LeftSidebar shouldShowDesktop={shouldShowDesktop}>
      <HotelInfoContainer />
      <SettingsNavigation />
    </LeftSidebar>
  );
};

export default LeftSidebarSettingsContainer;
