import { Box } from '@mui/material';
import React, { FC, useEffect, useRef } from 'react';
import { useMutation, useQuery } from 'urql';

import HeaderMobile from '../../../components/HeaderMobile';
import SkeletonMessageFull from '../../../components/Loading/MessageFull';
import Message from '../../../components/Message';
import {
  ChecklistItemCreateDocument,
  ChecklistItemDeleteDocument,
  ChecklistItemReorderDocument,
  ChecklistItemSetStateDocument,
  ChecklistItemSetTextDocument,
  MessageFullDocument,
  MessageUpdateDocument,
} from '../../../generated/graphql';
import useTranslation from '../../../hooks/store/useTranslation';
import useRedirectOnError from '../../../hooks/useRedirectOnError';

import translate from '../../../i18n/translate';

import { reportApiErrors } from '../../../utils/error';
import useDetailsScroll from '../hooks/useDetailsScroll';
import { PropsInner } from '../props';

/**
 * Used in right sidebar (and has a separate screen on small screens)
 * And shows full message details:
 * - Message title
 * - Message text
 * - Creator detailed info
 * - Full assignee list
 * - Read By data
 * - All labels
 * - Due date
 * - Full checklist
 *
 * @param props                       Props passed to the component
 * @param props.messageId             The ID of the message that we want to show the details for
 * @param props.networkMessageDetails Whether the debug option for fetching this is enabled
 * @returns                           The container itself
 */
const MessageOverview: FC<PropsInner> = ({
  messageId,
  networkMessageDetails,
}) => {
  const handleErrorRedirect = useRedirectOnError();
  const [{ data, error, fetching }] = useQuery({
    query: MessageFullDocument,
    variables: {
      messageId,
    },
  });

  const translation = useTranslation(messageId);

  const overviewRef = useRef<HTMLDivElement>(null);
  useDetailsScroll(data, overviewRef);

  const [, requestChecklistCreate] = useMutation(ChecklistItemCreateDocument);
  const [, requestChecklistDelete] = useMutation(ChecklistItemDeleteDocument);
  const [, requestChecklistReorder] = useMutation(ChecklistItemReorderDocument);
  const [, requestChecklistSetState] = useMutation(
    ChecklistItemSetStateDocument,
  );
  const [, requestChecklistSetText] = useMutation(ChecklistItemSetTextDocument);
  const [, messageUpdateMutation] = useMutation(MessageUpdateDocument);

  /**
   * Make a request to update the message title
   *
   * @param title New message title
   */
  const requestTitleUpdate = (title: string) => {
    messageUpdateMutation({
      data: { title },
      id: messageId,
    }).catch(reportApiErrors);
  };

  /**
   * Make a request to update the message text
   *
   * @param text New message text
   */
  const requestTextUpdate = (text: string) => {
    messageUpdateMutation({
      data: { text },
      id: messageId,
    }).catch(reportApiErrors);
  };

  useEffect(() => {
    if (error !== undefined) {
      handleErrorRedirect(error);
    }
  }, [error, handleErrorRedirect]);

  if (error !== undefined) {
    return null;
  }

  if (
    data === null ||
    data === undefined ||
    fetching ||
    networkMessageDetails
  ) {
    return <SkeletonMessageFull />;
  }

  return (
    <Box ref={overviewRef}>
      <HeaderMobile
        backUrl={true}
        isSticky={true}
        title={translate('GENERAL__ACTIVITY')}
      />
      <Message
        message={data.message!}
        mode="full"
        requestChecklistCreate={requestChecklistCreate}
        requestChecklistDelete={requestChecklistDelete}
        requestChecklistReorder={requestChecklistReorder}
        requestChecklistSetState={requestChecklistSetState}
        requestChecklistSetText={requestChecklistSetText}
        requestTextUpdate={requestTextUpdate}
        requestTitleUpdate={requestTitleUpdate}
        translation={translation}
      />
    </Box>
  );
};

export default MessageOverview;
