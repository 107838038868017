import { css, styled } from 'styled-components';

import { triangle } from '../../../styles/common';
import Avatar from '../../Common/Avatar';
import Triangle from '../../Common/Icon/Triangle';

const dropdownTriangleSize = 9;
const dropdownTriangleBorderSize = 2;

type DropdownWrapperProps = {
  'data-is-open': boolean;
};

export const TriangleIcon = styled(Triangle)`
  margin-left: var(--spacing-tiny);
  margin-right: var(--spacing-tiny);
`;

export const DropdownWrapper = styled.ul<DropdownWrapperProps>`
  background-color: var(--color-white);
  border: var(--border-pale);
  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow-1);
  color: var(--color-text-regular);
  opacity: ${props => (props['data-is-open'] ? 1 : 0)};
  padding: var(--spacing-tiny);
  position: absolute;
  right: -30%;
  top: calc(100% + ${dropdownTriangleSize}px + 8px);
  transition: opacity var(--preference-transition-duration-normal);
  visibility: ${props => (props['data-is-open'] ? 'visible' : 'hidden')};

  /* This is needed in order for dropdown menu to go above the grid and not under it */
  z-index: var(--z-modals);

  &::before,
  &::after {
    bottom: 100%;
    content: '';
    position: absolute;
    right: 23px;
  }

  &::after {
    ${triangle({ direction: 'up', size: dropdownTriangleSize })}
  }

  &::before {
    ${triangle({
      color: 'var(--color-border-light)',
      direction: 'up',
      size: dropdownTriangleSize + dropdownTriangleBorderSize,
    })}
    transform: translateX(${dropdownTriangleBorderSize}px);
  }
`;

export const MenuItem = styled.li`
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: var(--color-hover-light);
  }
`;

export const Wrapper = styled.div`
  align-items: stretch;
  display: flex;
  position: relative;
`;

type ProfileImageProps = {
  'data-is-open': boolean;
};

export const ProfileImage = styled(Avatar)<ProfileImageProps>`
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  border-radius: var(--border-radius-small);

  &:hover {
    background-color: var(--color-text-alt);
    border-color: var(--color-text-alt);
    color: var(--color-primary);
    transition:
      background-color var(--preference-transition-duration-normal),
      color var(--preference-transition-duration-normal);
  }

  ${props =>
    props['data-is-open'] &&
    css`
      background-color: var(--color-text-alt);
      border-color: var(--color-text-alt);
      color: var(--color-primary);
    `}
`;

export const Button = styled.button`
  align-items: center;
  display: inline-flex;
`;

export const Link = styled.a`
  display: block;
  padding: var(--spacing-small) var(--spacing-tiny);
  padding-right: var(--spacing-huge);
`;

export const MenuButton = styled.button`
  font-size: var(--font-baseline);
  padding: var(--spacing-small) var(--spacing-tiny);
  padding-right: var(--spacing-huge);
`;
