/**
 * @file contains hook that handles clicking outside of ref to sidebars
 */

import { RefObject, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useClickOutside from '../../../hooks/useClickOutside';
import { getCloseSidebarUrl } from '../../../routes/urls/searchParams/sidebar';

type Props = {
  leftSidebarOptions?: {
    stopExec: boolean;
  };
  leftSidebarRef: RefObject<HTMLDivElement>;
  rightSidebarOptions?: {
    onClick: () => void;
    stopExec: boolean;
  };
  rightSidebarRef: RefObject<HTMLDivElement>;
};

/**
 * Custom hook that handles click outside of sidebar refs
 *
 * @param props                     Props passed to the hook
 * @param props.leftSidebarOptions  Options passed to the useClickOutside hook for left sidebar
 * @param props.leftSidebarRef      Ref that we listen to for outside clicks (left sidebar)
 * @param props.rightSidebarOptions Options passed to the useClickOutside hook for right sidebar
 * @param props.rightSidebarRef     Ref that we listen to for outside clicks (right sidebar)
 */
const useSidebarsClickOutside = ({
  leftSidebarOptions,
  leftSidebarRef,
  rightSidebarOptions,
  rightSidebarRef,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useClickOutside({
    onClick: useCallback(() => {
      navigate(getCloseSidebarUrl(location));
    }, [location, navigate]),
    ref: leftSidebarRef,
    stopExec: leftSidebarOptions ? leftSidebarOptions.stopExec : true,
  });

  useClickOutside({
    onClick: rightSidebarOptions?.onClick,
    ref: rightSidebarRef,
    stopExec: rightSidebarOptions ? rightSidebarOptions.stopExec : true,
  });
};

export default useSidebarsClickOutside;
