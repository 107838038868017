import { styled } from 'styled-components';

import devices from '../../styles/devices';

export const Nav = styled.nav`
  border-top: var(--border-dark);
  position: relative;
  z-index: var(--z-bottom-nav);

  @media ${devices.tabletS} {
    display: none;
  }
`;

export const FloatingButtonWrapper = styled.div`
  align-self: flex-end;
  bottom: 100%;
  margin-bottom: var(--spacing-medium);
  margin-left: auto;
  margin-right: var(--spacing-small);
  position: absolute;
  right: var(--spacing-medium);
  text-align: right;
`;
