/**
 * @file Hook for marking a post as read
 */

import { useEffect } from 'react';

import { useMutation, useQuery } from 'urql';

import {
  MessagesReadDocument,
  Topic,
  TopicsListDocument,
} from '../../../../generated/graphql';

import useIsAppInView from '../../../../hooks/useIsAppInView';

import { getShouldAdd, getShouldRemove } from './helpers';
import { UseMessageReadParams } from './types';
import useMessagesReadQueueReducer from './useMessagesReadQueueReducer';

/**
 * Hook for marking a post as read
 * Gives back a callback that is invoked for each message separately
 * Based on its params, it'll be added to the queue or removed from it,
 * And after the specified amount of time, if the messageID is still in view,
 * It'll call the appropriate mutation, to mark it as read
 *
 * @param topicId The ID of the currently open topic
 * @returns       Function that accepts message info and adds or removes from mark-as-read queue
 */
const useMessagesRead = (topicId: Topic['id'] | null) => {
  /**
   * !!This is a monkey patch!!
   * It fixes topicList query not being updated after reading message that was open with push notification (for comments)
   */
  const [, executeQuery] = useQuery({ pause: true, query: TopicsListDocument });
  const [, messagesReadMutation] = useMutation(MessagesReadDocument);
  const {
    readQueueAdd,
    readQueueClear,
    readQueueRemove,
    readQueueReset,
    state,
  } = useMessagesReadQueueReducer();
  const isAppInView = useIsAppInView();

  useEffect(() => {
    if (isAppInView) {
      readQueueClear();
    }
  }, [isAppInView, readQueueClear, topicId]);

  useEffect(() => {
    if (state.size === 0) {
      return;
    }

    readQueueReset();
    messagesReadMutation({ messageIds: Array.from(state) })
      .then(() => executeQuery({ requestPolicy: 'network-only' }))
      .catch(error => reportError(error));

    /**
     * Only react to changes in the debounced state array
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.size]);

  return (args: UseMessageReadParams) => {
    const { messageId } = args;

    if (getShouldAdd(args)) {
      readQueueAdd(messageId);
    } else if (getShouldRemove({ ...args, isAppInView })) {
      readQueueRemove(messageId);
    }
  };
};

export default useMessagesRead;
