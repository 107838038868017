import { styled } from 'styled-components';

import devices from '../../../../../styles/devices';

type FiltersProps = {
  ['data-show-filters']: boolean;
};

export const FiltersWrapper = styled.div<FiltersProps>`
  @media ${devices.tabletS} {
    background-color: var(--color-background);
    position: absolute;
    transform: ${props =>
      props['data-show-filters'] ? 'translateY(0)' : 'translateY(-100%)'};
    transition: transform var(--preference-transition-duration-normal);
    width: 100%;
    z-index: var(--z-1);
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;
