import { styled } from 'styled-components';

// Wrapper around the entire component
export const Wrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid var(--color-border-light);
  border-top: var(--border-pale);
  display: flex;
  gap: var(--spacing-medium);
  min-height: var(--preference-topic-header-height);
  padding: var(--spacing-small) var(--spacing-medium);
`;

// Topic title
export const H2 = styled.h2`
  display: inline-block;
  font-size: var(--font-baseline);
  font-weight: 600;
  margin-right: var(--spacing-small);
`;
