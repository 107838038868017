/**
 * @file contains hook for initializing the state for the current user
 */

import { useEffect, useState } from 'react';
import { useQuery } from 'urql';

import { MeDocument } from '../generated/graphql';
import { initMatomo } from '../services/matomo';
import { reportError } from '../services/reporting';
import { getAuthCode, goToIAM } from '../utils/auth';
import { getInitialHotelId } from '../utils/hotel';
import { initPermissionsSystem } from '../utils/permissions';

import { getAuthToken } from '../utils/storage/auth';

import useLocale from './useLocale';

/**
 * Custom hook for initializing the state for the current user
 * like the permissions system, translations and tracking.
 *
 * @returns Whether the state was initialized
 */
const useCurrentUser = (): boolean => {
  const code = getAuthCode();
  const token = getAuthToken();
  // There's no token, meaning the user is not yet logged in
  // There's also no code, which we can use to call the login mutation
  // As a result, Me query would fail
  // To avoid that, we pause it and redirect the user to IAM
  const shouldRedirect = code === null && token === null;

  const [{ data }] = useQuery({
    pause: shouldRedirect,
    query: MeDocument,
    variables: {},
  });
  const [isInitialized, setIsInitialized] = useState(false);

  const me = data?.me ?? null;

  const shouldPause = me === null;
  const language = useLocale(shouldPause);

  useEffect(() => {
    if (me === null) {
      return;
    }

    const hotelId = getInitialHotelId(me);
    if (hotelId === null) {
      reportError('Hotel ID missing, permissions not initiated.');
      return;
    }

    initPermissionsSystem(me, hotelId);
    initMatomo(me, hotelId);

    setIsInitialized(true);
  }, [me]);

  if (shouldRedirect === true) {
    goToIAM();
  }

  return language !== null && isInitialized === true;
};

export default useCurrentUser;
