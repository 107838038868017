import { styled } from 'styled-components';

import devices from '../../styles/devices';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${devices.tabletS} {
    background-color: var(--color-background);
  }
`;

export const Ul = styled.ul`
  display: inherit;
  flex-wrap: nowrap;
  gap: var(--spacing-small);
  margin-top: var(--spacing-small);

  /* this needs to be scrolled horizontally on mobiles and tablets */
  overflow-x: auto;

  @media ${devices.tabletS} {
    margin-top: auto;
  }

  @media ${devices.laptopS} {
    flex-wrap: wrap;
  }
`;

export const Li = styled.li`
  display: inherit;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: var(--spacing-tiny);
`;
