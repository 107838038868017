import { styled } from 'styled-components';

import ButtonTranslate from '../../containers/buttonTranslate';

import devices from '../../styles/devices';

/**
 * Calculated based on button width
 * (translate button + help button)
 */
const sideWidthMin = '75px';

type HeaderProps = {
  'data-sticky': boolean;
};

export const Header = styled.header<HeaderProps>`
  align-items: center;
  background-color: var(--color-background);
  border-bottom: var(--border-dark);
  display: grid;
  gap: var(--spacing-tiny);
  grid-template-columns: ${sideWidthMin} 1fr ${sideWidthMin};
  padding: var(--spacing-small);
  position: ${props => (props['data-sticky'] ? 'sticky' : 'static')};

  /* Needed for sticky positioning */
  top: 0;
  z-index: var(--z-1);

  @media ${devices.tabletS} {
    display: none;
  }
`;

/**
 * Container for the Info icon
 */
export const RightElementContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

/**
 * Container for the Burger menu
 */
export const LeftElementContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ButtonTranslateContainer = styled(ButtonTranslate)`
  height: 1.56rem;
`;
