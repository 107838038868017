import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React, { FC } from 'react';

import translate from '../../../i18n/translate';

import * as Styled from './styled';

type Props = {
  label?: string;
  onClick: () => void;
};

/**
 * Back button with MUI component
 *
 * @param props         Props passed to the component
 * @param props.label   Aria label
 * @param props.onClick Click callback for the button
 * @returns             The component itself
 */
const ButtonBack: FC<Props> = ({
  label = translate('GENERAL__BACK'),
  onClick,
}) => {
  return (
    <Styled.IconButton aria-label={label} color="primary" onClick={onClick}>
      <ChevronLeftIcon fontSize="large" />
    </Styled.IconButton>
  );
};
export default ButtonBack;
