/**
 * @file Hook for determining what to show on Kanban page
 */
import { Topic } from '../../../../generated/graphql';
import useParamTopicId from '../../../../hooks/router/params/useParamTopicId';

import useIsLeftSidebarOpen from '../../../../hooks/router/search/useIsLeftSidebarOpen';
import useCurrentPath from '../../../../hooks/useCurrentPath';
import useIsMobile from '../../../../hooks/useIsMobile';
import {
  createKanbanMessageDetailsEditPath,
  createKanbanMessageDetailsPath,
  createKanbanPath,
  createKanbanTopicDetailsEditPath,
  createKanbanTopicDetailsPath,
} from '../../../paths/kanban';
import useShouldShowComposeMain from '../../Feed/Feed/hooks/useShouldShowComposeMain';

type UseKanbanParamsReturn = {
  isKanban: boolean;
  isLeftSidebarOpen: boolean;
  shouldShowCompose: boolean;
  shouldShowKanban: boolean;
  shouldShowRightSidebarDesktop: boolean;
  shouldShowRightSidebarMobile: boolean;
  topicId: Topic['id'] | null;
};

const showForDesktop: (string | null)[] = [
  createKanbanMessageDetailsPath(),
  createKanbanMessageDetailsEditPath(),
  createKanbanTopicDetailsPath(),
  createKanbanTopicDetailsEditPath(),
];

const showForMobile: (string | null)[] = [
  createKanbanTopicDetailsPath(),
  createKanbanTopicDetailsEditPath(),
];

/**
 * Hook for determining what to show on Kanban page
 * Including:
 * - Left sidebar
 * - Right sidebar
 * Along with giving info about the current topic ID
 *
 * @returns Info about the feed page
 */
const useKanbanParams = (): UseKanbanParamsReturn => {
  const currentPath = useCurrentPath();
  const isMobile = useIsMobile();
  const isLeftSidebarOpen = useIsLeftSidebarOpen();
  const topicId = useParamTopicId();
  const shouldShowCompose = useShouldShowComposeMain();

  const shouldShowRightSidebarDesktop = showForDesktop.includes(currentPath);
  const shouldShowRightSidebarMobile = showForMobile.includes(currentPath);
  const isDetails = currentPath?.includes(createKanbanTopicDetailsPath());
  const isKanban = currentPath === createKanbanPath();

  const shouldShowKanban =
    (shouldShowCompose === false && isDetails === false) || isMobile === false;

  return {
    isKanban,
    isLeftSidebarOpen,
    shouldShowCompose,
    shouldShowKanban,
    shouldShowRightSidebarDesktop,
    shouldShowRightSidebarMobile,
    topicId,
  };
};

export default useKanbanParams;
