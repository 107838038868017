import React, { FC } from 'react';

import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import translate from '../../i18n/translate';
import { getSettingsUrl } from '../../routes/urls/settings';

import { Props as ButtonProps } from '../Common/ButtonSingle';
import { NAMES } from '../Common/LinkIcon';

import HeaderButtons from './HeaderButtons';

import * as Styled from './styled';

type Props = {
  buttons?: ButtonProps[];
  settingsBackUrl?: string;
  subtitle: string | null;
  tabs?: [];
  title: string | null;
};

const ICON_SIZE = 20;

/**
 * Setting header generic component
 *
 * @param props                 Props passed to the component
 * @param props.buttons         Buttons for header component
 * @param props.settingsBackUrl Url to navigate to on back button press (defaults to settings main url)
 * @param props.subtitle        Subtitle for the header
 * @param props.title           Title for the header
 * @returns                     Setting header component
 */
const SettingsHeader: FC<Props> = ({
  buttons = [],
  settingsBackUrl,
  subtitle,
  title,
}) => {
  const hotelId = useParamHotelId();

  const settingsBaseUrl = getSettingsUrl(hotelId);

  return (
    <header>
      <Styled.TitleWrapper>
        {settingsBackUrl && (
          <Styled.LinkIcon
            iconHeight={ICON_SIZE}
            iconName={NAMES.GENERAL__CHEVRON}
            iconWidth={ICON_SIZE}
            label={translate('GENERAL__BACK')}
            url={`${settingsBaseUrl}${settingsBackUrl}`}
          />
        )}
        <div>
          <Styled.H2>{title}</Styled.H2>
          <Styled.H3>{subtitle}</Styled.H3>
        </div>
      </Styled.TitleWrapper>
      <HeaderButtons buttons={buttons} />
    </header>
  );
};

export default SettingsHeader;
