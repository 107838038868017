/**
 * @file Hook that tells us if compose is open
 */
import useSearchParams from './useSearchParams';

/**
 * Hook that tells us if compose is open
 * based on url
 *
 * @returns Whether the compose is open
 */
const useIsComposeOpen = (): boolean => {
  const query = useSearchParams();

  return query.get('compose') === 'open';
};

export default useIsComposeOpen;
