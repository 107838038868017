import React, { FC } from 'react';

import { Props as ButtonProps } from '../../Common/ButtonSingle';

import * as Styled from './styled';

/**
 * A single button within a settings page header
 *
 * @param props            Props passed to the component
 * @param props.children   Button contents
 * @param props.isDisabled Whether the button is disabled
 * @param props.formId     The form the button is associated with
 * @param props.onClick    Callback to be invoked on button click
 * @param props.type       The type attribute for the button
 * @returns                The component itself
 */
const HeaderButtonSingle: FC<ButtonProps> = ({
  children,
  isDisabled = false,
  formId,
  onClick,
  type = 'button',
}) => {
  return (
    <Styled.ButtonHeader
      data-usage="default"
      formId={formId}
      isDisabled={isDisabled}
      key="setting-header"
      onClick={onClick}
      type={type}
    >
      {children}
    </Styled.ButtonHeader>
  );
};

export default HeaderButtonSingle;
