import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import FloatingBottomNav from '../../components/FloatingBottomNav';
import HeaderMobile from '../../components/HeaderMobile';
import AppLayout from '../../components/layouts/AppLayout';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useIsMobile from '../../hooks/useIsMobile';
import translate from '../../i18n/translate';
import { getHomeUrl } from '../../routes/urls/home';

import { ChatList } from './ChatList';
import { useIsChatEnabled } from './useIsChatEnabled';

/**
 * App layout containing the chat conversation list for mobile
 *
 * @returns Chat page
 */
export const ChatListPageMobile: FC = () => {
  const isMobile = useIsMobile();
  const hotelId = useParamHotelId();
  const isEnabled = useIsChatEnabled();

  if (isEnabled === false || isMobile === false) {
    return <Navigate to={getHomeUrl(hotelId)} />;
  }

  return (
    <AppLayout
      footer={<FloatingBottomNav />}
      header={
        <HeaderMobile backUrl={true} title={translate('GENERAL__CHAT')} />
      }
    >
      <ChatList />
    </AppLayout>
  );
};
