import React, { FC, UIEventHandler, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';

import LeftSidebar from '../../components/LeftSidebar';
import { PREFERENCE_SCROLL_TIMEOUT } from '../../constants/preferences';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { ChatList } from '../../integrations/roqChat/ChatList';
import { useIsChatEnabled } from '../../integrations/roqChat/useIsChatEnabled';

import HotelInfoContainer from '../hotelInfo';
import TopicsContainer from '../topics';

import { mapDispatchToProps, mapStateToProps } from './redux';
import * as Styled from './styled';
import { Props } from './types';

/**
 * The container for the left sidebar in the /feed
 *
 * @param props                   Props passed to the component
 * @param props.scrollPosition    Stored scroll position
 * @param props.setScrollPosition Request to store current scroll position in Redux
 * @param props.shouldShowDesktop Whether LeftSidebar should be visible or not
 * @returns                       The component itself
 */
const LeftSidebarFeedContainer: FC<Props> = ({
  scrollPosition,
  setScrollPosition,
  shouldShowDesktop,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const isChatEnabled = useIsChatEnabled();

  useLayoutEffect(() => {
    // We don't scroll until we know whether chat is supported
    // Because, while isChatEnabled is null, there's nothing rendered, and scroll position will be off
    if (wrapperRef.current === null || isChatEnabled === null) {
      return;
    }

    wrapperRef.current.scrollTop = scrollPosition;
    // We only want to set scroll position on mount
    // otherwise, we'd be overriding user's scroll
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatEnabled]);

  /**
   * Store sidebar scroll position when it changes
   *
   * @param event The event that took place
   */
  const handleScroll: UIEventHandler<HTMLDivElement> = useDebouncedCallback(
    event => setScrollPosition((event.target as HTMLDivElement).scrollTop),
    PREFERENCE_SCROLL_TIMEOUT,
  );

  return (
    <LeftSidebar shouldShowDesktop={shouldShowDesktop}>
      <HotelInfoContainer />
      <Styled.InnerWrapper onScroll={handleScroll} ref={wrapperRef}>
        <TopicsContainer />
        <ChatList />
      </Styled.InnerWrapper>
    </LeftSidebar>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeftSidebarFeedContainer);
