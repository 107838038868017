import React from 'react';

import LeftSidebarSettingsContainer from '../../../../containers/leftSidebarSettings';
import useIsLeftSidebarOpen from '../../../../hooks/router/search/useIsLeftSidebarOpen';
import useIsTablet from '../../../../hooks/useIsTablet';

/**
 * Generate config params for the left sidebar
 *
 * @param hasLeftSidebar Whether left sidebar is enabled on this page
 * @returns              The left sidebar config
 */
export const useLeftSidebarConfig = (hasLeftSidebar: boolean) => {
  const isOpen = useIsLeftSidebarOpen();
  const isTablet = useIsTablet();

  if (hasLeftSidebar === false) {
    return null;
  }

  return {
    clickOutsideOptions: { stopExec: !isTablet || !isOpen },
    component: <LeftSidebarSettingsContainer shouldShowDesktop={isOpen} />,
    shouldShowDesktop: isOpen,
    shouldShowMobile: false,
  };
};
