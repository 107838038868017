import React from 'react';

import useCurrentUser from '../hooks/useCurrentUser';

import AppAuthenticated from './appAuthenticated';
import AppUnauthenticated from './appUnauthenticated';

/**
 * Permissions check component that fetches the user's data
 * and their permissions
 *
 * @returns Either AppAuthenticated or AppUnauthenticated depending on the login status
 */
const PermissionsCheck = () => {
  const isInitialized = useCurrentUser();

  if (isInitialized === true) {
    return <AppAuthenticated />;
  }

  return <AppUnauthenticated />;
};

export default PermissionsCheck;
