/**
 * @file Helpers for DashboardContent component
 */

import { extractTitle } from '../../hooks/elasticSearch/useEsSmartViewQuery/helpers';
import { FormattedData, FormattedDataArgs } from '../../models/dashboard';
import { getSmartViewsSmartViewUrl } from '../../routes/urls/smartViews';

/**
 * Used for data formatting
 * in order to properly display kpi components
 *
 * @param args              Args passed to function
 * @param args.dashboardKpi Data from server
 * @param args.hotelId      Hotel id
 * @param args.location     Current location object, which represents the current URL in web browsers
 * @returns                 Formatted dashboard data
 */
export const getFormattedData = ({
  dashboardKpi,
  hotelId,
  location,
}: FormattedDataArgs): FormattedData => {
  const dataFormatted: FormattedData = {
    CONCIERGE: [],
    REQUEST: [],
    TASK: [],
  };

  dashboardKpi.forEach(kpi => {
    const { dashboardKpiType, title, id } = kpi.searchQuery;
    if (dashboardKpiType === null || dashboardKpiType === undefined) {
      return;
    }
    dataFormatted[dashboardKpiType].push({
      amount: kpi.searchInfo.total,
      label: extractTitle(title),
      url: getSmartViewsSmartViewUrl(hotelId, location, id),
    });
  });

  return dataFormatted;
};
