import React, { FC, useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import AppLayout from '../../../../components/layouts/AppLayout';
import ComposeContainer from '../../../../containers/compose';
import Kanban from '../../../../containers/kanban';
import LeftSidebarFeedContainer from '../../../../containers/leftSidebarFeed';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useIsTablet from '../../../../hooks/useIsTablet';
import { getTopicKanbanUrl } from '../../../urls/kanban';
import FeedFooter from '../../Feed/Feed/FeedFooter';
import FeedHeader from '../../Feed/Feed/FeedHeader';
import FeedRightSidebar from '../../Feed/Feed/FeedRightSidebar';
import FeedSubHeader from '../../Feed/Feed/FeedSubHeader';
import { shouldStopClickOutsideExec } from '../../Feed/Feed/helpers';

import useKanbanParams from '../hooks/useKanbanParams';

/**
 * Kanban page
 *
 * @returns The component itself
 */
const PageKanban: FC = () => {
  const hotelId = useParamHotelId();
  const isTablet = useIsTablet();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    isLeftSidebarOpen,
    shouldShowCompose,
    shouldShowKanban,
    shouldShowRightSidebarDesktop,
    shouldShowRightSidebarMobile,
    topicId,
  } = useKanbanParams();

  const clickOutsideStopExec = shouldStopClickOutsideExec(isTablet, false);

  const clickOutsideStopExecLeftSidebar =
    isTablet === false || isLeftSidebarOpen === false;

  const requestRightSidebarClose = useCallback(() => {
    navigate(getTopicKanbanUrl(hotelId, location, topicId));
  }, [hotelId, location, navigate, topicId]);

  return (
    <AppLayout
      footer={<FeedFooter />}
      header={shouldShowKanban && <FeedHeader />}
      leftSidebar={{
        clickOutsideOptions: {
          stopExec: clickOutsideStopExecLeftSidebar,
        },
        component: (
          <LeftSidebarFeedContainer shouldShowDesktop={isLeftSidebarOpen} />
        ),
        shouldShowDesktop: isLeftSidebarOpen,
        shouldShowMobile: false,
      }}
      rightSidebar={{
        clickOutsideOptions: {
          onClick: requestRightSidebarClose,
          stopExec: clickOutsideStopExec,
        },
        component: <FeedRightSidebar />,
        shouldShowDesktop: shouldShowRightSidebarDesktop,
        shouldShowMobile: shouldShowRightSidebarMobile,
      }}
      subHeader={shouldShowKanban && <FeedSubHeader />}
    >
      {shouldShowKanban && <Kanban />}
      {shouldShowCompose && <ComposeContainer nodeType="MESSAGE" />}
    </AppLayout>
  );
};

export default PageKanban;
