import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useParamMessageId from '../../hooks/router/params/useParamMessageId';
import useParamTopicId from '../../hooks/router/params/useParamTopicId';
import useIsComposeOpen from '../../hooks/router/search/useIsComposeOpen';
import useIsMobile from '../../hooks/useIsMobile';
import translate from '../../i18n/translate';
import { MessageFeed } from '../../models/message';
import { getMessageDetailsUrl } from '../../routes/urls/message';
import { dateToString } from '../../utils/date/format';
import getIsSameDay from '../../utils/date/getIsSameDay';

import MessageDateHeading from './MessagesDateHeading';
import MessageListElement from './MessagesListElement';

import useMessagesRead from './hooks/useMessagesRead';
import * as Styled from './styled';

type Props = {
  messages: MessageFeed[];
  requestLoadMoreAfter: (() => void) | null;
  requestLoadMoreBefore: (() => void) | null;
};

/**
 * Messages that are shown within feed
 *
 * @param props                       Props passed to the component
 * @param props.messages              The messages to show
 * @param props.requestLoadMoreAfter  Callback to invoke if load more is triggered
 * @param props.requestLoadMoreBefore Callback to invoke if load more is triggered
 * @returns                           The component itself
 */
const Messages: FC<Props> = ({
  messages,
  requestLoadMoreAfter,
  requestLoadMoreBefore,
}) => {
  const isComposeOpen = useIsComposeOpen();
  const isMobile = useIsMobile();
  const location = useLocation();
  const topicId = useParamTopicId();
  const hotelId = useParamHotelId();
  const marker = useMessagesRead(topicId);
  const messageIdFocus = useParamMessageId();

  /**
   * Whether to apply bottom padding on the feed in case we want scroll to go
   * to bottom of the page, but still have visible content above the compose
   */
  const shouldPadBottom = isMobile === false && isComposeOpen === false;

  /**
   * On mobile, the whole message is a link,
   * So we don't want any links within
   */
  const shouldRenderLinks = isMobile === false;

  /**
   * Take either the current date or the latest message date
   */
  const dateCurrent = new Date(
    messages.length === 0 ? Date.now() : dateToString(messages[0].createdAt),
  );

  return (
    <section aria-label={translate('GENERAL__FEED')} role="feed">
      {messages.length > 0 && <MessageDateHeading date={dateCurrent} />}
      <Styled.Ul data-should-pad-bottom={shouldPadBottom}>
        {messages.map((message, index) => {
          const dateMessage = new Date(dateToString(message.createdAt));
          const dateToShow = new Date(dateCurrent.getTime());

          const shouldShowDateHeading =
            getIsSameDay(dateCurrent, dateMessage) === false;

          dateCurrent.setTime(dateMessage.getTime());

          const messageDetailsUrl = getMessageDetailsUrl(
            hotelId,
            location,
            message.id,
            topicId,
          );

          /**
           * Purpose of this check is to determine which element
           * is third to last so it can trigger pagination
           */
          const isThirdFromLast = index === messages.length - 3;
          const isThirdFromStart = index === 2;

          /**
           * @todo - Focus only if topic hasn't been scrolled previously
           * aka. coming directly from the deep link. Not solvable yet.
           */
          const shouldFocus = messageIdFocus === message.id;

          return (
            <MessageListElement
              dateMessage={dateToShow}
              key={`message-${message.id}`}
              marker={marker}
              message={message}
              messageDetailsUrl={messageDetailsUrl}
              requestLoadMoreAfter={
                isThirdFromLast ? requestLoadMoreAfter : null
              }
              requestLoadMoreBefore={
                isThirdFromStart && messageIdFocus
                  ? requestLoadMoreBefore
                  : null
              }
              shouldFocus={shouldFocus}
              shouldRenderLinks={shouldRenderLinks}
              shouldShowDateHeading={shouldShowDateHeading}
            />
          );
        })}
      </Styled.Ul>
    </section>
  );
};

export default Messages;
