/**
 * @file Hook that tells us which recurring mode is open
 */
import { useLocation } from 'react-router-dom';

import {
  RECURRING_MODES,
  RecurringModeParam,
} from '../../../models/pageParams';

import { getSearchParamRecurringMode } from '../../../routes/helpers/searchParams';

/**
 * Hook that tells us which recurring mode is open
 * based on the url
 *
 * @returns Whether/which recurringMode is open
 */
const useRecurringMode = (): RecurringModeParam | null => {
  const location = useLocation();
  const recurringMode = getSearchParamRecurringMode(location);

  if (
    recurringMode === null ||
    RECURRING_MODES.includes(recurringMode) === false
  ) {
    return null;
  }

  return recurringMode;
};

export default useRecurringMode;
