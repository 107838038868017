import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from 'urql';

import FloatingBottomNav from '../../../components/FloatingBottomNav';
import HeaderMobile from '../../../components/HeaderMobile';
import MobileNavigation from '../../../components/MobileNavigation';
import AppLayout from '../../../components/layouts/AppLayout';
import { MeDocument } from '../../../generated/graphql';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';
import { getHomeUrl } from '../../urls/home';

/**
 * More page
 *
 * @returns The page itself
 */
const PageMore: FC = () => {
  const isMobile = useIsMobile();
  const hotelId = useParamHotelId();
  /**
   * User should be fetched at this point so we retrieve only from cache
   */
  const [{ data }] = useQuery({
    query: MeDocument,
    requestPolicy: 'cache-only',
    variables: {},
  });

  if (data === null || data === undefined) {
    // Data should be in cache by this point, this is just for type correctness
    return null;
  }

  const { email, hotelPermissions } = data.me;

  if (isMobile) {
    return (
      <AppLayout
        footer={<FloatingBottomNav />}
        header={
          <HeaderMobile backUrl={true} title={translate('GENERAL__MORE')} />
        }
      >
        <MobileNavigation
          hotelPermissions={hotelPermissions}
          userEmail={email}
        />
      </AppLayout>
    );
  }

  return <Navigate to={getHomeUrl(hotelId)} />;
};

export default PageMore;
