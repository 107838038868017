import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { styled } from '@mui/system';

export const LockIconStyled = styled(LockIcon)`
  /* MUI icons are centered and icons have different sizes 
  so we need to hardcode margin values to align them to the UI */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-left: -4px !important;
`;

export const LockOpenIconStyled = styled(LockOpenIcon)`
  /* MUI icons are centered and icons have different sizes 
  so we need to hardcode margin values to align them to the UI */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-left: -4px !important;
`;
