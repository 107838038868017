// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roq-chat-window__header {
  border-bottom: var(--border-pale);
  padding: var(--spacing-small);
}

/* We have our own back button, so we don't need this one */
.roq-panel-back-button {
  display: none;
}

/* Header under our header */
.roq-chat-conversation-header {
  align-items: center;
  display: flex;
  gap: var(--spacing-small);
}

/* Name and status (Active now, Offline) */
.roq-chat-conversation-header__info {
  flex-grow: 1;
}

.roq-chat-conversation-header__actions .roq-action-button__button {
  padding: var(--spacing-tiny) var(--spacing-small);
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/_window-header.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,6BAA6B;AAC/B;;AAEA,2DAA2D;AAC3D;EACE,aAAa;AACf;;AAEA,4BAA4B;AAC5B;EACE,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA,0CAA0C;AAC1C;EACE,YAAY;AACd;;AAEA;EACE,iDAAiD;AACnD","sourcesContent":[".roq-chat-window__header {\n  border-bottom: var(--border-pale);\n  padding: var(--spacing-small);\n}\n\n/* We have our own back button, so we don't need this one */\n.roq-panel-back-button {\n  display: none;\n}\n\n/* Header under our header */\n.roq-chat-conversation-header {\n  align-items: center;\n  display: flex;\n  gap: var(--spacing-small);\n}\n\n/* Name and status (Active now, Offline) */\n.roq-chat-conversation-header__info {\n  flex-grow: 1;\n}\n\n.roq-chat-conversation-header__actions .roq-action-button__button {\n  padding: var(--spacing-tiny) var(--spacing-small);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
