import React, { FC } from 'react';

import { HotelPermissions } from '../../generated/graphql';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useCanAccessReservations from '../../hooks/useCanAccessReservations';
import useLogout from '../../hooks/useLogout';

import { getProfileExternalUrl } from '../../routes/external';

import { getDevPanelUrl } from '../../routes/urls/devPanel';
import { getSelectHotelUrl } from '../../routes/urls/more';
import { getReservationsUrl } from '../../routes/urls/reservations';
import { getSettingsUrl } from '../../routes/urls/settings';

import { getCanViewDevPanel } from '../../utils/permissions/devPanel';

import MobileNavigationLink from './MobileNavigationLink';

import { generateLinks } from './links';

import * as Styled from './styled';

export type Props = {
  hotelPermissions: HotelPermissions[];
  userEmail: string;
};

/**
 * Profile menu for the mobile screens
 *
 * @param props                  Props passed to the component
 * @param props.hotelPermissions Current user hotels list
 * @param props.userEmail        Email of the current user
 * @returns                      MobileNavigation component
 */
const MobileNavigation: FC<Props> = ({ hotelPermissions, userEmail }) => {
  const canViewDevPanelButton = getCanViewDevPanel();
  const hotelId = useParamHotelId();
  const logout = useLogout();
  const numberOfHotels = hotelPermissions.length;
  const shouldShowReservationsLink = useCanAccessReservations();

  const linksBasicSetup = {
    devPanelUrl: getDevPanelUrl(canViewDevPanelButton, hotelId),
    onLogoutClick: logout,
    profileUrl: getProfileExternalUrl(userEmail),
    selectHotelUrl: numberOfHotels <= 1 ? '' : getSelectHotelUrl(hotelId),
    settingsUrl: getSettingsUrl(hotelId),
  };

  const linksDraft = shouldShowReservationsLink
    ? { ...linksBasicSetup, reservationsUrl: getReservationsUrl(hotelId) }
    : linksBasicSetup;

  const links = generateLinks(linksDraft);

  return (
    <nav>
      <Styled.Ul>
        {links.map(link => {
          return (
            <li key={link.label}>
              <MobileNavigationLink {...link} />
            </li>
          );
        })}
      </Styled.Ul>
    </nav>
  );
};

export default MobileNavigation;
