/**
 * @file Configuration preferences for running on production environment
 */

export {
  AUTH__CODE,
  BUGSNAG_API_KEY,
  FIREBASE_OPTIONS,
  FIREBASE_VAPID_KEY,
  HELPDESK_BASE_URL,
  SW_URL,
} from './config-common';

export const BUGSNAG_PUBLIC_PATH = 'https://hotelboard.io/';

export const COOKIE_AUTH = 'gf_auth';

export const GRAPHQL_URL = 'https://api.hotelboard.io/graphql';
export const GRAPHQL_SUBSCRIPTIONS_URL = 'wss://api.hotelboard.io/graphql';

export const MATOMO_ENABLED = true;
export const MATOMO_SITE_ID = 2;

export const REPORT_LEVEL_ALERT = 5;
export const REPORT_LEVEL_LOG = 5;
export const REPORT_LEVEL_POST = 2;

export const ROQ_HOST = 'https://jupiter.roq-platform.com';

export const SSO_BASE_URL = 'https://login.gastfreund.net/';
export const SSO_CLIENT_ID = '91';
export const SSO_REDIRECT_URI = 'https://hotelboard.io/';

export const URL_CDN = 'https://cdn.gastfreund.net/';
export const URL_AVATARS = `${URL_CDN}profile-images/thumbs/`;
export const URL_IMAGES = `${URL_CDN}images/thumbs/`;
