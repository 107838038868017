/**
 * @file contains hook that returns current hotel based on hotelId from url and users data that i stored in our cookies
 */

import { useEffect, useState } from 'react';

import { Hotel, Maybe } from '../generated/graphql';
import { getHotelPermissionsById } from '../utils/hotel';

import { getUserCurrent } from '../utils/permissions/storage';

import useParamHotelId from './router/params/useParamHotelId';

/**
 * Custom hook for getting current hotel
 * (based on hotelId from url and users data that is stored in our cookies)
 *
 * @returns Current hotel, null or undefined while data is not written in cookies
 */
const useCurrentHotel = () => {
  const [hotel, setHotel] = useState<Maybe<Hotel>>(null);

  const hotelId = useParamHotelId();
  const user = getUserCurrent();

  useEffect(() => {
    if (user === null || user === undefined) {
      setHotel(null);
      return;
    }

    const { hotelPermissions } = user;
    const hotelPermission = getHotelPermissionsById(
      hotelId,
      hotelPermissions ?? [],
    );

    setHotel(hotelPermission?.hotel ?? null);
  }, [hotelId, user]);

  return hotel;
};

export default useCurrentHotel;
