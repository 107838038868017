import { styled } from 'styled-components';

import { visuallyHidden } from '../../../../styles/common';

// Wrapper for the options
export const Ul = styled.ul`
  display: flex;
`;

// Radio button itself
export const Radio = styled.input`
  ${visuallyHidden}
`;

// Text for the label
export const LabelText = styled.div`
  background-color: var(--color-background-selected);
  border: var(--border-dark);
  color: var(--color-button-secondary);

  /**  To show bottom border */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-bottom: -1px;
  padding: var(--spacing-tiny) var(--spacing-medium);

  li:first-child & {
    border-top-left-radius: var(--border-radius-small);
  }

  li:last-child & {
    border-top-right-radius: var(--border-radius-small);
  }

  /* Otherwise, we'd have outline cut off */
  li:not(:last-child) ${Radio}:not(:checked) + & {
    border-right: none;
  }

  li:not(:first-child) ${Radio}:not(:checked) + & {
    border-left: none;
  }

  ${Radio}:checked + & {
    background-color: var(--color-background-list);
    border-color: var(--color-outline);
    color: var(--color-link);
    font-weight: 700;
  }

  ${Radio}:focus-visible + & {
    background-color: var(--color-border-dark);
    border-color: var(--color-text-light);
    color: var(--color-text-light);
    font-weight: 700;
  }

  ${Radio}:not(:checked):not(:disabled) + & {
    cursor: pointer;
  }

  ${Radio}:disabled + & {
    background-color: var(--color-button-disabled);
    color: var(--color-text-light);
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: not-allowed;
  }
`;
