import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

type Props = {
  title: ReactNode;
};

/**
 * Header component for search results in feed
 *
 * @param props       Props passed to the component
 * @param props.title Title for the header
 * @returns           The component itself
 */
const ESSubHeader: FC<Props> = ({ title }) => {
  return (
    <Styled.Wrapper>
      <Styled.H2>{title}</Styled.H2>
    </Styled.Wrapper>
  );
};

export default ESSubHeader;
