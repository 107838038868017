import { Button, styled } from '@mui/material';

export const MuiButton = styled(Button)({
  '&:hover': {
    boxShadow: 'none',
  },
  border: 'var(--mui-button-outlined-border)',
  boxShadow: 'none',
  textTransform: 'capitalize',
});
