import React, { FC, ReactNode } from 'react';

import { Locale } from '../../generated/graphql';
import translate from '../../i18n/translate';
import { TypeMessageFull } from '../../models/message';
import { getLanguageCode } from '../../utils/user/language';

import MessageReadBy from './MessageReadBy';

import MessageSection from './MessageSection';
import * as Styled from './styled';

type Props = {
  message: TypeMessageFull;
};

/**
 * Information about who read the message
 *
 * @param props                        Props passed to the component
 * @param props.message                The message to show the info for
 * @param props.message.readBy         Array of users who read the message
 * @param props.message.readPercentage Percentage of people who read the message
 * @returns                            The component itself
 */
const MessageSectionReadBy: FC<Props> = ({
  message: { readBy: readByRaw = [], readPercentage },
}) => {
  const readBy = readByRaw ?? [];

  const percentage =
    getLanguageCode() === Locale.De
      ? `${readPercentage} %`
      : `${readPercentage}%`;

  const heading: ReactNode = (
    <>
      {translate('MESSAGE__HEADING__READ_BY')}
      <Styled.Percentage>{percentage}</Styled.Percentage>
    </>
  );

  return (
    <MessageSection heading={heading}>
      <MessageReadBy percentage={readPercentage} readBy={readBy} />
    </MessageSection>
  );
};

export default MessageSectionReadBy;
