import React, { FC, useState } from 'react';

import Heading from '../../../containers/leftSidebarFeed/LeftSidebarFeedContent/Heading';
import SmartViewsContainer from '../../../containers/smartViews';

import translate from '../../../i18n/translate';

/**
 * Shows a button that toggles if to show smart views or not
 *
 * @returns The component itself
 */
const SmartViews: FC = () => {
  const [isActive, setIsActive] = useState(true);

  /**
   * The user has clicked to toggle the smart view expanded state
   */
  const handleToggle = () => {
    setIsActive(state => !state);
  };

  return (
    <>
      <Heading
        handleToggle={handleToggle}
        isActive={isActive}
        text={translate('GENERAL__SMART_VIEWS')}
      />
      <SmartViewsContainer isActive={isActive} />
    </>
  );
};

export default SmartViews;
