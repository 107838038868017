/**
 * @file Mobile app nav links
 */

import { Hotel } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import { createChatPath } from '../../../routes/paths/chat';
import { createHomePath } from '../../../routes/paths/home';
import { createMorePath } from '../../../routes/paths/more';
import {
  createSearchPath,
  createSearchTextPath,
} from '../../../routes/paths/search';
import { getChatUrl } from '../../../routes/urls/chat';

import { getHomeUrl } from '../../../routes/urls/home';
import { getMoreUrl } from '../../../routes/urls/more';
import { getSearchUrl } from '../../../routes/urls/search';
import { NAMES } from '../../Common/Icon';

type NavLink = {
  ariaLabel: string;
  iconName: string;
  paths: string[];
  url: string;
};

export const CHAT_ICON = NAMES.BOTTOM_NAV__CHAT;

/**
 * Small screen nav links
 *
 * @param hotelId ID of the hotel
 * @returns       Array of navigation links data
 */
export const getNavLinks = (hotelId: Hotel['id'] | null): NavLink[] => [
  {
    ariaLabel: translate('GENERAL__HOME'),
    iconName: NAMES.BOTTOM_NAV__HOME,
    paths: [createHomePath()],
    url: getHomeUrl(hotelId),
  },
  {
    ariaLabel: translate('GENERAL__CHAT'),
    iconName: CHAT_ICON,
    paths: [createChatPath()],
    url: getChatUrl(hotelId),
  },
  {
    ariaLabel: translate('GENERAL__SEARCH'),
    iconName: NAMES.GENERAL__SEARCH,
    paths: [createSearchPath(), createSearchTextPath()],
    url: getSearchUrl(hotelId),
  },
  {
    ariaLabel: translate('GENERAL__MORE'),
    iconName: NAMES.BOTTOM_NAV__MORE,
    paths: [createMorePath()],
    url: getMoreUrl(hotelId),
  },
];
