import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'urql';

import { Hotel, MeDocument, User } from '../../../generated/graphql';
import useCanAccessReservations from '../../../hooks/useCanAccessReservations';
import useClickOutside from '../../../hooks/useClickOutside';
import useIsTablet from '../../../hooks/useIsTablet';
import useLogout from '../../../hooks/useLogout';
import translate from '../../../i18n/translate';
import { getProfileExternalUrl } from '../../../routes/external';
import { getReservationsUrl } from '../../../routes/urls/reservations';
import { getSettingsUrl } from '../../../routes/urls/settings';
import { openDebugPanel } from '../../../store/actions/debug';
import { getCanViewDevPanel } from '../../../utils/permissions/devPanel';

import * as Styled from './styled';

export type Props = {
  hotelId: Hotel['id'] | null;
  userEmail: User['email'];
};

/**
 * Profile menu component with a dropdown that opens on click.
 *
 * @param props           Props passed to the component
 * @param props.hotelId   Id of current hotel
 * @param props.userEmail Email of the logged in user
 * @returns               The component itself
 */
const ProfileMenu: FC<Props> = ({ hotelId, userEmail }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const isTablet = useIsTablet();
  const logout = useLogout();
  const shouldShowLink = useCanAccessReservations();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    onClick: useCallback(() => setIsOpen(false), []),
    ref: wrapperRef,
    stopExec: !isOpen,
  });

  /**
   * Data will always be fetched on app boot, so it's already cached at this point
   */
  const [{ data }] = useQuery({
    query: MeDocument,
    requestPolicy: 'cache-only',
    variables: {},
  });

  /**
   * The user has clicked on the dropdown toggle
   */
  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    setIsOpen(state => !state);
  };

  const user = data?.me ?? null;

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <Styled.Button
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-haspopup="true"
        aria-label={translate('ARIA__BUTTON__OPEN_MENU')}
        onClick={handleClick}
        type="button"
      >
        <Styled.ProfileImage
          data-is-open={isOpen}
          fontSize={0.95}
          size={40}
          user={user}
        />
      </Styled.Button>
      <Styled.DropdownWrapper data-is-open={isOpen} role="menu">
        {/** Since these links are hidden in tablet view from the header show them here */}
        {isTablet && (
          <>
            {shouldShowLink && (
              <Styled.MenuItem role="menuitem">
                <Styled.Link href={getReservationsUrl(hotelId)}>
                  {translate('GENERAL__RESERVATIONS')}
                </Styled.Link>
              </Styled.MenuItem>
            )}

            <Styled.MenuItem role="menuitem">
              <Styled.Link href={getSettingsUrl(hotelId)}>
                {translate('GENERAL__SETTINGS')}
              </Styled.Link>
            </Styled.MenuItem>

            <Styled.MenuItem role="menuitem">
              <Styled.Link href={translate('GENERAL__HELPCENTER')}>
                {translate('GENERAL__HELP')}
              </Styled.Link>
            </Styled.MenuItem>
          </>
        )}
        <Styled.MenuItem role="menuitem">
          <Styled.Link href={getProfileExternalUrl(userEmail)}>
            {translate('GENERAL__PROFILE')}
          </Styled.Link>
        </Styled.MenuItem>

        {getCanViewDevPanel() && (
          <Styled.MenuItem role="menuitem">
            <Styled.MenuButton
              onClick={() => dispatch(openDebugPanel())}
              type="button"
            >
              {translate('DEBUG__DEV_PANEL__LINK')}
            </Styled.MenuButton>
          </Styled.MenuItem>
        )}

        <Styled.MenuItem role="menuitem">
          <Styled.MenuButton
            aria-label={translate('ARIA__LINK__LOGOUT')}
            onClick={logout}
            type="button"
          >
            {translate('GENERAL__LOGOUT')}
          </Styled.MenuButton>
        </Styled.MenuItem>
      </Styled.DropdownWrapper>
    </Styled.Wrapper>
  );
};

export default ProfileMenu;
