import { styled } from 'styled-components';

export const PageWrapper = styled.section`
  font-size: var(--font-lg);
  padding: var(--spacing-medium) var(--spacing-medium) var(--spacing-huge);
`;

export const PageTitle = styled.h2`
  font-size: inherit;
  font-weight: var(--font-semibold);
  margin-bottom: var(--spacing-small);
`;

export const PageDescription = styled.p`
  font-size: var(--font-baseline);
  margin-bottom: var(--spacing-medium);
`;
