/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { Location } from 'react-router-dom';

import { ROUTE__SMART_VIEWS } from '../../constants/routing/routes';
import { Hotel } from '../../generated/graphql';
import { reportError } from '../../services/reporting';

/**
 * Get URL for /smart-views route
 *
 * @param hotelId  ID of the hotel
 * @param location Location object from history
 * @param id       ID of the smart view we want to go to
 * @returns        A string in a url format
 */
export const getSmartViewsSmartViewUrl = (
  hotelId: Hotel['id'] | null,
  location: Location,
  id: string,
): string => {
  if (hotelId === null) {
    reportError('HotelId in getSmartViewsSmartViewUrl was null');
  }

  const { search } = location;

  return `/${hotelId}/${ROUTE__SMART_VIEWS}/${id}${search}`;
};
