/**
 * @file Various form helpers
 */

import { RefObject } from 'react';

import { Item } from '../../hooks/useSelect';

import translate from '../../i18n/translate';

/**
 * Checks if variable is a valid string or not
 *
 * @param value Input value
 * @returns     Value or null
 */
export const checkInputField = (
  value: string | null | undefined,
): null | string => {
  if (value === null || value === undefined) {
    return null;
  }
  return value;
};

/**
 * Checks if variable is a valid string or not
 *
 * @param formRef Input value
 */
export const submitFormProgrammatically = (
  formRef: RefObject<HTMLFormElement>,
): void => {
  if (formRef?.current) {
    if (formRef.current.checkValidity() === false) {
      formRef.current.reportValidity();
    } else {
      formRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true }),
      );
    }
  }
};

/**
 * Creates an item for the <Select /> dropdown out of the values
 *
 * @param prefix Prefix for the item label used for translations ;
 * @param value  Value of the dropdown used a suffix
 * @returns      Properly formatted item value
 */
export const createSelectItem = <T extends string>(
  prefix: string,
  value: T,
): Item => {
  return {
    label: translate(`${prefix}${value}`),
    value: value,
  };
};
