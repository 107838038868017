import React, { ComponentProps, FC } from 'react';

import { NAMES } from '../Common/Icon';

import * as Styled from './styled';

export type Props = ComponentProps<typeof Styled.Input>;

/**
 * Search input component
 *
 * @param props props passed to the component
 * @returns     The component itself
 */
const SearchField: FC<Props> = props => {
  return (
    <Styled.Wrapper>
      <Styled.Icon
        color="var(--color-button-secondary)"
        height={15}
        name={NAMES.GENERAL__SEARCH}
        width={15}
      />

      <Styled.Input {...props} isRequired={true} type="search" />
    </Styled.Wrapper>
  );
};

export default SearchField;
