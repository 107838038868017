import React, { FC } from 'react';

import * as Styled from './styled';

type Props = {
  isPrivate: boolean;
};

/**
 * Topic visibility icon (private/public)
 *
 * @param props           Props passed to the component
 * @param props.isPrivate Whether the topic is private
 * @returns               The component itself
 */
const TopicVisibilityIcon: FC<Props> = ({ isPrivate }) => {
  return isPrivate ? <Styled.LockIconStyled /> : <Styled.LockOpenIconStyled />;
};

export default TopicVisibilityIcon;
