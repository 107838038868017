import { styled } from 'styled-components';

import { visuallyHidden } from '../../../styles/common';
import devices from '../../../styles/devices';

import ModalHeading from './ModalHeading';
import ModalSubheading from './ModalSubheading';
import ReactModalAdapter from './adapter';
import { getImagePath, getTitleMarginTop } from './helpers';
import { ContentProps, HeadingProps, ReactModalProps } from './types';

const breakpoint = devices.tabletS;

export const classOverlay = 'Overlay';
export const classModal = 'Modal';

// Styling the base popup look
export const StyledReactModal = styled(ReactModalAdapter)<ReactModalProps>`
  --background-size: 90px;

  & .${classOverlay} {
    background-color: var(--color-shadow-modal);
    display: grid;
    inset: 0;
    position: fixed;
    z-index: var(--z-modals);
  }

  & .${classModal} {
    background-color: var(--color-background);
    background-image: url(${getImagePath});
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto var(--background-size);
    border-radius: var(--border-radius-small);
    display: flex;
    flex-direction: column;
    height: ${props => props['data-height']};
    max-height: 95vh;
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties, plugin/no-unsupported-browser-features */
    max-height: 95dvh;
    max-width: min(90vw, 700px);
    min-width: 95vw;
    outline: none;
    place-self: center;
    position: absolute;
    width: ${props => props['data-width']};

    @media ${devices.mobileL} {
      max-height: 90vh;
      min-width: 90vw;
    }

    @media ${breakpoint} {
      max-height: 80vh;
      min-width: 350px;
    }
  }
`;

// Wraps Heading and Button close
export const WrapperHeading = styled.div<HeadingProps>`
  align-items: ${props => (props['data-has-image'] ? 'flex-start' : 'center')};
  display: flex;
`;

// <header> element within the modal
export const Header = styled.header`
  flex-shrink: 0;
  padding: var(--spacing-medium) var(--spacing-medium) var(--spacing-small);

  @media ${breakpoint} {
    margin-bottom: var(--spacing-medium);
    padding: var(--spacing-large) var(--spacing-large) var(--spacing-small);
  }
`;

// <h2> element, modal title
export const Heading = styled(ModalHeading)<HeadingProps>`
  flex-grow: 1;
  margin-top: ${getTitleMarginTop};
`;

// <h3> element, modal subtitle
export const Subheading = styled(ModalSubheading)`
  margin-top: var(--spacing-small);

  &:empty {
    display: none;
  }
`;

export const ButtonBack = styled.button`
  display: flex;
  padding-right: var(--spacing-medium);
`;

export const Text = styled.p`
  ${visuallyHidden}
`;

// children prop gets rendered here
export const Content = styled.section<ContentProps>`
  flex-grow: 1;
  flex-shrink: 1;

  /**
   * Make overflow visible when using dropdown component inside of the modal Content,
   * so that opened dropdown doesn't trigger scroll
   */
  overflow: ${props => (props['data-overflow-visible'] ? 'visible' : 'auto')};
  padding: 0 var(--spacing-medium) var(--spacing-medium);

  @media ${breakpoint} {
    padding: 0 var(--spacing-large);
  }
`;

export const Separator = styled.hr`
  background-color: var(--color-border-dark);
  border: none;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;
`;

// contains all the controls
export const Footer = styled.footer`
  flex-shrink: 0;
  margin-top: var(--spacing-medium);
  padding: 0 var(--spacing-medium) var(--spacing-medium);

  @media ${breakpoint} {
    padding: 0 var(--spacing-large) var(--spacing-large);
  }
`;
