import { styled } from 'styled-components';

import { visuallyHidden } from '../../../styles/common';
import { CssVariable } from '../../../styles/cssTypes';
import devices from '../../../styles/devices';
import ButtonClose from '../../Common/ButtonClose';
import TitleCommon from '../../Common/Title';
import ButtonBack from '../../MUI/ButtonBack';

type HeaderProps = {
  'data-is-popup': boolean;
};

type ActionButtonProps = {
  'data-color'?: CssVariable;
};

/**
 * Get the color to use for button text
 *
 * @param props The props passed to the component
 * @returns     THe color to use
 */
const getColor = (props: ActionButtonProps) => {
  return props['data-color'] ?? 'var(--color-primary-light)';
};

/**
 * Get the margin to use for the header
 *
 * @param props Props passed to the header
 * @returns     The margin to use
 */
const getHeaderMargin = (props: HeaderProps): string => {
  if (props['data-is-popup']) {
    return 'var(--spacing-tiny) 0';
  }

  return '0 0 var(--spacing-small)';
};

export const Header = styled.header<HeaderProps>`
  align-items: center;
  display: inline-grid;
  gap: var(--spacing-small);
  grid-template-columns: 1fr auto 1fr;
  margin: var(--spacing-small) 0;
  padding: 0 var(--spacing-small) 0;

  @media ${devices.tabletS} {
    margin: ${getHeaderMargin};

    /* Add min-height to compensate new close icon */
    min-height: 30px;
    padding: 0;
  }
`;

export const ActionButton = styled.button<ActionButtonProps>`
  color: ${getColor};
  font-size: var(--font-sm);
  grid-column: 3;
  place-self: center end;

  @media ${devices.tabletS} {
    grid-column: 1;
    place-self: center start;
  }
`;

export const BackButton = styled(ButtonBack)`
  grid-row: 1;
  justify-self: start;
`;

export const CloseButton = styled(ButtonClose)`
  grid-column: 3;
  justify-self: end;
`;

export const Title = styled(TitleCommon)`
  grid-column: 2;

  &:empty {
    display: none;
  }

  @media ${devices.tabletS} {
    ${visuallyHidden};
  }
`;

// used for aria-describedby
export const Subheading = styled.h3`
  &:empty {
    display: none;
  }

  ${visuallyHidden};
`;
