import React, { FC } from 'react';

import IconChevron from '../../../Common/Chevron';
import Icon from '../../../Common/Icon';

import * as Styled from './styled';

type Props = {
  iconName: string;
  label: string;
};

/**
 * Inner component for MobileNavigationLink
 *
 * @param props          Props passed to the component
 * @param props.iconName Name of the icon
 * @param props.label    Link text label
 * @returns              MobileNavigationLink inner component
 */
const Inner: FC<Props> = ({ iconName, label }) => {
  return (
    <>
      <Styled.LabelWrapper>
        <Icon
          color="var(--color-icons-alt-active)"
          height={19}
          name={iconName}
          width={19}
        />
        <Styled.Label>{label}</Styled.Label>
      </Styled.LabelWrapper>
      <IconChevron direction="right" size={15} />
    </>
  );
};

export default Inner;
