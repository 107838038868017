/**
 * @file Helpers for header mobile
 */
import { NavigateFunction } from 'react-router-dom';

import { Message, TargetLanguage } from '../../generated/graphql';
import { getIsFeedMessageDetailsPage } from '../../routes/helpers/pages';

/**
 * Go to previous route from history or backUrl
 *
 * @param backUrl  Url to go back to
 * @param navigate Navigate function from react-router-dom
 */
export const goBack = (
  backUrl: string | true | null,
  navigate: NavigateFunction,
) => {
  if (backUrl === null) {
    return;
  }

  if (backUrl === true) {
    navigate(-1);
  } else {
    navigate(backUrl);
  }
};

/**
 * Get whether to show the message translate button
 *
 * @param messageId      The ID of the message that the user is viewing
 * @param targetLanguage The target language for the user
 * @returns              Whether to show the button
 */
export const getShouldShowTranslate = (
  messageId: Message['id'] | null,
  targetLanguage: TargetLanguage | null,
): boolean => {
  return getIsFeedMessageDetailsPage(messageId) && targetLanguage !== null;
};
