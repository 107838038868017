import { styled } from 'styled-components';

import devices from '../../../styles/devices';
import ButtonQuickFiltersToggle from '../../MUI/ButtonQuickFiltersToggle';
import MuiSegmentedButtonGroup from '../../MUI/SegmentedButtonGroup';

type WrapperProps = {
  'data-is-kanban': boolean;
};

// Wrapper around the entire component
export const Wrapper = styled.header<WrapperProps>`
  align-items: center;
  border-bottom: ${props =>
    props['data-is-kanban'] ? 'none' : 'var(--border-dark)'};
  column-gap: var(--spacing-small);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  justify-content: start;
  padding: var(--spacing-small) var(--spacing-medium) var(--spacing-tiny);
  position: relative;

  @media ${devices.tabletS} {
    gap: var(--spacing-small);
  }
`;

export const SegmentedButtonGroupWrapper = styled.div`
  display: flex;
  grid-row: 2;
  justify-content: stretch;

  @media ${devices.tabletS} {
    justify-content: flex-start;
  }
`;

export const SegmentedButtonGroup = styled(MuiSegmentedButtonGroup)`
  width: 100%;

  @media ${devices.tabletS} {
    /* Hardcoded min-width to match the requirements of having fixed width of the buttons */
    min-width: 15rem;
    width: auto;
  }
`;

export const QuickFiltersButton = styled(ButtonQuickFiltersToggle)`
  grid-column: 2;
  grid-row: 2;
`;

export const QuickFiltersWrapper = styled.div`
  /* Span quick filters across the grid */
  grid-column: 1 / 3;
  grid-row: 3;
`;
