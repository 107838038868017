import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';

import useIsMobile from '../../../hooks/useIsMobile';

import { getHomeUrl } from '../../urls/home';

import SearchMobile from './SearchMobile';

/**
 * Search page
 *
 * @returns The page itself
 */
const PageSearch: FC = () => {
  const isMobile = useIsMobile();
  const hotelId = useParamHotelId();

  /**
   * Show Search page on mobile
   */
  if (isMobile) {
    return <SearchMobile />;
  }

  /**
   * In this case redirect to home  (desktops don't have a /search page)
   */
  return <Navigate to={getHomeUrl(hotelId)} />;
};

export default PageSearch;
