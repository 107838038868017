/**
 * @file Helper types info stored in the URL
 */

import { Message, Scalars, Template, Topic } from '../generated/graphql';

export const RECURRING_MODES = [
  'dtstartDate',
  'until',
  'dtstartTime',
  'byMonthDay',
] as const;

/**
 * Params on the chat page
 */
export type ChatParams = {
  conversationId: Scalars['ID']['output'];
};

/**
 * Params related to compose
 */
export type ComposeModeParam =
  | 'assign'
  | 'attach'
  | 'checklist'
  | 'date'
  | 'description'
  | 'label'
  | 'offset'
  | 'template'
  | 'title'
  | 'topic';

/**
 * Params related to and used on the /feed
 */
export type FeedParams = {
  messageEditMode: MessageEditModeParam;
  messageId: Message['id'];
  topicEditMode: TopicEditModeParam;
  topicId: Topic['id'];
};

/**
 * Params for message edit modes
 */
export type MessageEditModeParam =
  | 'assign'
  | 'date'
  | 'description'
  | 'label'
  | 'title';

/**
 * Params related to template recurring popups
 */
export type RecurringFrequencyParam = 'daily' | 'weekly';

/**
 * Params related to template recurring popups
 */
export type RecurringModeParam = (typeof RECURRING_MODES)[number];

/**
 * Params that should be available to the entire app
 */
export type RootParams = {
  hotelId: string;
};

/**
 * Params related to and used on the /search
 */
export type SearchParams = {
  page: string;
  searchTerm: string;
};

export type SettingsTemplatesParams = {
  frequency: RecurringFrequencyParam;
  templateId: Template['id'];
};

/**
 * Params for topic edit modes
 */
export type TopicEditModeParam = 'assign' | 'description' | 'title';
