/**
 * @file Mark message as read when it should be
 */

import { useEffect } from 'react';

import { useMutation, useQuery } from 'urql';

import {
  MessageFullDocument,
  MessagesReadDocument,
  TopicsListDocument,
} from '../../../generated/graphql';
import useParamMessageId from '../../../hooks/router/params/useParamMessageId';
import { reportApiErrors } from '../../../utils/error';
import getShouldMarkAsRead from '../helpers/getShouldMarkAsRead';

/**
 * Mark message as read when it should be
 * (it's not already read and there are no comments for it)
 */
const useMessageRead = () => {
  const messageId = useParamMessageId() ?? '';
  const [{ data }] = useQuery({
    query: MessageFullDocument,
    variables: { messageId },
  });

  const [, requestTopicsList] = useQuery({
    pause: true,
    query: TopicsListDocument,
  });

  const [, requestMessagesRead] = useMutation(MessagesReadDocument);

  const shouldMarkAsRead = getShouldMarkAsRead(data);

  useEffect(() => {
    if (shouldMarkAsRead) {
      requestMessagesRead({ messageIds: [messageId] })
        .then(() => {
          /**
           * !!This is a monkey patch!!
           * It fixes topicList query not being updated after reading message
           * that was open with push notification (for new message)
           */
          requestTopicsList({ requestPolicy: 'network-only' });
        })
        .catch(reportApiErrors);
    }
    // Adding requestMessagesRead and requestTopicsList causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId, shouldMarkAsRead]);
};

export default useMessageRead;
