/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */
import { Location } from 'react-router-dom';

import { ROUTE__FEED, ROUTE__KANBAN } from '../../constants/routing/routes';

import { Hotel, Message, Topic } from '../../generated/graphql';
import { MessageEditModeParam } from '../../models/pageParams';
import { reportError } from '../../services/reporting';
import { getIsFeedKanbanPage } from '../helpers/pages';

import { getMessageDetailsUrl } from './message';

/**
 * Generates the URL for a message edit section on desktops
 *
 * @param hotelId   ID of the hotel
 * @param location  Location object from the history
 * @param messageId ID of the message
 * @param mode      name of the section to generate the URL for
 * @param topicId   ID of the topic
 * @returns         a string in a url format
 */
export const getMessageEditSectionDesktopUrl = (
  hotelId: Hotel['id'] | null,
  location: Location,
  messageId: Message['id'] | null,
  mode: MessageEditModeParam | null,
  topicId: Topic['id'] | null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getMessageEditSectionDesktopUrl was null');
  }

  if (messageId === null) {
    reportError('MessageId passed to getMessageEditSectionDesktopUrl was null');
  }

  if (topicId === null) {
    reportError('TopicId passed to getMessageEditSectionDesktopUrl was null');
  }

  if (mode === null) {
    return getMessageDetailsUrl(hotelId, location, messageId, topicId);
  }

  const isKanban = getIsFeedKanbanPage();
  const ROUTE = isKanban ? ROUTE__KANBAN : ROUTE__FEED;

  const { search } = location;

  return `/${hotelId}/${ROUTE}/${topicId}/post/${messageId}/details/${mode}${search}`;
};
