import React, { FC } from 'react';

import HeaderContainer from '../../../../containers/header';
import useIsMobile from '../../../../hooks/useIsMobile';
import HeaderMobile from '../../../HeaderMobile';
import useShouldHideLayoutHeader from '../hooks/useShouldHideLayoutHeader';

type Props = {
  title: string | null;
};

/**
 * Header for the settings pages layout
 *
 * @param props       Props passed to the component
 * @param props.title Header title
 * @returns           The component itself
 */
const SettingsLayoutHeader: FC<Props> = ({ title }) => {
  const isMobile = useIsMobile();
  const shouldHideHeader = useShouldHideLayoutHeader();

  if (isMobile === false) {
    return <HeaderContainer />;
  }

  if (shouldHideHeader) {
    return null;
  }

  return <HeaderMobile backUrl={true} title={title} />;
};

export default SettingsLayoutHeader;
