/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { Location } from 'react-router-dom';

import {
  removeParamsKeysFromUrl,
  replaceOrAddParamsToUrl,
} from '../../helpers/router';

/**
 * Gets url for opening the sidebar
 *
 * @param location Location object from the history
 * @returns        A string in a url format
 */
export const getOpenSidebarUrl = (location: Location): string => {
  return removeParamsKeysFromUrl(location, 'sidebar');
};

/**
 * Gets url for closing the sidebar
 *
 * @param location Location object from the history
 * @returns        A string in a url format
 */
export const getCloseSidebarUrl = (location: Location): string => {
  return replaceOrAddParamsToUrl(location, { sidebar: 'closed' });
};
