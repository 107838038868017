/**
 * @file contains hook that checks whether right sidebar is open
 */

import useCurrentPath from '../../useCurrentPath';

/**
 * React hook that tells us if sidebar (right) is open
 * based on the url
 *
 * @returns Whether right sidebar is open
 */
const useIsRightSidebarOpen = (): boolean => {
  const currentPath = useCurrentPath();

  return currentPath === 'feedMessageDetails';
};

export default useIsRightSidebarOpen;
