/**
 * @file Reacts to notification permission status, works both for web and native
 */

import { getIsReactNativeWebView } from '../../utils/webview/helpers';
import usePermissionStatus from '../usePermissionStatus';
import useWebViewDeviceInfo from '../webview/useWebViewDeviceInfo';

const mapping: Record<PermissionState, NotificationPermission> = {
  denied: 'denied',
  granted: 'granted',
  prompt: 'default',
};

/**
 * Get the status for notification permissions
 * Works both for PWA and native (within webview)
 *
 * It's null when the data is being processed
 * (occurs only when within native, on the web it's sync)
 *
 * @returns The status
 */
const useNotificationPermissionStatus = (): NotificationPermission | null => {
  const permissionWeb = usePermissionStatus('notifications');
  const { deviceInfo } = useWebViewDeviceInfo();

  const isReactNativeWebView = getIsReactNativeWebView();

  if (isReactNativeWebView) {
    return deviceInfo?.notificationPermission ?? null;
  }

  return permissionWeb === null
    ? globalThis?.Notification?.permission ?? null
    : mapping[permissionWeb];
};

export default useNotificationPermissionStatus;
