import React, { FC } from 'react';

import useAreRepliesOpen from '../../../../hooks/router/search/useAreRepliesOpen';
import useIsMobile from '../../../../hooks/useIsMobile';
import { MessageSearch } from '../../../../models/message';
import { getCanCreateComment } from '../../../../utils/permissions/comment';

import MessageSingle from '../../../Common/MessageSingle';

export type Props = {
  message: MessageSearch;
  url?: string;
};

/**
 * Single message within search results
 *
 * @param props         Props passed to the component
 * @param props.message One message result from the search
 * @param props.url     The url to navigate on element click
 * @returns             The component itself
 */
const SearchResultMessage: FC<Props> = ({ message, url }) => {
  const isMobile = useIsMobile();

  const hasComments = message.numberOfComments > 0;
  const shouldShowComments =
    getCanCreateComment(message.isArchived) || hasComments;

  const isExpanded = useAreRepliesOpen(message.id);

  return (
    <MessageSingle
      isExpanded={isExpanded}
      message={message}
      mode="feedSearch"
      shouldClamp={isMobile}
      shouldRenderLinks={false}
      shouldShowComments={shouldShowComments}
      url={url}
    />
  );
};

export default SearchResultMessage;
