import React, { FC, FormEventHandler, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  SEARCH__AUTOSUBMIT_TIMEOUT,
  SEARCH__INPUT_PATTERN,
} from '../../../constants/preferences';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamSearchTerm from '../../../hooks/router/params/useParamSearchTerm';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';
import { getSearchTextUrl } from '../../../routes/urls/search';
import { submitFormProgrammatically } from '../../../utils/forms/helpers';
import SearchField from '../../SearchField';

// We want to submit the form 1s after the user types the last letter,
// so we don't overwhelm the backend with requests after each key
let timerId = 0;

/**
 * Search form that starts text search on the desktop
 *
 * @returns Search desktop form
 */
const SearchForm: FC = () => {
  const hotelId = useParamHotelId();
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();
  // Doesn't just use the URL value directly
  // because we have a timeout to update the URL on key type
  const [searchTerm, setSearchTerm] = useState(useParamSearchTerm() ?? '');
  const shouldFocus = isMobile || searchTerm.length !== 0;

  const formRef = useRef<HTMLFormElement>(null);

  /**
   * When input is changed, update local state,
   * and if we're on desktop, start a timer to automatically submit the form
   * in 1s, if the user doesn't type anything else
   *
   * @param value The new value
   */
  const onChange = (value: string): void => {
    setSearchTerm(value);

    // Don't submit automatically on mobile
    // because input only exists on the search page
    if (isMobile === true) {
      return;
    }

    window.clearTimeout(timerId);
    timerId = window.setTimeout(() => {
      if (value.trim().length === 0) {
        return;
      }

      submitFormProgrammatically(formRef);
    }, SEARCH__AUTOSUBMIT_TIMEOUT);
  };

  /**
   * The user has submitted the form, so we update the url
   *
   * @param event The event that took place
   */
  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    // Navigate to 1st page in search results
    const page = 1;
    navigate(getSearchTextUrl(hotelId, searchTerm, page, location.search));
  };

  return (
    <form onSubmit={handleSubmit} ref={formRef} role="search">
      <SearchField
        onChange={onChange}
        pattern={SEARCH__INPUT_PATTERN}
        placeholder={translate('TOPIC__SEARCH__PLACEHOLDER')}
        shouldFocus={shouldFocus}
        value={searchTerm}
      />
    </form>
  );
};

export default SearchForm;
