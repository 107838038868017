import React, { FC, ReactNode } from 'react';

import NavLinks from './NavLinks';
import * as Styled from './styled';

type Props = {
  floatingButton?: ReactNode;
};

/**
 * Bottom Navigation Bar that is shown only on mobile screens
 *
 * @param props                Props passed to the component
 * @param props.floatingButton Floating button to show about the navigation
 * @returns                    The component itself
 */
const FloatingBottomNav: FC<Props> = ({ floatingButton }) => {
  return (
    <Styled.Nav>
      {floatingButton && (
        <Styled.FloatingButtonWrapper>
          {floatingButton}
        </Styled.FloatingButtonWrapper>
      )}

      <NavLinks />
    </Styled.Nav>
  );
};

export default FloatingBottomNav;
