/**
 * @file Helper functions for Compose component
 */

import { Location } from 'react-router-dom';

import { ComposeNodeId, ComposeNodeType } from '../../containers/compose/props';
import translate from '../../i18n/translate';
import { getIsFeedKanbanPage } from '../../routes/helpers/pages';
import { getCloseComposeUrl } from '../../routes/urls/searchParams/compose';

/**
 * @todo Refactor the whole navigation logic for header component
 * (There is one extra render on kanban page on mobile when opening the compose form
 * and because of that we can't use navigate(-1))
 * Get back for templates back button
 *
 * @param isMobile Boolean that tells us are we on mobile device or not
 * @param location React router location object
 * @returns        Url string if isMobile=false and true if isMobile=true
 */
export const getBackUrl = (
  isMobile: boolean,
  location: Location,
): string | true => {
  const isKanban = getIsFeedKanbanPage();

  if (isMobile === false) {
    return getCloseComposeUrl(location);
  }

  return isKanban ? getCloseComposeUrl(location) : true;
};

/**
 * Get which title to use for the compose form
 *
 * @param nodeId   The ID of the node (template) being edited (null for creation)
 * @param nodeType Whether the compose form is being used for messages or templates
 * @returns        The title to use
 */
export const getComposeTitle = (
  nodeId: ComposeNodeId,
  nodeType: ComposeNodeType,
): string => {
  if (nodeType === 'MESSAGE') {
    return translate('COMPOSE__TITLE');
  }

  if (nodeId === null) {
    return translate('SETTINGS__TEMPLATES__COMPOSE');
  }

  return translate('SETTINGS__TEMPLATES__COMPOSE__EDIT');
};
