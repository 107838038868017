import { styled } from 'styled-components';

import CommonLinkIconWithText from '../../components/Common/LinkIconWithText';

import CommonHotelInfo from '../../components/HotelInfo';
import devices from '../../styles/devices';

export const Wrapper = styled.div`
  display: none;
  padding: 0 var(--spacing-medium) var(--spacing-medium);

  @media ${devices.tabletS} {
    display: block;
  }
`;

export const HotelInfo = styled(CommonHotelInfo)`
  padding: 0;
`;

export const LinkIconWithText = styled(CommonLinkIconWithText)`
  margin-bottom: var(--spacing-small);

  & svg {
    transform: rotate(180deg);
  }
`;
