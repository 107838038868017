import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ILLUSTRATION__SEARCH } from '../../../constants/illustrations';
import translate from '../../../i18n/translate';
import EmptyState from '../../Common/EmptyState';

import * as Styled from './styled';

export type Props = {
  searchTerm: string | null;
};

/**
 * Component that renders empty state for search results
 *
 * @param props            Props passed to the component
 * @param props.searchTerm Search term used if any
 * @returns                Search Results empty state component
 */
const SearchResultsEmpty: FC<Props> = ({ searchTerm }) => {
  const navigate = useNavigate();

  /**
   * The user has clicked the back button, so we navigate back
   */
  const goBack = () => {
    navigate(-1);
  };

  /**
   * Get which text to show as the title
   *
   * @returns The text to show
   */
  const getTitleText = () => {
    if (searchTerm !== null) {
      return translate('SEARCH__NO_RESULTS__TITLE', searchTerm);
    }

    return translate('SEARCH__NO_RESULTS__TITLE_FALLBACK');
  };

  /**
   * Get which text to show as the subtitle
   *
   * @returns The text to show (or undefined if none)
   */
  const getSubtitleText = (): string | undefined => {
    if (searchTerm === null) {
      return;
    }

    return translate('SEARCH__NO_RESULTS__SUBTITLE');
  };

  return (
    <Styled.Wrapper>
      <EmptyState
        illustrationName={ILLUSTRATION__SEARCH}
        subtitle={getSubtitleText()}
        title={getTitleText()}
      />
      <Styled.Button onClick={goBack} type="button">
        {translate('GENERAL__BACK_TO_PREVIOUS_PAGE')}
      </Styled.Button>
    </Styled.Wrapper>
  );
};

export default SearchResultsEmpty;
