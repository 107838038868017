import { styled } from 'styled-components';

import Button from '../../components/Common/Button';

export const RefreshButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: var(--color-primary-light);
  font-weight: var(--font-weight-label-bold);
`;
