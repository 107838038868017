import { styled } from 'styled-components';

import LinkIconCommon from '../Common/LinkIcon';

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spacing-medium);
`;

export const H2 = styled.h2`
  font-size: var(--font-lg);
  font-weight: 600;
  margin-bottom: var(--spacing-small);

  &:empty {
    display: none;
  }
`;

export const H3 = styled.h3`
  font-size: var(--font-baseline);

  &:empty {
    display: none;
  }
`;

export const LinkIcon = styled(LinkIconCommon)`
  & svg {
    /* Since we are using right facing chevron as a back icon rotate it to be left facing */
    transform: rotate(180deg);
  }
`;
