import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const Ul = styled.ul`
  align-items: center;
  background-color: var(--color-background);
  display: flex;
  justify-content: space-around;
  padding: 0 var(--spacing-medium);
`;

export const Li = styled.li`
  display: flex;
`;

const selectedBorderWidth = '3px';

export const NavLink = styled(Link)`
  border-bottom: ${selectedBorderWidth} solid transparent;
  border-top: solid ${selectedBorderWidth} transparent;
  padding: var(--spacing-medium) var(--spacing-small);
  position: relative;
  text-align: center;
  width: 100%;

  &[aria-current='page'] {
    border-top-color: var(--color-icons-alt-active);
  }
`;
