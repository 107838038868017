import { styled } from 'styled-components';

export const SelectItemWrapper = styled.span`
  padding-left: var(--spacing-tiny);
`;

export const DisabledSelect = styled.h3`
  border: transparent;
  color: var(--color-grey-darkest);

  /* unsupported on iOS, but doesn't matter since that's touch input anyway */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  cursor: not-allowed;
  padding: var(--spacing-tiny);
`;
