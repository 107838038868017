import { Link as RouterLink } from 'react-router-dom';
import { styled } from 'styled-components';

import devices from '../../../styles/devices';

const breakpoint = devices.tabletS;

export const Link = styled(RouterLink)`
  align-items: center;
  border-radius: var(--border-radius-small);
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-medium);

  @media ${breakpoint} {
    display: flex;
    font-weight: var(--font-light);
    padding: var(--spacing-small);
    transition: background-color var(--preference-transition-duration-normal);

    &[aria-current='page'] {
      background-color: var(--color-background-selected);
      font-weight: var(--font-semibold);
    }

    &:hover {
      background-color: var(--color-hover-light);
    }
  }
`;
