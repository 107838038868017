import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { visuallyHidden } from '../../styles/common';
import devices from '../../styles/devices';
import Chevron from '../Common/Chevron';

export const Wrapper = styled.div`
  background-color: var(--color-background);
  border-right: var(--border-dark);
  display: flex;
  flex-direction: column;
  height: 100%;

  /**
   * temporary to add space between footer and content if there are too many
   * topics on mobile
   * @todo - find a better solution including the footer
   */
  overflow-y: auto;
  padding-bottom: var(--spacing-medium);

  @media ${devices.tabletS} {
    /**
    * This was added to negate overflow-y:auto for desktops, since InnerWrapper is handling the scroll.
    */
    margin-bottom: 0;
    overflow-y: visible;
    padding-bottom: 0;
    padding-top: var(--spacing-small);
  }
`;

const SidebarDesktopLink = styled(Link)`
  align-items: center;
  background-color: var(--color-primary-lighter);
  border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
  border-right-width: 1px;
  height: var(--spacing-huge);
  justify-content: center;
  left: 100%;
  padding: var(--spacing-small);
  position: absolute;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  width: fit-content;

  @media ${devices.laptopS} {
    padding: 0;
    width: var(--spacing-medium);
  }
`;

export const CloseSidebarDesktopLink = styled(SidebarDesktopLink)`
  display: flex;

  @media ${devices.tabletS} {
    right: var(--preference-sidebar-triangle-width);
    top: var(--preference-sidebar-triangle-position-top);
  }

  /* Hide closeLink when sidebar wrapper is not hovered and closeLink is not in focus
  or when closeLink is not in focus.
  We do it this way so we don't have to 'reset' visuallyHidden props to component's defaults
  */
  @media (hover: hover) {
    ${Wrapper}:not(:hover) &:not(:focus) {
      ${visuallyHidden}
    }
  }
`;

export const OpenSidebarDesktopLink = styled(SidebarDesktopLink)`
  display: none;

  @media ${devices.tabletS} {
    display: flex;
    right: var(--preference-sidebar-triangle-width);
    top: var(--preference-sidebar-triangle-position-top);
  }
`;

export const ChevronIcon = styled(Chevron)`
  background-color: var(--color-primary-lighter);
`;
