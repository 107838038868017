import React, { FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import { useIsChatEnabled } from '../../../integrations/roqChat/useIsChatEnabled';

import NavLinkIcon from './NavLinkIcon';
import { CHAT_ICON, getNavLinks } from './getNavLinks';
import * as Styled from './styled';

/**
 * Navigation links for Bottom Navigation Bar for mobile
 *
 * @returns The component itself
 */
const NavLinks: FC = () => {
  const hotelId = useParamHotelId();
  const isChatEnabled = useIsChatEnabled();
  const { pathname } = useLocation();
  const navLinks = getNavLinks(hotelId);

  return (
    <Styled.Ul>
      {navLinks.map(({ ariaLabel, iconName, paths, url }) => {
        const isChat = iconName === CHAT_ICON;
        const isSelected = paths.some(
          path => matchPath(path, pathname) !== null,
        );

        if (isChat && isChatEnabled !== true) {
          return null;
        }

        return (
          <Styled.Li key={iconName}>
            <Styled.NavLink
              aria-current={isSelected ? 'page' : 'false'}
              aria-label={ariaLabel}
              to={url}
            >
              <NavLinkIcon iconName={iconName} isSelected={isSelected} />
            </Styled.NavLink>
          </Styled.Li>
        );
      })}
    </Styled.Ul>
  );
};

export default NavLinks;
