/**
 * @file contains concierge field types
 */

/**
 * Values used on the backend
 * (Messaging API only)
 */
export const RAW_TEXT = '1';
export const RAW_TEXT_AREA = '2';
export const RAW_CHECKBOX = '3';
export const RAW_RADIOBOX_GROUP = '4';
export const RAW_DATE = '5';
export const RAW_DATETIME = '6';
export const RAW_TIME = '7';
export const RAW_DROPDOWN = '8'; //not used
export const RAW_EMAIL = '9';
export const RAW_SUBMIT = '10';
export const RAW_TITLE = '11';
export const RAW_SUBTITLE = '12';
export const RAW_DESCRIPTION = '13';
export const RAW_DIVIDER = '14';
export const RAW_FEEDBACK_EMOJI = '15';
export const RAW_FEEDBACK_STARS = '16';
export const RAW_SIGNATURE = '17';

/**
 * Values used elsewhere
 * (frontend and other backend APIs)
 */
export const TEXT = 1;
export const TEXT_AREA = 2;
export const CHECKBOX = 3;
export const RADIOBOX_GROUP = 4;
export const DATE = 5;
export const DATETIME = 6;
export const TIME = 7;
export const DROPDOWN = 8; //not used
export const EMAIL = 9;
export const SUBMIT = 10;
export const TITLE = 11;
export const SUBTITLE = 12;
export const DESCRIPTION = 13;
export const DIVIDER = 14;
export const FEEDBACK_EMOJI = 15;
export const FEEDBACK_STARS = 16;
export const SIGNATURE = 17;

/**
 * All the Messaging API field types
 */
export type ConciergeFieldTypeRaw =
  | typeof RAW_TEXT
  | typeof RAW_TEXT_AREA
  | typeof RAW_CHECKBOX
  | typeof RAW_RADIOBOX_GROUP
  | typeof RAW_DATE
  | typeof RAW_DATETIME
  | typeof RAW_TIME
  | typeof RAW_DROPDOWN
  | typeof RAW_EMAIL
  | typeof RAW_SUBMIT
  | typeof RAW_TITLE
  | typeof RAW_SUBTITLE
  | typeof RAW_DESCRIPTION
  | typeof RAW_DIVIDER
  | typeof RAW_FEEDBACK_EMOJI
  | typeof RAW_FEEDBACK_STARS
  | typeof RAW_SIGNATURE;

/**
 * All the Messaging API textual fields
 */
export type ConciergeFieldTypeTextRaw =
  | typeof RAW_TEXT
  | typeof RAW_TEXT_AREA
  | typeof RAW_DATE
  | typeof RAW_DATETIME
  | typeof RAW_TIME
  | typeof RAW_EMAIL
  | typeof RAW_SUBMIT
  | typeof RAW_TITLE
  | typeof RAW_SUBTITLE
  | typeof RAW_DESCRIPTION
  | typeof RAW_DIVIDER
  | typeof RAW_SIGNATURE;

/**
 * All the possible concierge field types
 */
export type ConciergeFieldType =
  | typeof TEXT
  | typeof TEXT_AREA
  | typeof CHECKBOX
  | typeof RADIOBOX_GROUP
  | typeof DATE
  | typeof DATETIME
  | typeof TIME
  | typeof DROPDOWN
  | typeof EMAIL
  | typeof SUBMIT
  | typeof TITLE
  | typeof SUBTITLE
  | typeof DESCRIPTION
  | typeof DIVIDER
  | typeof FEEDBACK_EMOJI
  | typeof FEEDBACK_STARS
  | typeof SIGNATURE;

/**
 * All the textual fields
 */
export type ConciergeFieldTypeText =
  | typeof TEXT
  | typeof TEXT_AREA
  | typeof DATE
  | typeof DATETIME
  | typeof TIME
  | typeof EMAIL
  | typeof SUBMIT
  | typeof TITLE
  | typeof SUBTITLE
  | typeof DESCRIPTION
  | typeof DIVIDER
  | typeof SIGNATURE;
