import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import translate from '../../i18n/translate';

import {
  getCloseSidebarUrl,
  getOpenSidebarUrl,
} from '../../routes/urls/searchParams/sidebar';

import * as Styled from './styled';

type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  shouldShowDesktop: boolean;
};

const chevronSize = 11;

/**
 * Left sidebar layout component
 *
 * @param props                   Props passed to the component
 * @param props.className         Styled-components generated class name, needed for styling
 * @param props.children          Children passed to the component
 * @param props.shouldShowDesktop Is LeftSidebar visible or not
 * @returns                       The component itself
 */
const LeftSidebar: FC<Props> = ({ children, className, shouldShowDesktop }) => {
  const location = useLocation();

  const closeSidebarUrl = getCloseSidebarUrl(location);
  const openSidebarUrl = getOpenSidebarUrl(location);

  const label = translate('ARIA__LABEL__LEFT_SIDEBAR');

  if (shouldShowDesktop === true) {
    return (
      <Styled.Wrapper>
        <Styled.CloseSidebarDesktopLink aria-label={label} to={closeSidebarUrl}>
          <Styled.ChevronIcon
            className={className}
            color="var(--color-border-light)"
            direction="left"
            size={chevronSize}
          />
        </Styled.CloseSidebarDesktopLink>
        {children}
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.OpenSidebarDesktopLink aria-label={label} to={openSidebarUrl}>
        <Styled.ChevronIcon
          className={className}
          color="var(--color-border-light)"
          direction="right"
          size={chevronSize}
        />
      </Styled.OpenSidebarDesktopLink>
      {children}
    </Styled.Wrapper>
  );
};

export default LeftSidebar;
