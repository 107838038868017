import React, { FC } from 'react';

import FloatingBottomNav from '../../../../components/FloatingBottomNav';
import HeaderMobile from '../../../../components/HeaderMobile';
import HotelInfo from '../../../../components/HotelInfo';

import AppLayout from '../../../../components/layouts/AppLayout';
import DashboardContent from '../../../../containers/DashboardContent';
import LeftSidebarFeedContainer from '../../../../containers/leftSidebarFeed';
import useIsLeftSidebarOpen from '../../../../hooks/router/search/useIsLeftSidebarOpen';
import useCurrentHotel from '../../../../hooks/useCurrentHotel';
import useIsMobile from '../../../../hooks/useIsMobile';
import useIsTablet from '../../../../hooks/useIsTablet';
import translate from '../../../../i18n/translate';
import { getUserCurrent } from '../../../../utils/permissions/storage';
import FeedFooter from '../../Feed/Feed/FeedFooter';
import FeedHeader from '../../Feed/Feed/FeedHeader';

/**
 * Dashboard page with KPIs
 *
 * @returns The component itself
 */
const PageDashboard: FC = () => {
  const hotel = useCurrentHotel();
  const user = getUserCurrent();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isLeftSidebarOpen = useIsLeftSidebarOpen();
  const clickOutsideStopExecLeftSidebar = !isTablet || !isLeftSidebarOpen;

  if (user === null) {
    /**
     * @todo Create GENERAL__USER and replace 'User' or ask some higher
     * thinking creature what should be the best message in this case
     */
    return <div>{translate('PLACEHOLDER__DATA_UNAVAILABLE', 'User')}</div>;
  }

  if (hotel === null) {
    return null;
  }

  const imageUrl = hotel?.imageUrl;
  const hotelTitle = hotel?.name ?? '';

  if (isMobile) {
    return (
      <AppLayout
        footer={<FloatingBottomNav />}
        header={
          <HeaderMobile
            title={<HotelInfo imageUrl={imageUrl} title={hotelTitle} />}
          />
        }
      >
        <DashboardContent />
      </AppLayout>
    );
  }

  return (
    <AppLayout
      footer={<FeedFooter />}
      header={<FeedHeader />}
      leftSidebar={{
        clickOutsideOptions: {
          stopExec: clickOutsideStopExecLeftSidebar,
        },
        component: (
          <LeftSidebarFeedContainer shouldShowDesktop={isLeftSidebarOpen} />
        ),
        shouldShowDesktop: isLeftSidebarOpen,
        shouldShowMobile: false,
      }}
    >
      <DashboardContent />
    </AppLayout>
  );
};

export default PageDashboard;
