import { styled } from 'styled-components';

import devices from '../../styles/devices';

// Wrapper around the entire component
export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  /**
   * Regulates font-size for the whole comments wrapper
   * Its for username, date, comment and "close comments" button
   */
  font-size: var(--font-baseline);
  margin-top: var(--spacing-tiny);
  overflow-y: auto;

  /**
  * A fix for the scrolling issue on mobile for comments overflow
  * pushing the page up
  * Resolved by creating another layer with position relative
  */
  position: relative;
`;

export const LoadMoreButton = styled.button`
  align-items: center;
  align-self: center;
  background-color: var(--color-background-button-more);
  border: var(--border-dark);
  border-radius: var(--border-radius-small);
  color: var(--color-button-more);
  display: flex;
  font-size: var(--font-sm);
  font-weight: var(--font-weight-text);
  justify-content: center;

  /**
   * margin-right in order to align button with message wrapper (tablet and smaller devices)
   */
  margin-right: var(--spacing-medium);
  padding: var(--spacing-tiny) var(--spacing-small);

  /**
   *Fit-content is not fully supported on Mozilla, prefix is needed (disable linter for vendor prefix)
   */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features, value-no-vendor-prefix */
  width: -moz-fit-content;

  /**
   * Fit-content without prefix is for all browsers except Mozilla (disable linter for duplicate properties)
   */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features, declaration-block-no-duplicate-properties */
  width: fit-content;

  @media ${devices.tabletS} {
    /**
     * In order to center the item we need to move it to the left for width value of avatar + gap (desktop)
     */
    margin-right: calc(var(--spacing-large) + var(--spacing-small));
  }

  &:hover {
    background-color: var(--color-background-button-more-hover);
    color: var(--color-button-more-hover);
    font-weight: var(--font-weight-label-bold);
  }
`;

export const WrapperNoComments = styled.div`
  padding-top: var(--spacing-small);
  text-align: center;
`;
