/**
 * @file Hooks for creating a functional custom dropdown menu
 */

import { KeyboardEvent } from 'react';

import useClickOutside from '../useClickOutside';

import getKeyboardHandlerButton from './keyboardHandlerButton';
import getKeyboardHandlerItem from './keyboardHandlerItem';

import {
  ComboBoxProps,
  Item,
  ItemProps,
  ListProps,
  UseSelectParams,
  UseSelectReturn,
  WrapperProps,
} from './types';
import useAutoFocusItem from './useAutoFocusItem';
import useFocusHandlers from './useFocusHandlers';
import useSelectRefs from './useSelectRefs';
import useSelectState from './useSelectState';
import useTypeAhead from './useTypeAhead';

/**
 * Generates props needed in order to create a single select accessible dropdown component
 *
 * @param args             Args passed to the hook
 * @param args.defaultItem Item that is selected by default
 * @param args.itemCount   Number of items in the list
 * @param args.name        Name attribute
 * @param args.onChange    onChange callback
 * @param args.selectId    id used to generate id
 * @returns                State and props for the select used to create accessible select dropdown components
 */
const useSelect = ({
  defaultItem,
  itemCount,
  name,
  onChange,
  selectId,
}: UseSelectParams): UseSelectReturn => {
  /**
   * Generates refs
   */
  const { buttonRef, itemRefs, wrapperRef } = useSelectRefs(itemCount);

  /**
   * Gives access to hook's inner state
   */
  const { setSelectedItem, selectedItem, isOpen, open, close, toggle } =
    useSelectState(defaultItem);

  /**
   * Closes the dropdown when clicked outside of the list or button
   */
  useClickOutside({
    onClick: close,
    ref: wrapperRef,
  });

  /**
   * Generates button ID that can be used for labels
   */
  const id = selectId;

  /**
   * Creates focus handlers and tells us which element is currently focused
   */
  const { currentFocusIndex, focusHandlers } = useFocusHandlers({
    buttonRef,
    itemCount,
    itemRefs,
  });

  /**
   * Initiates type-ahead functionality
   */
  const setTypeAheadValue = useTypeAhead({
    focusItem: focusHandlers.toItem,
    itemRefs,
  });

  /**
   * Focuses an item after menu has been opened
   */
  useAutoFocusItem({
    focusFirstItem:
      selectedItem === undefined ? focusHandlers.toFirstItem : null,
    focusItem: focusHandlers.toItem,
    isOpen,
    itemRefs,
  });

  /**
   * Selects an item, resets type ahead, closes the menu and focuses the combobox
   *
   * @param item List option item to select
   */
  const selectItem = (item: Item) => {
    setTypeAheadValue('');
    setSelectedItem(item);
    close();
    focusHandlers.toButton();

    if (onChange) {
      onChange(item.value);
    }
  };

  /**
   * Initiates keyboard handlers for button
   */
  const handleKeyDownButton = getKeyboardHandlerButton({ open });

  /**
   * Initiates keyboard handlers for items
   */
  const handleKeyDownItem = getKeyboardHandlerItem({
    close,
    currentFocusIndex,
    focusHandlers,
    itemCount,
    selectItem,
    setTypeAheadValue,
  });

  /**
   * Gets the props that are spread on the combo box that toggles the select
   *
   * @returns ComboBoxProps
   */
  const getComboBoxProps = (): ComboBoxProps => {
    const props: ComboBoxProps = {
      'aria-controls': name,
      'aria-expanded': isOpen ? 'true' : 'false',
      'aria-haspopup': 'listbox',
      'aria-labelledby': id,
      onClick: toggle,
      onKeyDown: (event: KeyboardEvent) => handleKeyDownButton(event),
      ref: buttonRef,
      role: 'combobox',
      tabIndex: 0,
    };

    if (selectedItem?.value !== undefined) {
      props['aria-activedescendant'] = selectedItem?.value;
    }

    return props;
  };

  /**
   * Gets the props that are spread on the list that holds select option items
   *
   * @returns ListProps
   */
  const getListProps = (): ListProps => ({
    'aria-labelledby': id,
    id: name,
    role: 'listbox',
    tabIndex: -1,
  });

  /**
   * Gets the props that are spread on the option item
   *
   * @param item  Option item object
   * @param index Index of the option item
   * @returns     ItemProps
   */
  const getItemProps = (item: Item, index: number): ItemProps => ({
    'aria-label': item.labelText ?? item.label,
    'aria-selected': selectedItem?.value === item.value ? 'true' : 'false',
    id: item.value,
    onClick: () => selectItem(item),
    onKeyDown: (event: KeyboardEvent) => handleKeyDownItem(event, item),
    ref: itemRefs[index],
    role: 'option',
    tabIndex: -1,
  });

  /**
   * Gets the props for the wrapper of the entire select
   *
   * @returns WrapperProps
   */
  const getWrapperProps = (): WrapperProps => ({
    ref: wrapperRef,
  });

  /**
   * State of the select that shows whether it is open and which idem
   * is selected
   *
   * @returns State of the select
   */
  const state = {
    isOpen,
    selectedItem,
  };

  return {
    getComboBoxProps,
    getItemProps,
    getListProps,
    getWrapperProps,
    state,
  };
};

export { useSelect as default, type Item };
