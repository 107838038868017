/**
 * @file All navigator callbacks used for generating proxy links
 */

import { getChatConversationUrl } from '../../../urls/chat';
import { getMessageDetailsUrl } from '../../../urls/message';
import {
  getSettingsGroupsUrl,
  getSettingsLabelsUrl,
  getSettingsPeopleUrl,
  getSettingsTopicsUrl,
} from '../../../urls/settings';
import { getTopicUrl } from '../../../urls/topic';
import { NavigationFunction } from '../types';

/**
 * Returns a URL for a chat conversation
 *
 * @param params The search params
 * @returns      Generated URL
 */
export const navigatorChatConversation: NavigationFunction = params => {
  return getChatConversationUrl(params.hotelId, params.conversationId);
};

/**
 * Returns a URL for a label settings
 *
 * @param params The search params
 * @returns      Generated URL
 */
export const navigatorLabel: NavigationFunction = params => {
  return getSettingsLabelsUrl(params.hotelId);
};

/**
 * Returns a URL for a message detail
 *
 * @param params The search params
 * @returns      Generated URL
 */
export const navigatorMessage: NavigationFunction = params => {
  const navigatorParams = null;
  const { hotelId, messageId, topicId } = params;
  return getMessageDetailsUrl(hotelId, navigatorParams, messageId, topicId);
};

/**
 * Returns a URL for a topic navigation
 *
 * @param params The search params
 * @returns      Generated URL
 */
export const navigatorTopicMain: NavigationFunction = params => {
  const searchParams = null;
  const { hotelId, topicId } = params;
  return getTopicUrl(hotelId, searchParams, topicId);
};

/**
 * Returns a URL for a topic settings
 *
 * @param params The search params
 * @returns      Generated URL
 */
export const navigatorTopicSettings: NavigationFunction = params => {
  return getSettingsTopicsUrl(params.hotelId);
};

/**
 * Returns a URL for a user settings
 *
 * @param params The search params
 * @returns      Generated URL
 */
export const navigatorUser: NavigationFunction = params => {
  return getSettingsPeopleUrl(params.hotelId);
};

/**
 * Returns a URL for a group settings
 *
 * @param params The search params
 * @returns      Generated URL
 */
export const navigatorUserGroup: NavigationFunction = params => {
  return getSettingsGroupsUrl(params.hotelId);
};
