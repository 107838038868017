import React, { FC, useId } from 'react';
import { useNavigate } from 'react-router-dom';

import MessageSection from '..';

import { Message } from '../../../../generated/graphql';
import useParamMessageEditMode from '../../../../hooks/router/params/useParamMessageEditMode';
import translate from '../../../../i18n/translate';
import RichText from '../../../Common/RichText';
import CommonTextForm from '../../../Forms/CommonTextForm';
import useMessageSectionUrl from '../../hooks/useMessageSectionUrl';

export type Props = {
  canUpdateMessage: boolean;
  maxLength?: HTMLInputElement['maxLength'];
  mode: 'description' | 'title';
  requestMessageTextUpdate: (text: Message['text']) => void;
  text: Message['text'];
};

/**
 * Section in the message that displays or lets user update description (text) or title
 *
 * @param props                          Props passed to the component
 * @param props.canUpdateMessage         Whether that user has the permission to edit the message
 * @param props.maxLength                Max message text length
 * @param props.mode                     Whether it's about editing title or description
 * @param props.requestMessageTextUpdate Callback that updates the title
 * @param props.text                     Message text value
 * @returns                              The component itself
 */
const MessageSectionText: FC<Props> = ({
  canUpdateMessage,
  maxLength,
  mode,
  requestMessageTextUpdate,
  text,
}) => {
  const messageEditMode = useParamMessageEditMode();
  const navigate = useNavigate();

  const idForm = useId();

  const heading =
    mode === 'description'
      ? translate('GENERAL__DESCRIPTION')
      : translate('GENERAL__TITLE');

  const linkTo = useMessageSectionUrl({
    mode,
  });

  const urlModeOverview = useMessageSectionUrl({
    mode: null,
  });

  /**
   * The user has cancelled editing, so we just close the form
   */
  const handleCancel = () => {
    navigate(urlModeOverview, { replace: true });
  };

  /**
   * The user has confirmed text change, so we save it and close the form
   *
   * @param messageText The text the user entered
   */
  const handleTextUpdate = (messageText: Message['text']) => {
    requestMessageTextUpdate(messageText);
    handleCancel();
  };

  const isEditing = mode === messageEditMode;

  if (isEditing) {
    return (
      <MessageSection heading={heading}>
        <CommonTextForm
          descriptionUsage="messageEdit"
          formId={idForm}
          isDisabled={canUpdateMessage === false}
          maxLength={maxLength}
          mode={mode}
          requestCancel={handleCancel}
          requestSave={handleTextUpdate}
          shouldFocus={true}
          shouldShowActionButtons={true}
          text={text}
        />
      </MessageSection>
    );
  }

  return (
    <MessageSection
      heading={heading}
      linkTo={canUpdateMessage ? linkTo : undefined}
    >
      <RichText>{text}</RichText>
    </MessageSection>
  );
};

export default MessageSectionText;
