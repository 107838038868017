import React, { FC } from 'react';

import { Navigate } from 'react-router';

import FloatingBottomNav from '../../../components/FloatingBottomNav';
import HeaderMobile from '../../../components/HeaderMobile';
import AppLayout from '../../../components/layouts/AppLayout';

import DevPanel from '../../../containers/devPanel';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import translate from '../../../i18n/translate';
import { getCanViewDevPanel } from '../../../utils/permissions/devPanel';
import { getDashboardUrl } from '../../urls/dashboard';

/**
 * Dev panel page, shows dev panel inline,
 * for mobile, as keyboard shortcuts can't be used there
 *
 * @returns The component itself
 */
const PageDevPanel: FC = () => {
  const hotelId = useParamHotelId();

  if (getCanViewDevPanel() === false) {
    return <Navigate to={getDashboardUrl(hotelId)} />;
  }

  return (
    <AppLayout
      footer={<FloatingBottomNav />}
      header={
        <HeaderMobile
          backUrl={true}
          title={translate('DEBUG__DEV_PANEL__LINK')}
        />
      }
    >
      <DevPanel showInline={true} />
    </AppLayout>
  );
};

export default PageDevPanel;
