import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import ButtonTranslateContainer from '../../../../containers/buttonTranslate';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useParamMessageId from '../../../../hooks/router/params/useParamMessageId';
import useParamTopicId from '../../../../hooks/router/params/useParamTopicId';
import useTargetLanguage from '../../../../hooks/useTargetLanguage';
import translate from '../../../../i18n/translate';
import { getTopicUrl } from '../../../../routes/urls/topic';
import Icon, { NAMES } from '../../../Common/Icon';

import * as Styled from './styled';

type Props = {
  className?: string;
};

const ICON_SIZE = 14;

/**
 * Right Sidebar Header component
 *
 * @param props           props passed to the component
 * @param props.className styled-components generated class name
 * @returns               The component itself
 */
const RightSidebarHeader: FC<Props> = ({ className }) => {
  const location = useLocation();
  const hotelId = useParamHotelId();
  const topicId = useParamTopicId();
  const messageId = useParamMessageId();
  const targetLanguage = useTargetLanguage();

  return (
    <Styled.Wrapper className={className}>
      {/* Since right sidebar can be for a topic and a message, we need to check if
      there is a messageId before showing the translate button */}
      {targetLanguage !== null && messageId && (
        <ButtonTranslateContainer container="details" messageId={messageId} />
      )}
      <Styled.CloseSidebarDesktopLink
        aria-label={translate('GENERAL__CLOSE')}
        to={getTopicUrl(hotelId, location, topicId)}
      >
        <Icon
          height={ICON_SIZE}
          name={NAMES.GENERAL__CLOSE}
          width={ICON_SIZE}
        />
      </Styled.CloseSidebarDesktopLink>
    </Styled.Wrapper>
  );
};

export default RightSidebarHeader;
