/**
 * @file Config generators for all settings pages
 */

import React from 'react';

import {
  MODAL__GROUP_CREATE,
  MODAL__LABEL_CREATE,
  MODAL__TOPIC_CREATE,
} from '../../../../constants/routeModalParams';
import { getOpenModalUrl } from '../../../../containers/modals/helpers';
import OpenComposeButton from '../../../../containers/openComposeButton';
import translate from '../../../../i18n/translate';

import { getCanCreateGroup } from '../../../../utils/permissions/group';
import { getCanCreateLabel } from '../../../../utils/permissions/label';
import { getCanCreateTemplate } from '../../../../utils/permissions/template';
import { getCanCreateTopic } from '../../../../utils/permissions/topic';
import ButtonAdd from '../../../Common/ButtonAdd';
import { ButtonProps } from '../../../Common/Modal';
import { ConfigCallback, LayoutConfig } from '../types';

/**
 * Generate ButtonProps for settings page
 * (only the create button is used at the moment)
 *
 * @param label   The translation key for the button text
 * @param onClick Button onClick callback
 * @returns       The buttons
 */
const getButtons = (label: string, onClick: () => void): ButtonProps[] => {
  return [
    {
      children: translate(label),
      key: 'create',
      onClick,
      type: 'button',
    },
  ];
};

/**
 * Get layout config for the Settings Groups page
 *
 * @param location react-router-dom location object
 * @param navigate react-router-dom navigate function
 * @returns        The desired config
 */
export const getConfigGroups: ConfigCallback = (location, navigate) => {
  const canCreate = getCanCreateGroup();

  /**
   * Get callback to show the group create modal
   *
   * @returns The callback to invoke
   */
  const onClick = () =>
    navigate(getOpenModalUrl(location, MODAL__GROUP_CREATE));

  const subtitleKey = canCreate
    ? 'SETTINGS__GROUPS__SUBTITLE'
    : 'SETTINGS__GROUPS__SUBTITLE_LIMITED_PERMISSION';

  return {
    buttons: canCreate
      ? getButtons('SETTINGS__GROUPS__CREATE_GROUP__BUTTON', onClick)
      : [],
    floatingButton: canCreate ? <ButtonAdd onClick={onClick} /> : null,
    subtitle: translate(subtitleKey),
    title: translate('SETTINGS__GROUPS'),
  };
};

/**
 * Get layout config for the Settings Labels page
 *
 * @param location react-router-dom location object
 * @param navigate react-router-dom navigate function
 * @returns        The desired config
 */
export const getConfigLabels: ConfigCallback = (location, navigate) => {
  const canCreate = getCanCreateLabel();

  /**
   * Get callback to show the group create modal
   *
   * @returns The callback to invoke
   */
  const onClick = () =>
    navigate(getOpenModalUrl(location, MODAL__LABEL_CREATE));

  const subtitleKey = canCreate
    ? 'SETTINGS__LABELS__SUBTITLE'
    : 'SETTINGS__LABELS__SUBTITLE_LIMITED_PERMISSION';

  return {
    buttons: canCreate
      ? getButtons('SETTINGS__LABELS__CREATE_LABEL__BUTTON', onClick)
      : [],
    floatingButton: canCreate ? <ButtonAdd onClick={onClick} /> : null,
    subtitle: translate(subtitleKey),
    title: translate('SETTINGS__LABELS'),
  };
};

/**
 * Get layout config for the Settings Push Notifications page
 *
 * @returns The desired config
 */
export const getConfigNotificationsPush: ConfigCallback = () => ({
  buttons: [],
  floatingButton: null,
  shouldShowHeader: false,
  subtitle: null,
  title: translate('SETTINGS__NOTIFICATIONS__PUSH'),
});

/**
 * Get layout config for the Settings Email Notifications page
 *
 * @returns The desired config
 */
export const getConfigNotificationsEmail: ConfigCallback = () => ({
  buttons: [],
  floatingButton: null,
  shouldShowHeader: false,
  subtitle: null,
  title: translate('SETTINGS__NOTIFICATIONS__EMAIL'),
});

/**
 * Get layout config for the Reservations page
 *
 * @param onClick Onclick
 * @returns       The desired config
 */
export const getConfigReservations = (onClick: () => void): LayoutConfig => ({
  buttons: [
    {
      children: translate('GENERAL__REFRESH'),
      key: 'refresh',
      onClick,
      type: 'button',
    },
  ],
  floatingButton: null,
  subtitle: null,
  title: translate('GENERAL__RESERVATIONS'),
});

/**
 * Get layout config for the Settings root page
 *
 * @returns The desired config
 */
export const getConfigRoot: ConfigCallback = () => ({
  buttons: [],
  floatingButton: null,
  subtitle: null,
  title: translate('GENERAL__SETTINGS'),
});

/**
 * Get layout config for the Settings Templates page
 *
 * @returns The desired config
 */
export const getConfigTemplates: ConfigCallback = () => ({
  buttons: [],
  floatingButton: getCanCreateTemplate() ? <OpenComposeButton /> : null,
  subtitle: translate('SETTINGS__TEMPLATES__SUBTITLE'),
  title: translate('SETTINGS__TEMPLATES'),
});

/**
 * Get layout config for the Settings Templates Recurring page
 *
 * @returns The desired config
 */
export const getConfigTemplatesRecurring: ConfigCallback = () => ({
  buttons: [],
  floatingButton: null,
  settingsBackUrl: '/templates',
  subtitle: translate('SETTINGS__TEMPLATES__RECURRING__SUBTITLE'),
  title: translate('RRULE__ACTION__CREATE'),
});

/**
 * Get layout config for the Settings Topics page
 *
 * @param location react-router-dom location object
 * @param navigate react-router-dom navigate function
 * @returns        The desired config
 */
export const getConfigTopics: ConfigCallback = (location, navigate) => {
  const canCreate = getCanCreateTopic();

  /**
   * Get callback to show the topic create modal
   *
   * @returns The callback to invoke
   */
  const onClick = () =>
    navigate(getOpenModalUrl(location, MODAL__TOPIC_CREATE));

  return {
    buttons: canCreate ? getButtons('TOPIC__CREATE', onClick) : [],
    floatingButton: canCreate ? <ButtonAdd onClick={onClick} /> : null,
    subtitle: translate('SETTINGS__TOPICS__SUBTITLE'),
    title: translate('SETTINGS__TOPICS'),
  };
};

/**
 * Get layout config for the Settings Users page
 *
 * @returns The desired config
 */
export const getConfigUsers: ConfigCallback = () => ({
  buttons: [],
  floatingButton: null,
  subtitle: translate('SETTINGS__PEOPLE__SUBTITLE'),
  title: translate('SETTINGS__PEOPLE'),
});
