/**
 * @file Get whether to mark the current message as read
 */

import { MessageFullQuery } from '../../../generated/graphql';

/**
 * Get whether to mark the current message as read
 * We do so when it's unread and has no comments
 * (in case where there are comments, we mark as read on them becoming visible)
 *
 * @param data The fetched data
 * @returns    Whether to mark the message as read
 */
const getShouldMarkAsRead = (data: MessageFullQuery | undefined) => {
  const message = data?.message ?? null;
  if (message === null) {
    return false;
  }

  return message.isSeen === false && message.numberOfComments === 0;
};

export default getShouldMarkAsRead;
