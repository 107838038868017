import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton, Link } from '@mui/material';
import React, { FC } from 'react';

import HeaderRightSideContentContainer from '../../../containers/headerRightSideContentContainer';

import { Locale } from '../../../generated/graphql';
import translate from '../../../i18n/translate';
import { Page } from '../types';
export type Props = {
  language: Locale | null;
  page: Page;
};

/**
 * Mobile header right side content component
 *
 * @param props          props passed to the component
 * @param props.language preferred language
 * @param props.page     On which page user currently is
 * @returns              The component itself
 */
const RightSideContent: FC<Props> = ({ language, page }) => {
  if (page === 'reservations') {
    return <HeaderRightSideContentContainer />;
  }

  if (page === 'other' && language !== null) {
    return (
      <IconButton
        aria-label={translate('ARIA__LINK__HELP')}
        component={Link}
        href={translate('GENERAL__HELPCENTER')}
        rel="noopener noreferrer"
        target="_blank"
      >
        <HelpOutlineIcon />
      </IconButton>
    );
  }

  return null;
};
export default RightSideContent;
