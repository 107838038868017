/**
 * @file Helper functions for topicHeaderMobile container
 */

import { Location } from 'react-router-dom';

import { Hotel, TopicWithMembersFragment } from '../../generated/graphql';
import { getTopicDetailsUrl } from '../../routes/urls/topic';
import { getCanUpdateTopic } from '../../utils/permissions/topic';

/**
 * Get the value for infoUrl prop which is responsible for rendering the edit
 * topic icon. We want to show the edit icon only if the use has permissions
 *
 * @param topic    The topic we need the details URL for
 * @param hotelId  ID of the hotel
 * @param location History location object
 * @returns        String in URL format if topic accessible otherwise undefined
 */
export const getInfoUrl = (
  topic: TopicWithMembersFragment | undefined,
  hotelId: Hotel['id'] | null,
  location: Location,
) => {
  return topic !== undefined && getCanUpdateTopic(topic)
    ? getTopicDetailsUrl(hotelId, location, topic.id)
    : undefined;
};
