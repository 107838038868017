import React, { FC } from 'react';

import { ChatBubble } from '../../../../integrations/roqChat/ChatBubble';

import Icon, { NAMES } from '../../../Common/Icon';
import { CHAT_ICON } from '../getNavLinks';

import * as Styled from './styled';

type Props = {
  iconName: string;
  isSelected: boolean;
};

/**
 * A single icon within the navbar
 *
 * @param props            Props passed to the component
 * @param props.iconName   The name of the icon to show
 * @param props.isSelected Whether the item is selected
 * @returns                The component itself
 */
const NavLinkIcon: FC<Props> = ({ iconName, isSelected }) => {
  const isChat = iconName === CHAT_ICON;
  const isHome = iconName === NAMES.BOTTOM_NAV__HOME;

  const iconColor = isSelected
    ? 'var(--color-icons-alt-active)'
    : 'var(--color-icons-inactive)';

  if (isChat) {
    return (
      <ChatBubble>
        <Icon color={iconColor} name={iconName} />
      </ChatBubble>
    );
  }

  return (
    <>
      <Icon color={iconColor} name={iconName} />
      {isHome && <Styled.BadgeIndicatorContainer />}
    </>
  );
};

export default NavLinkIcon;
