/**
 * @file Constants for url hash value and element id's
 */

export const HASH_MESSAGE__INFO__ASSIGNEE = 'assignees';
export const HASH_MESSAGE__INFO__ATTACHMENT = 'attachments';
export const HASH_MESSAGE__INFO__CHECKLIST = 'checklist';
export const HASH_MESSAGE__INFO__DUE_DATE = 'date';
export const HASH_MESSAGE__INFO__LABEL = 'label';
export const HASH_MESSAGE__INFO__STATUS = 'status';
