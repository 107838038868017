import React, { FC, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import SplashScreen from '../components/SplashScreen';
import SettingsLayout from '../components/layouts/SettingsLayout';
import useBadgeClear from '../hooks/useBadgeClear';
import mappingRoutesAuthenticated from '../routes/mapping/mappingRoutesAuthenticated';
import mappingRoutesError from '../routes/mapping/mappingRoutesError';
import mappingRoutesSettings from '../routes/mapping/mappingRoutesSettings';
import PageDevPanel from '../routes/pages/DevPanel';
import PageIndex from '../routes/pages/Index';
import PageNoMatch from '../routes/pages/NoMatch';
import { createDevPanelPath } from '../routes/paths/devPanel';
import { createReservationsPath } from '../routes/paths/reservations';
import injectMetaViewport from '../utils/webview/injectMetaViewport';

import PermissionsCheck from './PermissionsCheck';

const PageReservations = lazy(() => import('../routes/pages/Reservations'));

/**
 * Main application entry point
 *
 * @returns App component
 */
const App: FC = () => {
  injectMetaViewport();
  useBadgeClear();

  return (
    <BrowserRouter>
      <Routes>
        {/* ERROR pages */}
        {/* Need to be publicly accessible, because the user might not be logged in */}
        {mappingRoutesError.map(props => (
          <Route key={props.path} {...props} />
        ))}

        {/* Inner pages, requiring that the user is logged in */}
        <Route element={<PermissionsCheck />}>
          {/* INDEX */}
          <Route element={<PageIndex />} index={true} />

          {/* All inner routes that have no standard layout */}
          {mappingRoutesAuthenticated.map(props => (
            <Route key={props.path} {...props} />
          ))}

          {/* DEV PANEL */}
          <Route element={<PageDevPanel />} path={createDevPanelPath()} />

          {/* RESERVATIONS */}
          <Route element={<SettingsLayout hasLeftSidebar={false} />}>
            <Route
              element={
                <Suspense fallback={<SplashScreen />}>
                  <PageReservations />
                </Suspense>
              }
              path={createReservationsPath()}
            />
          </Route>

          {/* SETTINGS */}
          <Route element={<SettingsLayout />}>
            {mappingRoutesSettings.map(props => (
              <Route key={props.path} {...props} />
            ))}
          </Route>
        </Route>

        {/* General catch all 404 */}
        <Route element={<PageNoMatch />} path="*" />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
