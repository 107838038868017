import React, { FC } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';
import Chevron from '../Chevron';

import * as Styled from './styled';

export type Props = {
  isSelected: boolean;
  label: string;
  shouldReplace?: boolean;
  url: string;
};

/**
 * Navigation link component that can be reused
 *
 * @param props               Props passed to the component
 * @param props.isSelected    Whether the topic is selected
 * @param props.label         Label of the link
 * @param props.shouldReplace If link should be shouldReplaced or push in history
 * @param props.url           A callback that is used to set a new url for filtering or a raw url
 * @returns                   Left sidebar desktop link component with styles
 */
const NavigationLink: FC<Props> = ({
  isSelected,
  label,
  shouldReplace = false,
  url,
}) => {
  const isMobile = useIsMobile();
  return (
    <Styled.Link
      aria-current={isSelected ? 'page' : 'false'}
      replace={shouldReplace}
      to={url}
    >
      {label}
      {isMobile && <Chevron direction="right" size={15} />}
    </Styled.Link>
  );
};

export default NavigationLink;
