/**
 * @file Contains getters for search params
 */

import { Location } from 'react-router-dom';

import { ModeLabels } from '../../constants/metaModesLabels';
import {
  SEARCH_PARAM__ATTACHMENT_ID,
  SEARCH_PARAM__COMPOSE_MODE,
  SEARCH_PARAM__FILTER__MEMBERS,
  SEARCH_PARAM__FILTER__QUICK,
  SEARCH_PARAM__GROUP_ID,
  SEARCH_PARAM__LABEL_ID,
  SEARCH_PARAM__LABEL_MODE,
  SEARCH_PARAM__MESSAGE_ID,
  SEARCH_PARAM__MODAL_ID,
  SEARCH_PARAM__RECURRING_MODE,
  SEARCH_PARAM__REDIRECT,
  SEARCH_PARAM__TEMPLATE_ID,
  SEARCH_PARAM__TOPIC_ID,
  SEARCH_PARAM__USER_ID,
} from '../../constants/routing/searchParams';
import { Label, Template, Topic, User } from '../../generated/graphql';
import { Attachment } from '../../models/attachment';
import { GroupID } from '../../models/group';
import { ModalId } from '../../models/modal';
import { ComposeModeParam, RecurringModeParam } from '../../models/pageParams';
import { CustomSearchParams } from '../../models/searchParams';

import { getIsFeedKanbanPage } from './pages';

/**
 * Check whether the filters are active on the current page
 *
 * @param location Location object from the history
 * @returns        Whether it's true
 */
export const getIsSearchParamFilterActive = (location: Location): boolean => {
  const isKanban = getIsFeedKanbanPage();

  return isKanban
    ? location.search.includes(SEARCH_PARAM__FILTER__MEMBERS)
    : location.search.includes(SEARCH_PARAM__FILTER__QUICK);
};

/**
 * Get the provided search param value
 *
 * @param paramKey The search param key
 * @param location History Location object (if not provided, we get search from window.location)
 * @returns        The modalId search param
 */
export const getSearchParam = <T extends keyof CustomSearchParams>(
  paramKey: T,
  location?: Location,
): CustomSearchParams[T] | null => {
  const search = location?.search ?? window.location?.search;
  const params = new URLSearchParams(search);
  const value = params.get(paramKey);

  if (value === null) {
    return null;
  }

  return decodeURIComponent(value) as CustomSearchParams[T];
};

/**
 * Get attachmentId search param from the url
 *
 * @param location Location object from the history
 * @returns        Attachment id or null
 */
export const getSearchParamAttachmentId = (
  location: Location,
): Attachment['id'] | null =>
  getSearchParam(SEARCH_PARAM__ATTACHMENT_ID, location);

/**
 * Get composeMode search param from the url
 *
 * @param location Location object from the history
 * @returns        Compose mode or null
 */
export const getSearchParamComposeMode = (
  location: Location,
): ComposeModeParam | null =>
  getSearchParam(SEARCH_PARAM__COMPOSE_MODE, location);

/**
 * Get filterMembers search param from the url
 *
 * @param location Location object from the history
 * @returns        Set of ids or null
 */
export const getSearchParamFilterMembers = (
  location: Location,
): Set<string> | null => {
  const filterMembers = getSearchParam(SEARCH_PARAM__FILTER__MEMBERS, location);
  if (filterMembers === null) {
    return null;
  }

  return new Set(filterMembers.split(','));
};

/**
 * Get quickFilters search param from the url
 *
 * @param location Location object from the history
 * @returns        Quick filter id or null
 */
export const getSearchParamFilterQuick = (
  location: Location,
): string | null => {
  const quickFilter = getSearchParam(SEARCH_PARAM__FILTER__QUICK, location);

  return quickFilter;
};

/**
 * Get groupId search param from the url
 *
 * @param location Location object from the history
 * @returns        Group id or null
 */
export const getSearchParamGroupId = (location: Location): GroupID | null =>
  getSearchParam(SEARCH_PARAM__GROUP_ID, location);

/**
 * Get labelId search param from the url
 *
 * @param location Location object from the history
 * @returns        Label id or null
 */
export const getSearchParamLabelId = (location: Location): Label['id'] | null =>
  getSearchParam(SEARCH_PARAM__LABEL_ID, location);

/**
 * Get labelId search param from the url
 *
 * @param location Location object from the history
 * @returns        Label id or null
 */
export const getSearchParamLabelMode = (
  location: Location,
): ModeLabels | null => getSearchParam(SEARCH_PARAM__LABEL_MODE, location);

/**
 * Get messageId search param from the url
 *
 * @param location Location object from the history
 * @returns        Message id or null
 */
export const getSearchParamMessageId = (location: Location): GroupID | null =>
  getSearchParam(SEARCH_PARAM__MESSAGE_ID, location);

/**
 * Get modal id search param from the url
 *
 * @param location Location object from the history
 * @returns        ModalId or null
 */
export const getSearchParamModalId = (location: Location): ModalId | null =>
  getSearchParam(SEARCH_PARAM__MODAL_ID, location);

/**
 * Get redirect search param from the url
 *
 * @param location Location object from the history
 * @returns        Redirect param value or null
 */
export const getSearchParamRedirect = (location: Location) =>
  getSearchParam(SEARCH_PARAM__REDIRECT, location);

/**
 * Get templateId search param from the url
 *
 * @param location Location object from the history
 * @returns        Template id or null
 */
export const getSearchParamTemplateId = (
  location: Location,
): Template['id'] | null => getSearchParam(SEARCH_PARAM__TEMPLATE_ID, location);

/**
 * Get topicId search param from the url
 *
 * @param location Location object from the history
 * @returns        Topic id or null
 */
export const getSearchParamTopicId = (location: Location): Topic['id'] | null =>
  getSearchParam(SEARCH_PARAM__TOPIC_ID, location);

/**
 * Get UserId search param from the url
 *
 * @param location Location object from the history
 * @returns        User id or null
 */
export const getSearchParamUserId = (location: Location): User['id'] | null =>
  getSearchParam(SEARCH_PARAM__USER_ID, location);

/**
 * Get recurringMode search param from the url
 *
 * @param location Location object from the history
 * @returns        recurringMode
 */
export const getSearchParamRecurringMode = (
  location: Location,
): RecurringModeParam | null =>
  getSearchParam(SEARCH_PARAM__RECURRING_MODE, location);
