import React, { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { useLocation } from 'react-router-dom';

import { MODAL__POST_UNARCHIVE } from '../../../constants/routeModalParams';
import { useModal } from '../../../containers/modals/useModal';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import { MessageSearch } from '../../../models/message';

import { getMessageDetailsUrl } from '../../../routes/urls/message';

import { getCanUnArchiveMessage } from '../../../utils/permissions/message';

import SearchResultMessage from './SearchResultMessage';
import * as Styled from './styled';

export type Props = {
  message: MessageSearch;
  requestLoadMore: (() => void) | null;
};

/**
 * A single message within search results,
 * wrapped by link or button, depending on the state
 *
 * @param props                 Props passed to the component
 * @param props.message         One message result from the search
 * @param props.requestLoadMore Callback to request more messages to be loaded
 * @returns                     The component itself
 */
const SearchResultElement: FC<Props> = ({ message, requestLoadMore }) => {
  const { openModal } = useModal();
  const [inViewRef, inView] = useInView();
  const location = useLocation();
  const hotelId = useParamHotelId();

  const isArchived = message.isArchived;

  const url = getMessageDetailsUrl(
    hotelId,
    location,
    message.id,
    message.topic.id,
  );

  /**
   * The user has clicked on archive button,
   * so we show the confirmation modal
   */
  const openUnArchivedModal = () => {
    openModal(MODAL__POST_UNARCHIVE, { messageId: message.id });
  };

  useEffect(() => {
    /**
     * The last result is in view,
     * so we load the next page of search results
     */
    if (inView && requestLoadMore !== null) {
      requestLoadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  if (isArchived === false) {
    return (
      <div ref={inViewRef}>
        <SearchResultMessage message={message} url={url} />
      </div>
    );
  }

  if (getCanUnArchiveMessage()) {
    return (
      <>
        <Styled.ButtonWrapper
          onClick={openUnArchivedModal}
          ref={inViewRef}
          type="button"
        >
          <SearchResultMessage message={message} />
        </Styled.ButtonWrapper>
      </>
    );
  }

  return (
    <div ref={inViewRef}>
      <SearchResultMessage message={message} />
    </div>
  );
};

export default SearchResultElement;
