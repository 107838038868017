/**
 * @file Helpers for ComposeMeta component
 */

import { FC } from 'react';
import { LinkProps, Location } from 'react-router-dom';

import ComposeMetaAssignment from '../../../../containers/compose/composeMetaAssignment';
import ComposeMetaAttachments from '../../../../containers/compose/composeMetaAttachments';
import ComposeMetaChecklist from '../../../../containers/compose/composeMetaChecklist';
import ComposeMetaDueDate from '../../../../containers/compose/composeMetaDueDate';
import ComposeMetaLabels from '../../../../containers/compose/composeMetaLabels';
import ComposeMetaOffset from '../../../../containers/compose/composeMetaOffset';
import ComposeMetaTemplate from '../../../../containers/compose/composeMetaTemplate';
import ComposeMetaTitle from '../../../../containers/compose/composeMetaTitle';
import ComposeMetaTopic from '../../../../containers/compose/composeMetaTopic';
import { ComposeNodeType } from '../../../../containers/compose/props';
import { ComposeModeParam } from '../../../../models/pageParams';
import {
  getCloseComposeSectionUrl,
  getOpenComposeSectionUrl,
} from '../../../../routes/urls/searchParams/compose';

import { Mapping, Mode, PropsChildren } from './types';

/**
 * The meta inputs to show on desktop, after title
 */
const mappingDesktopBelow: Record<ComposeNodeType, FC<PropsChildren>[]> = {
  MESSAGE: [
    ComposeMetaTitle,
    ComposeMetaTemplate,
    ComposeMetaAssignment,
    ComposeMetaLabels,
    ComposeMetaDueDate,
    ComposeMetaChecklist,
  ],
  TEMPLATE: [
    ComposeMetaTitle,
    ComposeMetaTopic,
    ComposeMetaAssignment,
    ComposeMetaLabels,
    ComposeMetaOffset,
    ComposeMetaChecklist,
  ],
};

/**
 * The meta inputs to show on mobile, before description
 */
const mappingMobileAbove: Mapping = {
  MESSAGE: [ComposeMetaTemplate],
  TEMPLATE: [],
};

/**
 * The meta inputs to show on mobile, after description
 */
const mappingMobileBelow: Record<ComposeNodeType, FC<PropsChildren>[]> = {
  MESSAGE: [
    ComposeMetaAttachments,
    ComposeMetaAssignment,
    ComposeMetaLabels,
    ComposeMetaDueDate,
    ComposeMetaChecklist,
  ],
  TEMPLATE: [
    ComposeMetaTopic,
    ComposeMetaAssignment,
    ComposeMetaLabels,
    ComposeMetaOffset,
    ComposeMetaChecklist,
  ],
};

/**
 * Mapping between screen size and position and the components to show
 */
export const mapping: Record<Mode, Mapping> = {
  'DESKTOP-ABOVE': { MESSAGE: [], TEMPLATE: [] },
  'DESKTOP-BELOW': mappingDesktopBelow,
  'MOBILE-ABOVE': mappingMobileAbove,
  'MOBILE-BELOW': mappingMobileBelow,
};

/**
 * Generate Link to prop for meta popup toggle
 *
 * @param isActive Whether the meta box is currently active
 * @param mode     The compose mode to generate the prop for
 * @param location React router location object
 * @returns        The object that can be used for to prop on Link component
 */
export const getLinkToggleProps = (
  isActive: boolean,
  mode: ComposeModeParam,
  location: Location,
): LinkProps => {
  return {
    replace: true,
    to: isActive
      ? getCloseComposeSectionUrl(location)
      : getOpenComposeSectionUrl(location, mode),
  };
};
