import { Box, ButtonProps, Stack, StackProps, styled } from '@mui/material';

import breakpoints from '../../constants/breakpoints';
import { colorNeutral, colorPrimary } from '../../styles/mui/colors';

export const Section = styled(Stack)<StackProps>(({ theme }) => ({
  backgroundColor: colorNeutral[300],
  borderRadius: theme.spacing(1.5),
  flexShrink: 0,
  height: '100%',
  // This is mostly for screens that are between mobile and `breakpoints.tabletS`.
  // The sections don't look good if they take `92.5%` of space (width).
  maxWidth: '32rem',
  paddingBottom: theme.spacing(0.5),
  position: 'relative',
  scrollSnapAlign: 'center',
  // On mobile, we always want to see one section, but we also want to see if there
  // is a section on the left, or on the right. This way the user knows that scrolling
  // is available, because the next/previous section will be partially visible as well.
  width: '92.5%',
  [theme.breakpoints.up(breakpoints.tabletS)]: {
    flex: '1 1 0',
    maxWidth: 'none',
    scrollSnapAlign: 'none',
    width: 'auto',
  },
}));

export const DragOverlay = styled('div')({
  backgroundColor: 'var(--color-shadow)',
  borderRadius: 'inherit',
  inset: 0,
  position: 'absolute',
});

export const SectionHeader = styled(Stack)<StackProps & ButtonProps>(
  ({ theme }) => ({
    '&:focus-visible, &:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
    flexDirection: 'row',
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: 'var(--font-weight-heading)',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4),
  }),
);

/**
 * Without this, horizontal drag & scroll is not working even when
 * scroll snapping is not set
 */
export const DroppableContent = styled('div')({
  height: '100%',
  // We have the child element `ScrollArea` that is scrollable
  overflow: 'hidden',
});

export const ScrollArea = styled(Stack)<StackProps>(({ theme }) => ({
  flex: '0 1 auto',
  gap: theme.spacing(2),
  height: '100%',
  marginRight: 1,
  overflowY: 'auto',
  // The bottom spacing is increased to accommodate the compose form button,
  // which covers the last card.
  padding: theme.spacing(0, 2, 8, 2),
  scrollbarColor: `${colorPrimary[700]} transparent`,
}));

export const HiddenTasksWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
}));
