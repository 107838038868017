import React, { FC } from 'react';

import { useQuery } from 'urql';

import BadgeIndicator from '../components/Common/BadgeIndicator';
import { TopicsUnreadDocument } from '../generated/graphql';
import translate from '../i18n/translate';

type Props = {
  className?: string;
};

/**
 * Container that gets the data for unread messages
 *
 * @param props           Props passed to the component
 * @param props.className styled-components className attribute
 * @returns               Badge or nothing
 */
const UnreadBadgeContainer: FC<Props> = ({ className }) => {
  const [{ data }] = useQuery({ query: TopicsUnreadDocument, variables: {} });

  /**
   * If at least one element has unread messages, show the badge
   */
  const hasUnreadMessages =
    data?.topics?.some(({ unreadMessages }) => unreadMessages !== 0) ?? false;

  if (hasUnreadMessages) {
    return (
      <BadgeIndicator
        ariaLabel={translate('ARIA__UNREAD_POSTS')}
        className={className}
      />
    );
  }

  return null;
};

export default UnreadBadgeContainer;
