// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roq-chat-sidebar {
  position: relative;
}

@media (max-width: 768px) {
  .roq-chat-sidebar {
    height: 100%;
  }
}

.roq-chat-window {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.roq-chat-conversation-list-sidebar {
  padding: 0 var(--spacing-medium);
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/_sidebar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".roq-chat-sidebar {\n  position: relative;\n}\n\n@media (max-width: 768px) {\n  .roq-chat-sidebar {\n    height: 100%;\n  }\n}\n\n.roq-chat-window {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.roq-chat-conversation-list-sidebar {\n  padding: 0 var(--spacing-medium);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
