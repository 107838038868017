import React, { FC } from 'react';

import EmptyState from '../../../../components/Common/EmptyState';
import FloatingBottomNav from '../../../../components/FloatingBottomNav';
import SearchForm from '../../../../components/Forms/SearchForm';
import HeaderMobile from '../../../../components/HeaderMobile';
import AppLayout from '../../../../components/layouts/AppLayout';
import { ILLUSTRATION__SEARCH } from '../../../../constants/illustrations';
import translate from '../../../../i18n/translate';

import * as Styled from './styled';

/**
 * Search page mobile layout
 *
 * @returns AppLayout for /search page on mobile phones
 */
const SearchMobile: FC = () => {
  return (
    <AppLayout
      footer={<FloatingBottomNav />}
      header={
        <HeaderMobile backUrl={true} title={translate('GENERAL__SEARCH')} />
      }
    >
      <Styled.Wrapper>
        <SearchForm />

        <EmptyState
          illustrationName={ILLUSTRATION__SEARCH}
          subtitle={translate('SEARCH__MOBILE__SUBTITLE')}
          title={translate('SEARCH__MOBILE__TITLE')}
        />
      </Styled.Wrapper>
    </AppLayout>
  );
};

export default SearchMobile;
