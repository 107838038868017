import React, { FC } from 'react';

import { Props as ButtonProps } from '../../Common/ButtonSingle';

import HeaderButtonsSingle from '../HeaderButtonSingle';

import * as Styled from './styled';

export type Props = {
  buttons: ButtonProps[];
};

/**
 * All the buttons within a settings header
 *
 * @param props         Props passed to the component
 * @param props.buttons The buttons to show
 * @returns             The component itself
 */
const HeaderButtons: FC<Props> = ({ buttons }) => (
  <Styled.Ul>
    {buttons.map(props => (
      <li key={props.key}>
        <HeaderButtonsSingle {...props} />
      </li>
    ))}
  </Styled.Ul>
);

export default HeaderButtons;
