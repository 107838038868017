import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import useIsMobile from '../../../hooks/useIsMobile';
import SettingsHeader from '../../SettingsHeader';
import AppLayout from '../AppLayout';

import SettingsLayoutFooter from './SettingsLayoutFooter';
import SettingsLayoutHeader from './SettingsLayoutHeader';
import useContentConfig from './hooks/useContentConfig';
import useLayoutConfig from './hooks/useLayoutConfig';
import { useLeftSidebarConfig } from './hooks/useLeftSidebarConfig';
import * as Styled from './styled';
import { Props } from './types';

/**
 * The settings page general layout
 *
 * @param props                Props passed to the layout
 * @param props.hasLeftSidebar Whether the page has left sidebar at all
 * @returns                    The layout to use
 */
const SettingsLayout: FC<Props> = ({ hasLeftSidebar = true }) => {
  const isMobile = useIsMobile();
  const isPlain = useContentConfig();
  const {
    buttons,
    floatingButton,
    settingsBackUrl,
    shouldShowHeader = true,
    subtitle,
    title,
  } = useLayoutConfig();

  const leftSidebar = useLeftSidebarConfig(hasLeftSidebar);
  const hasFloatingButton = floatingButton !== null;

  const children = (
    <>
      {isMobile === false && shouldShowHeader === true && (
        <SettingsHeader
          buttons={buttons}
          settingsBackUrl={settingsBackUrl}
          subtitle={subtitle}
          title={title}
        />
      )}
      <Outlet />
    </>
  );

  return (
    <AppLayout
      footer={<SettingsLayoutFooter floatingButton={floatingButton} />}
      header={<SettingsLayoutHeader title={title} />}
      leftSidebar={leftSidebar}
    >
      {isPlain ? (
        children
      ) : (
        <Styled.Content data-has-floating-button={hasFloatingButton}>
          {children}
        </Styled.Content>
      )}
    </AppLayout>
  );
};

export default SettingsLayout;
