import { colorSchemeSet } from 'hotelboard-indexeddb';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { PREFERENCE_WISEPOPS_ENABLE_FOR } from '../../constants/preferences';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useLocale from '../../hooks/useLocale';
import usePrefersDarkMode from '../../hooks/usePrefersDarkMode';
import useServiceWorker from '../../hooks/useServiceWorker';
import { useTrackPageView } from '../../services/matomo/useTrackPageView';
import { reportApiErrors } from '../../utils/error';
import { initWisePops } from '../../utils/services/wisePops';

import { postWebViewCookiesFlushEvent } from '../../utils/webview/events';
import AppAuthenticatedHotel from '../appAuthenticatedHotel';

/**
 * Main application entry point for authenticated users
 *
 * @returns AppAuthenticated component
 */
const AppAuthenticated: FC = () => {
  const language = useLocale();
  const prefersDarkMode = usePrefersDarkMode();
  const hotelId = useParamHotelId();
  useServiceWorker();
  useTrackPageView();

  postWebViewCookiesFlushEvent();

  if (language !== null && PREFERENCE_WISEPOPS_ENABLE_FOR.includes(language)) {
    initWisePops();
  }

  colorSchemeSet(prefersDarkMode ? 'dark' : 'light').catch(reportApiErrors);

  // The index page is the only authenticated page that doesn't have a hotel ID in the URL
  if (hotelId === null) {
    // No need to wrap the index page with a RoqChatProvider
    return <Outlet />;
  }

  return <AppAuthenticatedHotel />;
};

export default AppAuthenticated;
