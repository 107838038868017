/**
 * @file All available hotelboard permissions
 */

export const CHECKLIST__ADMIN = 'hotelboard:checklist-admin';
export const CHECKLIST__CHECK = 'hotelboard:checklist-check';
export const CHECKLIST__CREATE = 'hotelboard:checklist-create';
export const CHECKLIST__DELETE = 'hotelboard:checklist-delete';
export const CHECKLIST__REORDER = 'hotelboard:checklist-reorder';
export const CHECKLIST__UPDATE = 'hotelboard:checklist-update';

export const COMMENT__ADMIN = 'hotelboard:comment-admin';
export const COMMENT__CREATE = 'hotelboard:comment-create';
export const COMMENT__DELETE = 'hotelboard:comment-delete';
export const COMMENT__UPDATE = 'hotelboard:comment-update';
export const COMMENT__VIEW = 'hotelboard:comment-view';

export const DASHBOARD__VIEW = 'hotelboard:statistics-view';

export const DEV__PANEL__VIEW = 'hotelboard:debug';

export const GROUP__ADMIN = 'hotelboard:user-group-admin';
export const GROUP__CREATE = 'hotelboard:user-group-create';
export const GROUP__DELETE = 'hotelboard:user-group-delete';
export const GROUP__UPDATE = 'hotelboard:user-group-update';
export const GROUP__VIEW = 'hotelboard:user-group-view';

export const HOTEL__VIEW = 'hotelboard:hotel-view';

export const LABEL__CREATE = 'hotelboard:label-create';
export const LABEL__DELETE = 'hotelboard:label-delete';
export const LABEL__UPDATE = 'hotelboard:label-update';
export const LABEL__VIEW = 'hotelboard:label-view';

export const MESSAGE__ADMIN = 'hotelboard:message-admin';
export const MESSAGE__CREATE = 'hotelboard:message-create';
export const MESSAGE__DELETE = 'hotelboard:message-delete';
export const MESSAGE__UPDATE = 'hotelboard:message-update';
export const MESSAGE__VIEW = 'hotelboard:message-view';

export const RESERVATIONS__VIEW = 'hotelboard:reservation-view';

export const TEMPLATE__ADMIN = 'hotelboard:template-admin';
export const TEMPLATE__CREATE = 'hotelboard:template-create';
export const TEMPLATE__DELETE = 'hotelboard:template-delete';
export const TEMPLATE__UPDATE = 'hotelboard:template-update';
export const TEMPLATE__VIEW = 'hotelboard:template-view';

export const TOPIC__ADMIN = 'hotelboard:topic-admin';
export const TOPIC__CREATE = 'hotelboard:topic-create';
export const TOPIC__DELETE = 'hotelboard:topic-delete';
export const TOPIC__UPDATE = 'hotelboard:topic-update';
export const TOPIC__VIEW = 'hotelboard:topic-view';

export const USER__ADMIN = 'hotelboard:user-admin';
export const USER__UPDATE = 'hotelboard:user-update';
export const USER__VIEW = 'hotelboard:user-view';
