import React, { ComponentProps, FC, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { SEARCH_PARAM__COMPOSE_MODE } from '../../constants/routing/searchParams';
import useClickOutside from '../../hooks/useClickOutside';

import { removeParamsKeysFromUrl } from '../../routes/helpers/router';
import MetaContainerInline from '../MetaContainerInline';

import MetaInput from './MetaInput';
import * as Styled from './styled';
import usePopupPlacement from './usePopupPlacement';

export type Props = ComponentProps<typeof MetaContainerInline> &
  Omit<ComponentProps<typeof MetaInput>, 'wrapperRef'> & {
    align?: 'center' | 'right';
    className?: string;
    onClickOutside?: () => void;
    popupPlacement?: 'up' | 'down' | 'auto';
    shouldResize?: boolean;
  };

/**
 * Meta info popup, used for labels, assignees, due date, checklist...
 *
 * @param props                    Props passed to the component
 * @param props.align              How to align the popup, in relation to the input field
 * @param props.className          styled-components generated class name, needed for styling
 * @param props.hasMobilePadding   Should the wrapper padding be removed on mobile
 * @param props.headerAction       Callback to be invoked on action (eg. add new, remove, etc.)
 * @param props.idForm             id attribute for the form element related to submit button
 * @param props.idHeading          id attribute for the component heading
 * @param props.idSubheading       id attribute for the component subheading
 * @param props.inputCustom        The component to use for input instead of the plain one
 * @param props.isSaveDisabled     Is save button disabled
 * @param props.labelAction        The text to show for action label
 * @param props.labelActionColor   Color of action label text
 * @param props.labelClose         The label for the close (x) button
 * @param props.labelConfirm       The text to show for confirm label
 * @param props.linkToggleProps    Props for the toggle link
 * @param props.onClose            Callback to be invoked on cancel
 * @param props.popupPlacement     Placement of the popup
 * @param props.shouldResize       Should we limit the popup height or not
 * @param props.shouldDisablePopup Whether to disable popup
 * @param props.shouldShowPopup    Whether to open the details
 * @param props.textHeading        The text to show for heading
 * @param props.textPlaceholder    Placeholder text for the field
 * @param props.textSubheading     The text to show for subheading
 * @param props.textValue          Textual representation of the field's value
 * @returns                        The component itself
 */
const MetaContainerPopup: FC<Props> = props => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Closes the popup by removing composeMode search param
   */
  const handleClose = () => {
    const url = removeParamsKeysFromUrl(location, SEARCH_PARAM__COMPOSE_MODE);
    navigate(url, { replace: true });
  };

  const {
    align = 'center',
    className,
    inputCustom,
    linkProps,
    onClickOutside = props.onClose ?? handleClose,
    onClose = handleClose,
    placeholder,
    popupPlacement = 'up',
    shouldResize = true,
    shouldDisablePopup,
    shouldShowPopup,
    value,
  } = props;

  const { isUpwards, popupRef } = usePopupPlacement({
    isOpen: shouldShowPopup,
    placement: popupPlacement,
    wrapperRef: wrapperRef,
  });

  useClickOutside({
    onClick: onClickOutside,
    ref: wrapperRef,
    stopExec: !shouldShowPopup,
  });

  return (
    <Styled.Wrapper aria-haspopup="true" className={className} ref={wrapperRef}>
      <MetaInput
        inputCustom={inputCustom}
        linkProps={linkProps}
        placeholder={placeholder}
        shouldDisablePopup={shouldDisablePopup}
        shouldShowPopup={shouldShowPopup}
        value={value}
      />

      {shouldShowPopup && (
        <Styled.Popover
          {...props}
          data-align={align}
          data-auto-height={shouldResize}
          data-upwards={isUpwards}
          isPopup={true}
          onClose={onClose}
          popupRef={popupRef}
        />
      )}
    </Styled.Wrapper>
  );
};

export default MetaContainerPopup;
