import React, { ComponentProps, FC } from 'react';

import ESSearchResultsLayout from '../../../../components/layouts/ESSearchResultsLayout';
import SearchResultsContainer from '../../../../containers/searchResults';
import useEsTextQuery from '../../../../hooks/elasticSearch/useEsTextQuery';
import translate from '../../../../i18n/translate';
import { useTrackSiteSearch } from '../../../../services/matomo/useTrackSiteSearch';

type LayoutConfig = ComponentProps<typeof ESSearchResultsLayout>['config'];

/**
 * Text search results of a query. Separated into it's own component
 * because it is making a ES query, so not to pollute other components.
 *
 * @returns Elastic Search Layout with text search results
 */
const PageTextSearchResults: FC = () => {
  const esQuery = useEsTextQuery();

  const shouldSkipTracking =
    esQuery.isLoading === true ||
    (esQuery.error !== undefined && esQuery.error !== null);

  useTrackSiteSearch({
    count: esQuery.total,
    searchTerm: esQuery.searchTerm,
    shouldSkipTracking,
  });

  const layoutConfig: LayoutConfig = {
    backUrl: true,
    isLoadingTitle: false,
    title: translate('GENERAL__SEARCH'),
  };

  return (
    <ESSearchResultsLayout config={layoutConfig}>
      <SearchResultsContainer esQuery={esQuery} />
    </ESSearchResultsLayout>
  );
};

export default PageTextSearchResults;
