/**
 * @file Helper functions for generating links for mobile navigation
 */

import translate from '../../i18n/translate';
import { NAMES } from '../Common/Icon';

/**
 * @todo Find a better way to distinct types when 'internal' link doesn't have url (url is null)
 * Types of data navigation link can be
 */
type NavigationLink =
  | {
      isDisabled: boolean;
      label: string;
      url?: never;
      iconName: string;
      type: 'button';
      onClick: () => void;
    }
  | {
      isDisabled: boolean;
      label: string;
      url: string;
      iconName: string;
      type: 'external' | 'internal';
      onClick?: never;
    };

type Params = {
  devPanelUrl: string | null;
  onLogoutClick: () => void;
  profileUrl: string;
  reservationsUrl?: string;
  selectHotelUrl: string;
  settingsUrl: string;
};

/**
 * Generates a list of navigation lists with data
 *
 * @param urls                 The urls to generate the links with
 * @param urls.devPanelUrl     The link to the dev panel (won't render it if null)
 * @param urls.onLogoutClick   Click handler for logout function
 * @param urls.profileUrl      External url for the profile
 * @param urls.reservationsUrl Internal url for the reservations
 * @param urls.selectHotelUrl  Internal url for the hotel select
 * @param urls.settingsUrl     Internal url for the settings
 * @returns                    The links to show
 */
export const generateLinks = ({
  devPanelUrl,
  onLogoutClick,
  profileUrl,
  reservationsUrl,
  selectHotelUrl,
  settingsUrl,
}: Params): NavigationLink[] => {
  const links: NavigationLink[] = [
    {
      iconName: NAMES.GENERAL__AVATAR,
      isDisabled: false,
      label: translate('GENERAL__PROFILE'),
      type: 'external',
      url: profileUrl,
    },
    {
      iconName: NAMES.HEADER__COG,
      isDisabled: false,
      label: translate('GENERAL__SETTINGS'),
      type: 'internal',
      url: settingsUrl,
    },
    {
      iconName: NAMES.GENERAL__CHANGE_OBJECT,
      isDisabled: selectHotelUrl === '',
      label: translate('GENERAL__CHANGE_OBJECT'),
      type: 'internal',
      url: selectHotelUrl,
    },

    {
      iconName: NAMES.GENERAL__LOGOUT,
      isDisabled: false,
      label: translate('GENERAL__LOGOUT'),
      onClick: onLogoutClick,
      type: 'button',
    },
  ];

  if (reservationsUrl) {
    links.push({
      iconName: NAMES.GENERAL__RESERVATIONS,
      isDisabled: false,
      label: translate('GENERAL__RESERVATIONS'),
      type: 'internal',
      url: reservationsUrl,
    });
  }

  if (devPanelUrl !== null) {
    links.push({
      iconName: NAMES.HEADER__COG,
      isDisabled: false,
      label: translate('DEBUG__DEV_PANEL__LINK'),
      type: 'internal',
      url: devPanelUrl,
    });
  }

  return links;
};
