import React, { FC } from 'react';

import TopicHeaderContainer from '../../../../../containers/topicHeader';
import useParamTopicId from '../../../../../hooks/router/params/useParamTopicId';

import * as Styled from './styled';

/**
 * Component that shows topic subheader with topic title and description
 *
 * @returns Topic subheader component
 */
const FeedSubHeader: FC = () => {
  const topicId = useParamTopicId();

  if (topicId === null) {
    return null;
  }

  return (
    <Styled.Wrapper>
      <TopicHeaderContainer />
    </Styled.Wrapper>
  );
};

export default FeedSubHeader;
