import { styled } from 'styled-components';

import devices from '../../styles/devices';

const breakpoint = devices.tabletS;

export const Ul = styled.ul`
  @media ${breakpoint} {
    border-top: none;
    padding: var(--spacing-medium);
  }
`;

export const Li = styled.li`
  border-bottom: 1px solid var(--color-border-light);
  overflow: hidden;
  word-break: break-word;

  &:first-child {
    border-top: 1px solid var(--color-border-light);
  }

  @media ${breakpoint} {
    border-bottom: none;
  }
`;

export const ExternalLink = styled.a`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-medium);

  @media ${breakpoint} {
    display: flex;
    font-weight: var(--font-light);
    padding: var(--spacing-small);

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-hover-light);
      }
    }
  }
`;
