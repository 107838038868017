import React, { ComponentProps, FC, ReactNode } from 'react';

import HeaderContainer from '../../../containers/header';
import LeftSidebarFeedContainer from '../../../containers/leftSidebarFeed';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';

import useIsLeftSidebarOpen from '../../../hooks/router/search/useIsLeftSidebarOpen';

import useIsMobile from '../../../hooks/useIsMobile';
import useIsTablet from '../../../hooks/useIsTablet';
import FeedSubHeader from '../../../routes/pages/Feed/Feed/FeedSubHeader';

import FloatingBottomNav from '../../FloatingBottomNav';
import HeaderMobile from '../../HeaderMobile';
import AppLayout from '../AppLayout';

import ESSubHeader from './ESSubHeader';

export type Props = {
  children: ComponentProps<typeof AppLayout>['children'];
  config: ComponentProps<typeof HeaderMobile>;
};

/**
 * Determine which sub header to render
 *
 * @param topicId  Current active topic id if there is any
 * @param isMobile Flag that determines if we are on mobile view or not
 * @param title    Sub header title
 * @returns        Sub header component or null
 */
const subHeader = (
  topicId: string | null,
  isMobile: boolean,
  title: ReactNode,
) => {
  if (topicId) {
    return <FeedSubHeader />;
  }

  if (!isMobile) {
    return <ESSubHeader title={title} />;
  }

  return null;
};

/**
 * Shared layout for elastic search results
 *
 * @param props          Props passed to the component
 * @param props.children React children
 * @param props.config   Layout page configuration
 * @returns              Search page layout
 */
const ESSearchResultsLayout: FC<Props> = ({ children, config }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSidebarOpen = useIsLeftSidebarOpen();
  const topicId = useParamTopicId();

  return (
    <AppLayout
      footer={isMobile ? <FloatingBottomNav /> : null}
      header={isMobile ? <HeaderMobile {...config} /> : <HeaderContainer />}
      leftSidebar={{
        clickOutsideOptions: {
          stopExec: !isTablet || !isSidebarOpen,
        },
        component: (
          <LeftSidebarFeedContainer shouldShowDesktop={isSidebarOpen} />
        ),
        shouldShowDesktop: isSidebarOpen,
        shouldShowMobile: false,
      }}
      subHeader={subHeader(topicId, isMobile, config.title)}
    >
      {children}
    </AppLayout>
  );
};

export default ESSearchResultsLayout;
