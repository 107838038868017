import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useParamMessageId from '../../hooks/router/params/useParamMessageId';
import useTargetLanguage from '../../hooks/useTargetLanguage';
import translate from '../../i18n/translate';
import {
  getIsFeedMessageDetailsPage,
  getIsReservationsPage,
} from '../../routes/helpers/pages';

import { getLanguageCode } from '../../utils/user/language';

import ButtonBack from '../MUI/ButtonBack';

import HeaderMobileTitle from './HeaderMobileTitle';
import RightSideContent from './RightSideContent';
import { getShouldShowTranslate, goBack } from './helpers';

import * as Styled from './styled';
import { Page } from './types';

export type Props = {
  backUrl?: string | true;
  infoUrl?: string;
  isLoadingTitle?: boolean;
  isSticky?: boolean;
  title: ReactNode;
};

/**
 * Mobile header component for feed and details view
 *
 * @param props                props passed to the component
 * @param props.backUrl        Route back button should redirect to / If true, use native back
 * @param props.infoUrl        Route info button should redirect to
 * @param props.isLoadingTitle Whether the title is loading
 * @param props.isSticky       If header should be sticky to top
 * @param props.title          title that will be shown in the header
 * @returns                    The component itself
 */
const HeaderMobile: FC<Props> = ({
  backUrl = null,
  isLoadingTitle = false,
  isSticky = false,
  infoUrl = null,
  title,
}) => {
  const navigate = useNavigate();
  const targetLanguage = useTargetLanguage();
  const messageId = useParamMessageId();
  const isMessageDetailsPage = getIsFeedMessageDetailsPage(messageId);
  const isReservations = getIsReservationsPage();
  const language = getLanguageCode();
  const shouldShowTranslate = getShouldShowTranslate(messageId, targetLanguage);

  /**
   * Go to previous route from history or backUrl
   */
  const handleOnClick = () => {
    goBack(backUrl, navigate);
  };

  /**
   * Check on which page currently user is
   *
   * @returns 'reservations' | 'message-details' | 'other'
   */
  const pageCheck = (): Page => {
    if (isReservations) {
      return 'reservations';
    }
    if (isMessageDetailsPage) {
      return 'message-details';
    }
    return 'other';
  };

  return (
    <Styled.Header data-sticky={isSticky}>
      <Styled.LeftElementContainer>
        {backUrl !== null && (
          <ButtonBack
            label={translate('GENERAL__BACK')}
            onClick={handleOnClick}
          />
        )}
      </Styled.LeftElementContainer>

      <HeaderMobileTitle isLoading={isLoadingTitle} title={title} />

      <Styled.RightElementContainer>
        {shouldShowTranslate && messageId !== null && (
          <Styled.ButtonTranslateContainer
            container="details"
            messageId={messageId}
          />
        )}
        <RightSideContent language={language} page={pageCheck()} />
        {infoUrl !== null && (
          <IconButton
            aria-label={translate('GENERAL__DETAILS')}
            component={RouterLink}
            to={infoUrl}
          >
            <EditOutlinedIcon />
          </IconButton>
        )}
      </Styled.RightElementContainer>
    </Styled.Header>
  );
};

export default HeaderMobile;
