/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { ROUTE__SEARCH } from '../../constants/routing/routes';
import { Hotel } from '../../generated/graphql';
import { reportError } from '../../services/reporting';
import { createErrorMissingHotelPath } from '../paths/error';

/**
 * Get the url for the /search route
 *
 * @param hotelId ID of the hotel
 * @returns       A string in a url format
 */
export const getSearchUrl = (hotelId: Hotel['id'] | null): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getHomeUrl is null');
    return createErrorMissingHotelPath();
  }

  return `/${hotelId}/${ROUTE__SEARCH}`;
};

/**
 * Get url for showing the search results
 *
 * @param hotelId    ID of the hotel
 * @param searchTerm Search term
 * @param page       The page to generate the URL for
 * @param query      The query string, beginning with a ?.
 * @returns          A string in a url format
 */
export const getSearchTextUrl = (
  hotelId: Hotel['id'] | null,
  searchTerm: string,
  page: number,
  query: string,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getHomeUrl is null');
    return createErrorMissingHotelPath();
  }

  const encodedSearchTerm = encodeURIComponent(searchTerm);

  return `/${hotelId}/${ROUTE__SEARCH}/${encodedSearchTerm}/${page}${query}`;
};
