import { styled } from 'styled-components';

import devices from '../../styles/devices';

import CommonIcon from '../Common/Icon';
import CommonInput from '../Common/Input';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Icon = styled(CommonIcon)`
  align-items: center;
  display: none;
  left: var(--spacing-small);
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);

  @media ${devices.tabletS} {
    display: block;
  }
`;

export const Input = styled(CommonInput)`
  padding: var(--spacing-small);
  width: inherit;

  @media ${devices.tabletS} {
    border-color: var(--color-button-secondary);
    border-radius: var(--border-radius-small);
    padding: var(--spacing-tiny);
    padding-left: var(--spacing-large);

    &::-webkit-search-cancel-button {
      cursor: pointer;
    }

    &::-webkit-search-decoration {
      display: none;
    }
  }
`;
