import { styled } from 'styled-components';

import breakpointsVertical from '../../../constants/breakpointsVertical';
import CommonIllustration from '../Illustration';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Illustration = styled(CommonIllustration)`
  /*
    Limit the height of the illustration on mobiles
    For feed, the create post button goes over text otherwise
   */
  @media (max-height: ${breakpointsVertical.mobileM}px) {
    max-height: 30vh;
    object-fit: contain;
  }

  /* There's no space for the illustration for tiny screens */
  @media (max-height: ${breakpointsVertical.mobileS}px) {
    display: none;
  }
`;

export const Title = styled.h2`
  font-size: var(--font-size-baseline);
  font-weight: var(--font-bold);
  margin-bottom: var(--spacing-tiny);
  margin-top: var(--spacing-medium);
`;

export const ActionButton = styled.a`
  color: var(--color-link);
  font-weight: var(--font-semibold);
  margin-top: var(--spacing-small);

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
