import { styled } from 'styled-components';

export const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const Label = styled.span`
  font-weight: var(--font-light);
  margin-left: var(--spacing-small);
`;
