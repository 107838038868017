/**
 * @file Reducer for the useMessagesReadQueue hook
 */

import { MessagesReadQueueAction, MessagesReadQueueState } from './types';

/**
 * Messages read queue reducer
 * (its state only contains the ids that should be marked as read,
 * and not those that are pending)
 *
 * @param state  Current reducer state
 * @param action THe action that took place
 * @returns      New State
 */
const reducer = (
  state: MessagesReadQueueState,
  action: MessagesReadQueueAction,
): MessagesReadQueueState => {
  switch (action.type) {
    case 'ADD_TO_QUEUE':
      return new Set([...state, action.payload.messageId]);

    case 'RESET_QUEUE':
      return new Set();
  }
};

export default reducer;
