import React, { FC } from 'react';

import { Message } from '../../../../generated/graphql';
import translate from '../../../../i18n/translate';

import { MessageReplyIcon } from './MessageReplyIcon';

import * as Styled from './styled';

export type Props = {
  isExpanded: boolean;
  isUnread: boolean;
  numberOfComments: Message['numberOfComments'];
  toggleRepliesUrl: string;
};

/**
 * The message reply with the author icons and number of replies
 *
 * @param props                  Props passed to the component
 * @param props.isExpanded       Are comments expanded
 * @param props.isUnread         Whether the message is unread
 * @param props.numberOfComments Number of comments to a message
 * @param props.toggleRepliesUrl Url that toggles replies shown or not
 * @returns                      The component itself
 */
const MessageReply: FC<Props> = ({
  isExpanded,
  isUnread,
  numberOfComments = 0,
  toggleRepliesUrl,
}) => {
  /**
   * Gets text needed to be rendered depending on the
   * number of comments
   *
   * @returns The text to render
   */
  const getText = () => {
    if (numberOfComments === 0) {
      return translate('MESSAGE__REPLIES_PLACEHOLDER');
    }

    if (numberOfComments === 1) {
      return `1 ${translate('MESSAGE__REPLY')}`;
    }

    return `${numberOfComments} ${translate('MESSAGE__REPLIES')}`;
  };

  return (
    <Styled.ReplyContainer>
      <Styled.CommentsLink data-unread={isUnread} to={toggleRepliesUrl}>
        <MessageReplyIcon isExpanded={isExpanded} isUnread={isUnread} />
        <Styled.TextWrapper>{getText()}</Styled.TextWrapper>
      </Styled.CommentsLink>
    </Styled.ReplyContainer>
  );
};

export default MessageReply;
