import React from 'react';

import { NAMES } from '../../components/Common/LinkIconWithText';

import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import translate from '../../i18n/translate';

import { getIsSettingsPage } from '../../routes/helpers/pages';
import { getHomeUrl } from '../../routes/urls/home';

import * as Styled from './styled';

const iconWidth = 8;

/**
 * Hotel info back link. The back link is rendered only in settings page
 *
 * @returns The component itself
 */
const HotelInfoLinkBack = () => {
  const hotelId = useParamHotelId();

  return (
    <>
      {getIsSettingsPage() && (
        <Styled.LinkIconWithText
          iconName={NAMES.GENERAL__CHEVRON}
          iconWidth={iconWidth}
          label={translate('GENERAL__HOME')}
          text={translate('GENERAL__HOME')}
          url={getHomeUrl(hotelId)}
        />
      )}
    </>
  );
};

export default HotelInfoLinkBack;
