import React, { FC } from 'react';

import HeaderContainer from '../../../../../containers/header';
import TopicHeaderMobileContainer from '../../../../../containers/topicHeaderMobile';
import useIsMobile from '../../../../../hooks/useIsMobile';

/**
 * Component that shows feed header
 *
 * @returns Topic subheader component
 */
const FeedHeader: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <TopicHeaderMobileContainer />;
  }

  return <HeaderContainer />;
};

export default FeedHeader;
