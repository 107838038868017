import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { User } from '../../generated/graphql';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useCanAccessReservations from '../../hooks/useCanAccessReservations';
import translate from '../../i18n/translate';
import {
  getIsReservationsPage,
  getIsSettingsPage,
} from '../../routes/helpers/pages';

import { getDashboardUrl } from '../../routes/urls/dashboard';
import { getReservationsUrl } from '../../routes/urls/reservations';
import { getSettingsUrl } from '../../routes/urls/settings';
import { NAMES } from '../Common/Icon';
import SearchForm from '../Forms/SearchForm';

import ProfileMenu from './ProfileMenu';
import * as Styled from './styled';

type Props = {
  userEmail: User['email'];
};

const headerLogoHeight = 30;
const headerLogoWidth = 130;
const ICON_SIZE = 40;

/**
 * Header component for the desktop view.
 *
 * @param props           Props passed to the component
 * @param props.userEmail Email of the logged in user
 * @returns               The component itself
 */
const Header: FC<Props> = ({ userEmail }) => {
  const shouldShowReservations = useCanAccessReservations();
  const hotelId = useParamHotelId();

  return (
    <Styled.Wrapper>
      <Styled.Column>
        <Link
          aria-label={translate('DASHBOARD__TITLE')}
          to={getDashboardUrl(hotelId)}
        >
          <Styled.Logo
            color="var(--color-background)"
            height={headerLogoHeight}
            name={NAMES.GENERAL__LOGO__SPLASH}
            width={headerLogoWidth}
          />
        </Link>
      </Styled.Column>
      <SearchForm />

      <Styled.Column>
        <>
          {shouldShowReservations && (
            <Styled.LinkReservations
              ariaCurrent={getIsReservationsPage() ? 'page' : 'false'}
              iconHeight={ICON_SIZE}
              iconName={NAMES.GENERAL__RESERVATIONS}
              iconWidth={ICON_SIZE}
              label={translate('GENERAL__RESERVATIONS')}
              url={getReservationsUrl(hotelId)}
            />
          )}

          <Styled.LinkSettings
            ariaCurrent={getIsSettingsPage() ? 'page' : 'false'}
            iconHeight={ICON_SIZE}
            iconName={NAMES.HEADER__COG}
            iconWidth={ICON_SIZE}
            label={translate('GENERAL__SETTINGS')}
            url={getSettingsUrl(hotelId)}
          />

          <Styled.LinkHelp
            iconHeight={ICON_SIZE}
            iconName={NAMES.GENERAL__QUESTION_MARK}
            iconWidth={ICON_SIZE}
            isExternal={true}
            label={translate('ARIA__LINK__HELP')}
            url={translate('GENERAL__HELPCENTER')}
          />
        </>

        <ProfileMenu hotelId={hotelId} userEmail={userEmail} />
      </Styled.Column>
    </Styled.Wrapper>
  );
};

export default Header;
