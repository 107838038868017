import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

import HeaderMobile from '../../components/HeaderMobile';
import { TopicWithMembersDocument } from '../../generated/graphql';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useParamTopicId from '../../hooks/router/params/useParamTopicId';
import translate from '../../i18n/translate';
import AppState from '../../models/state';
import { getHomeUrl } from '../../routes/urls/home';
import { reportError } from '../../services/reporting';

import { getInfoUrl } from './helpers';

type StateProps = {
  networkTopicBasic: boolean;
};

type Props = StateProps;

/**
 * The container for the topic header on the home page
 * Fetches the currently selected topic
 *
 * @param props                   Props passed to the component
 * @param props.networkTopicBasic Whether the debug option for fetching this is enabled
 * @returns                       The component itself
 */
const TopicHeaderMobileContainer: FC<Props> = ({ networkTopicBasic }) => {
  const topicId = useParamTopicId();
  const hotelId = useParamHotelId();
  const location = useLocation();

  const [{ data, error, fetching }] = useQuery({
    query: TopicWithMembersDocument,
    variables: { id: topicId ?? '' },
  });

  const isLoadingTitle = fetching || networkTopicBasic;

  if (error) {
    reportError(error);
    return <div>{translate('PLACEHOLDER__ERROR')}</div>;
  }

  const topic = data?.topic;
  const infoUrl = getInfoUrl(topic, hotelId, location);

  const title = topic?.title ?? translate('GENERAL__TOPICS');

  return (
    <HeaderMobile
      backUrl={getHomeUrl(hotelId)}
      infoUrl={infoUrl}
      isLoadingTitle={isLoadingTitle}
      title={title}
    />
  );
};

/**
 * Redux map state to props function
 *
 * @param state Entire redux app state
 * @returns     Props for a react component derived from redux state
 */
const mapStateToProps = (state: AppState): StateProps => ({
  networkTopicBasic: state.debug.networkTopicBasic,
});

export default connect(mapStateToProps)(TopicHeaderMobileContainer);
