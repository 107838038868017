/**
 * @file Hook for managing ES search query and its results
 */

import { BoolQuery, Sort } from 'elastic-ts';
import { useState } from 'react';
import { useQuery } from 'urql';

import { PREFERENCE__SEARCH_RESULTS__LIMIT } from '../../../constants/pagination';
import {
  MessageSearchDocument,
  SearchQueryQueryVariables,
} from '../../../generated/graphql';
import { extractError } from '../helpers';
import { UseESQueryReturn } from '../types';

import { getIsMessageSearchResultEmpty } from './helpers';
import useGetSearchQuery from './useGetSearchQuery';

type QueryObject = {
  from: number;
  query: BoolQuery | null;
  size: number;
  sort: Sort | null;
};

/**
 * Hook for managing ES search query and its results
 *
 * @param variables Variables to pass to the backend
 * @returns         Data needed from making an ES query.
 */
const useElasticSearchQuery = (
  variables: SearchQueryQueryVariables,
): UseESQueryReturn => {
  const {
    error: searchQueryError,
    fetching: searchQueryFetching,
    query,
    sort,
    title,
  } = useGetSearchQuery(variables);
  const [from, setFrom] = useState(0);

  const isValidQuery = query !== null || searchQueryFetching === true;

  /**
   * Request to load more results of the same search
   */
  const loadMore = () => {
    setFrom(from + PREFERENCE__SEARCH_RESULTS__LIMIT);
  };

  /**
   * Construct a query object form the search query and pagination params
   */
  const queryObject: QueryObject = {
    from,
    query,
    size: PREFERENCE__SEARCH_RESULTS__LIMIT,
    sort,
  };

  /**
   * Construct a query string from the query object since elastic search
   * accepts searches in string form
   */
  const queryString = JSON.stringify(queryObject);

  const [{ data, error: messagesError, fetching: messagesFetching }] = useQuery(
    {
      pause: query === null,
      query: MessageSearchDocument,
      requestPolicy: 'cache-and-network',
      variables: { query: queryString },
    },
  );

  const nodes = data?.messageSearch?.nodes;

  // On production, it can happen that data.messageSearch exists and is null
  // After useGetSearchQuery has finished, but before useQuery has started
  // TypeScript doesn't complain about this and is fine on dev, but not on prod
  const messages = nodes ?? [];
  const nrFetched = nodes?.length;
  const nrTotal = data?.messageSearch?.searchInfo.total;

  const hasMore = nrFetched !== nrTotal;
  const isLoading = messagesFetching || searchQueryFetching;

  const isEmpty = getIsMessageSearchResultEmpty(data, isLoading);

  return {
    error: extractError(
      isValidQuery,
      isLoading,
      messagesError,
      searchQueryError,
    ),
    isEmpty,
    isLoading,
    loadMore: hasMore ? loadMore : null,
    messages,
    searchTerm: null,
    title,
    total: 0,
  };
};

export default useElasticSearchQuery;
