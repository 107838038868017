import React, { ComponentProps, FC } from 'react';
import { Navigate } from 'react-router-dom';

import ESSearchResultsLayout from '../../../components/layouts/ESSearchResultsLayout';
import SearchResultsContainer from '../../../containers/searchResults';
import useElasticSearchQuery from '../../../hooks/elasticSearch/useEsSmartViewQuery/useElasticSearchQuery';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamSmartView from '../../../hooks/router/params/useParamSmartView';
import useCurrentPath from '../../../hooks/useCurrentPath';

import { createSmartViewsSmartViewPath } from '../../paths/smartViews';

import { getHomeUrl } from '../../urls/home';

type LayoutConfig = ComponentProps<typeof ESSearchResultsLayout>['config'];

/**
 * SmartViews page
 *
 * @returns The page itself
 */
const PageSmartViews: FC = () => {
  const currentPath = useCurrentPath();
  const hotelId = useParamHotelId();
  const smartViewId = useParamSmartView();
  const esQuery = useElasticSearchQuery({ id: smartViewId ?? '' });

  const layoutConfig: LayoutConfig = {
    backUrl: getHomeUrl(hotelId),
    isLoadingTitle: esQuery.isLoading,
    title: esQuery.title,
  };

  /**
   * Check if on individual smart view (/smart-views/:smartView)
   * (/smart-views path does not exist atm in the app)
   */
  if (currentPath === createSmartViewsSmartViewPath()) {
    return (
      <ESSearchResultsLayout config={layoutConfig}>
        <SearchResultsContainer esQuery={esQuery} />
      </ESSearchResultsLayout>
    );
  }

  /**
   * Maybe unneeded check but just in case
   */
  return <Navigate to={getHomeUrl(hotelId)} />;
};

export default PageSmartViews;
