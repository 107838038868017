import React, { FC } from 'react';
import { useQuery } from 'urql';

import Header from '../components/Header';
import { MeDocument } from '../generated/graphql';
import translate from '../i18n/translate';
import { reportError } from '../services/reporting';

/**
 * The container for the header
 * Connects to the store, checks if the sidebar should be visible and passes it down
 *
 * @returns The component itself
 */
const HeaderContainer: FC = () => {
  const [{ data, error, fetching }] = useQuery({
    query: MeDocument,
    variables: {},
  });

  if (error) {
    reportError(error);
    return <div>{translate('PLACEHOLDER__ERROR')}</div>;
  }

  if (data === null || data === undefined || fetching) {
    // Data should be in cache by this point, this is just for type correctness
    return null;
  }

  return <Header userEmail={data.me.email} />;
};

export default HeaderContainer;
