/**
 * @file Create new gql fragment for Message and update cache
 */

import { Cache } from '@urql/exchange-graphcache';
import { gql } from 'urql';

import { MessagesReadMutationVariables } from '../../../../../../../generated/graphql';

/**
 * Create new gql fragment for Message and update cache
 *
 * @param args  The arguments passed along with the mutation
 * @param cache Current GraphQL cache
 */
export const messagesSetSeen = (
  args: MessagesReadMutationVariables,
  cache: Cache,
): void => {
  const fragment = gql`
    fragment _ on Message {
      id
      isSeen
    }
  `;

  if (Array.isArray(args.messageIds)) {
    args.messageIds.forEach(id => {
      cache.writeFragment(fragment, { id, isSeen: true });
    });

    return;
  }

  cache.writeFragment(fragment, { id: args.messageIds, isSeen: true });
};
