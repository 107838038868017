import { IconButton } from '@mui/material';
import { styled } from 'styled-components';

import devices from '../../../../styles/devices';
import RichText from '../../RichText';

export const TopicInfoWrapper = styled.hgroup`
  background-color: var(--color-background);
  display: flex;
  flex-flow: column;
  gap: var(--spacing-small);
  min-height: var(--preference-topic-header-height);
  position: relative;

  @media ${devices.tabletS} {
    z-index: var(--z-subheader);
  }
`;

export const TopicDescription = styled(RichText)`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  flex-grow: 1;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
`;

export const EditButton = styled(IconButton)`
  justify-self: center;
`;
