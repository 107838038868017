/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */
import { Location } from 'react-router-dom';

import { ROUTE__FEED, ROUTE__KANBAN } from '../../constants/routing/routes';
import { SEARCH_PARAM__FILTER__QUICK } from '../../constants/routing/searchParams';
import { getCloseModalUrl } from '../../containers/modals/helpers';
import { Hotel, Topic } from '../../generated/graphql';
import { reportError } from '../../services/reporting';

import { getIsFeedKanbanPage } from '../helpers/pages';

import { parseUrlString, removeParamsKeysFromUrl } from '../helpers/router';

import { getNoMatchUrl } from './404';
import { getHomeUrl } from './home';

/**
 * Gets url for opening selected topic
 *
 * @param hotelId  ID of the hotel
 * @param location Location object from history
 * @param topicId  ID of the topic
 * @returns        A string in a url format
 */
export const getTopicFeedUrl = (
  hotelId: Hotel['id'] | null,
  location: Location | null,
  topicId: Topic['id'] | null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getTopicUrl was null');
    return getNoMatchUrl();
  }

  if (topicId === null) {
    reportError('TopicId passed to getTopicUrl was null');
    return getHomeUrl(hotelId);
  }

  // Keep the search params
  const search = location?.search ?? '';

  return `/${hotelId}/${ROUTE__FEED}/${topicId}${search}`;
};

/**
 * Gets url for opening selected topic
 *
 * @param hotelId  ID of the hotel
 * @param location Location object from history
 * @param topicId  ID of the topic
 * @returns        A string in a url format
 */
export const getTopicUrl = (
  hotelId: Hotel['id'] | null,
  location: Location | null,
  topicId: Topic['id'] | null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getTopicUrl was null');
    return getNoMatchUrl();
  }

  if (topicId === null) {
    reportError('TopicId passed to getTopicUrl was null');
    return getHomeUrl(hotelId);
  }

  const isKanbanPage = getIsFeedKanbanPage();

  // Keep the search params
  const search = location?.search ?? '';

  return `/${hotelId}/${
    isKanbanPage ? ROUTE__KANBAN : ROUTE__FEED
  }/${topicId}${search}`;
};

/**
 * Gets url of topic when switching from one to another
 *
 * @param hotelId  ID of the hotel
 * @param location Location object from history
 * @param topicId  ID of the topic
 * @returns        A string in a url format
 */
export const getTopicChangeUrl = (
  hotelId: Hotel['id'] | null,
  location: Location,
  topicId: Topic['id'] | null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getTopicChangeUrl was null');
    return getNoMatchUrl();
  }

  if (topicId === null) {
    reportError('TopicId passed to getTopicChangeUrl was null');
    return getHomeUrl(hotelId);
  }

  const isKanbanPage = getIsFeedKanbanPage();

  const [, search] = parseUrlString(
    removeParamsKeysFromUrl(location, SEARCH_PARAM__FILTER__QUICK),
  );

  return `/${hotelId}/${
    isKanbanPage ? ROUTE__KANBAN : ROUTE__FEED
  }/${topicId}${search}`;
};

/**
 * Opens a selected topic
 *
 * @param hotelId           ID of the hotel
 * @param topicId           ID of the topic
 * @param shouldComposeOpen Whether the compose form should be open
 * @returns                 A string in a url format
 */
export const getTopicFromHomeRedirectUrl = (
  hotelId: Hotel['id'] | null,
  topicId: Topic['id'] | null,
  shouldComposeOpen = true,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getTopicFromHomeRedirectUrl was null');
    return getNoMatchUrl();
  }

  if (topicId === null) {
    return `/${hotelId}/${ROUTE__FEED}`;
  }

  const urlSuffix = shouldComposeOpen === true ? '?compose=open' : '';
  return `/${hotelId}/${ROUTE__FEED}/${topicId}${urlSuffix}`;
};

/**
 * Opens newly created topic and closes the modal
 *
 * @param hotelId  ID of the hotel
 * @param location Location object from history
 * @param topicId  ID of the topic
 * @returns        A string in a url format
 */
export const getTopicCreatedUrl = (
  hotelId: Hotel['id'] | null,
  location: Location,
  topicId: Topic['id'] | null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getTopicCreatedUrl was null');
    return getNoMatchUrl();
  }

  if (topicId === null) {
    reportError('TopicId passed to getTopicCreatedUrl was null');
    return getHomeUrl(hotelId);
  }

  // Keep the search params, but remove modal param
  const [, search] = parseUrlString(getCloseModalUrl(location));

  return `/${hotelId}/${ROUTE__FEED}/${topicId}${search}`;
};

/**
 * Opens newly created topic and closes the modal
 *
 * @param hotelId  ID of the hotel
 * @param location Location object from history
 * @param topicId  ID of the topic
 * @returns        A string in a url format
 */
export const getTopicDetailsUrl = (
  hotelId: Hotel['id'] | null,
  location: Location,
  topicId: Topic['id'] | null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getTopicDetailsUrl was null');
    return getNoMatchUrl();
  }

  if (topicId === null) {
    reportError('TopicId passed to getTopicDetailsUrl was null');
    return getHomeUrl(hotelId);
  }

  const isKanbanPage = getIsFeedKanbanPage();

  // Keep the search params
  const { search } = location;

  return `/${hotelId}/${
    isKanbanPage ? ROUTE__KANBAN : ROUTE__FEED
  }/${topicId}/details${search}`;
};
