/**
 * @file Hook that tells us if sidebar (left) is open
 */
import useIsMobile from '../../useIsMobile';

import useSearchParams from './useSearchParams';

/**
 * Hook that tells us if sidebar (left) is open
 * based on the url
 *
 * @summary Always return false on mobile because it's breaking our navigation
 * @returns Whether left sidebar is open
 */
const useIsLeftSidebarOpen = (): boolean => {
  const isMobile = useIsMobile();
  const query = useSearchParams();

  return isMobile === false && query.get('sidebar') !== 'closed';
};

export default useIsLeftSidebarOpen;
