/**
 * @file Hook for scrolling into element from hash tag in referenced DOM element
 */

import { RefObject, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { MessageFullQuery } from '../../../generated/graphql';
import useIsMobile from '../../../hooks/useIsMobile';

/**
 * Scroll into specified element from hash tag in referenced DOM element
 *
 * @param data Dependencies array for re-running effect
 * @param ref  Reference to HTML element where tag is located
 */
const useDetailsScroll = (
  data: MessageFullQuery | undefined,
  ref: RefObject<HTMLElement | null>,
) => {
  const location = useLocation();
  const isMobile = useIsMobile();

  useLayoutEffect(() => {
    if (
      isMobile ||
      location.hash === '' ||
      ref.current === null ||
      data === undefined
    ) {
      return;
    }

    const element = ref.current.querySelector(location.hash);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [location.hash, isMobile, ref, data]);
};

export default useDetailsScroll;
