import React, { FC } from 'react';

import Inner from './Inner';

import * as Styled from './styled';

export type Props =
  | {
      iconName: string;
      isDisabled: boolean;
      label: string;
      onClick?: never;
      type: 'external' | 'internal';
      url: string;
    }
  | {
      iconName: string;
      isDisabled: boolean;
      label: string;
      onClick: () => void;
      type: 'button';
      url?: never;
    };

/**
 * Navigation link used in mobile navigation
 *
 * @param props            Props passed to the component
 * @param props.iconName   Name of the icon
 * @param props.isDisabled If the link should be disabled
 * @param props.label      Label text for the link
 * @param props.onClick    onclick handler for the button if any
 * @param props.type       Whether the link is external, internal or a button
 * @param props.url        Url to route to if any
 * @returns                NavigationLink component
 */
const MobileNavigationLink: FC<Props> = ({
  iconName,
  isDisabled,
  label,
  onClick,
  type,
  url,
}) => {
  if (isDisabled) {
    return (
      <Styled.LinkDisabled>
        <Inner iconName={iconName} label={label} />
      </Styled.LinkDisabled>
    );
  }

  if (type === 'external') {
    return (
      <Styled.ExternalLink href={url} rel="noopener noreferrer" target="_blank">
        <Inner iconName={iconName} label={label} />
      </Styled.ExternalLink>
    );
  }

  if (type === 'internal') {
    return (
      <Styled.InternalLink to={url}>
        <Inner iconName={iconName} label={label} />
      </Styled.InternalLink>
    );
  }

  if (type === 'button') {
    return (
      <Styled.Button onClick={onClick as () => void} type="button">
        <Inner iconName={iconName} label={label} />
      </Styled.Button>
    );
  }

  return null;
};

export default MobileNavigationLink;
