/**
 * @file Hook that contains logic for compose meta link props
 */

import { useLocation, useNavigate } from 'react-router-dom';

import { getLinkToggleProps } from '../../../components/Compose/Common/ComposeMeta/helpers';
import useComposeMode from '../../../hooks/router/search/useComposeMode';
import useIsMobile from '../../../hooks/useIsMobile';
import { ComposeModeParam } from '../../../models/pageParams';

/**
 * Hook that it used for navigating between compose form views on mobile and,
 * popups on desktop
 *
 * @param mode Compose mode we need the navigation info and handlers
 * @returns    Object containing everything we need to navigate properly within compose form
 */
const useComposeLinking = (mode: ComposeModeParam) => {
  const composeMode = useComposeMode();
  const isActive = composeMode === mode;
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();

  const linkToggleProps = getLinkToggleProps(isActive, mode, location);

  /**
   * Callback for going back that solves issue of having to click
   * multiple times on the back arrow on mobile.
   */
  const goBack = () => {
    if (isMobile && isActive === false) {
      navigate(-1);
    } else {
      navigate(linkToggleProps.to, { replace: true });
    }
  };

  return {
    goBack,
    isActive,
    linkToggleProps,
  };
};

export default useComposeLinking;
