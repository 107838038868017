/**
 * @file Comment proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorMessage } from './navigators';

const mapperComment = {
  [NotificationEvent.CommentCreated]: navigatorMessage,
  [NotificationEvent.CommentCreatedForAssignees]: navigatorMessage,
  [NotificationEvent.CommentCreatedForCreator]: navigatorMessage,
  [NotificationEvent.CommentExternalCreated]: navigatorMessage,
  [NotificationEvent.CommentExternalCreatedForAssignees]: navigatorMessage,
};

export default mapperComment;
