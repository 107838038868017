import React, { FC } from 'react';

import MessageDetails from './MessageDetails';
import MessageFull from './MessageFull';
import useMessageRead from './hooks/useMessageRead';
import { Props } from './props';

import * as Styled from './styled';

/**
 * Message component (MessageFull or MessageDetails based on mode)
 *
 * @param props                          Props passed to the component
 * @param props.className                styled-components generated class name, needed for styling
 * @param props.mode                     Message mode
 * @param props.message                  The message to show the details for
 * @param props.requestChecklistCreate   Request that a checklist item is added
 * @param props.requestChecklistDelete   Request that an item is deleted
 * @param props.requestChecklistReorder  Request reordering of checklist items
 * @param props.requestChecklistSetState Request state update for an item
 * @param props.requestChecklistSetText  Request text update for an item
 * @param props.requestTextUpdate        Request message text update
 * @param props.requestTitleUpdate       Request message title update
 * @param props.translation              Translations for the message
 * @returns                              The component itself
 */
const Message: FC<Props> = props => {
  const { mode } = props;

  /**
   * We're looking at the message details,
   * so mark it as read if it's not already so,
   * and there are no comments to it
   * (if there are, we mark is as read on comments open)
   */
  useMessageRead();

  return (
    <Styled.Section className={props.className}>
      {mode === 'details' ? (
        <MessageDetails {...props} />
      ) : (
        <MessageFull {...props} />
      )}
    </Styled.Section>
  );
};

export default Message;
