import React, { FC, ReactNode } from 'react';

import * as Styled from './styled';

export type Props = {
  children: ReactNode;
  description: string;
  title: string;
};

/**
 * Dashboard wrapper component with title and description
 *
 * @param props             Props passed to the component
 * @param props.children    Children passed to the component (components that display dashboard KPIs)
 * @param props.description Description of the page
 * @param props.title       Title of the page
 * @returns                 The component itself
 */
const ContentWrapper: FC<Props> = ({ children, description, title }) => {
  return (
    <Styled.PageWrapper>
      <Styled.PageTitle>{title}</Styled.PageTitle>
      <Styled.PageDescription>{description}</Styled.PageDescription>
      {children}
    </Styled.PageWrapper>
  );
};
export default ContentWrapper;
