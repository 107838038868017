/**
 * @file Update the selectedHotel param for the user
 */

import { useEffect } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Hotel,
  Maybe,
  MeDocument,
  UserSelectHotelDocument,
} from '../../generated/graphql';
import { reportApiErrors } from '../../utils/error';

/**
 * Update the selectedHotel param for the user
 *
 * @param hotelId The ID of the hotel to mark as selected
 */
const useUserSelectedHotel = (hotelId: Maybe<Hotel['id']>) => {
  const [{ data, fetching }] = useQuery({
    query: MeDocument,
    variables: {},
  });
  const [, userSelectHotelMutation] = useMutation(UserSelectHotelDocument);

  const selectedHotel = data?.me.selectedHotel;

  // Check if current hotelId from the url matches hotelId from me query and update it if it doesn't on component mount
  useEffect(() => {
    if (fetching === false && selectedHotel !== hotelId) {
      userSelectHotelMutation({}).catch(reportApiErrors);
    }
  }, [fetching, hotelId, userSelectHotelMutation, selectedHotel]);
};

export default useUserSelectedHotel;
