// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roq-chat-message-bell {
  height: var(--size-bell-icon);
  width: var(--size-bell-icon);
}

.roq-chat-message-bell__button,
.roq-chat-message-bell__badge,
.roq-chat-message-bell__button__icon {
  height: 100%;
  width: 100%;
}

.roq-chat-message-bell__button__icon {
  align-items: center;
  display: flex;
  justify-content: center;
}

.roq-chat-message-bell__badge .roq-badge__count {
  background-color: var(--color-message-new);
  border-radius: 50%;
  font-size: 0;
  height: var(--size-badge-circle);
  position: absolute;
  right: 0;
  top: var(--spacing-small);
  width: var(--size-badge-circle);
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/_bell-icon.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;;;EAGE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,YAAY;EACZ,gCAAgC;EAChC,kBAAkB;EAClB,QAAQ;EACR,yBAAyB;EACzB,+BAA+B;AACjC","sourcesContent":[".roq-chat-message-bell {\n  height: var(--size-bell-icon);\n  width: var(--size-bell-icon);\n}\n\n.roq-chat-message-bell__button,\n.roq-chat-message-bell__badge,\n.roq-chat-message-bell__button__icon {\n  height: 100%;\n  width: 100%;\n}\n\n.roq-chat-message-bell__button__icon {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n\n.roq-chat-message-bell__badge .roq-badge__count {\n  background-color: var(--color-message-new);\n  border-radius: 50%;\n  font-size: 0;\n  height: var(--size-badge-circle);\n  position: absolute;\n  right: 0;\n  top: var(--spacing-small);\n  width: var(--size-badge-circle);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
