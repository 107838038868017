/**
 * @file Helper functions to generate links for settings navigation
 */

import { Hotel } from '../../generated/graphql';
import {
  createSettingsNotificationsEmailPath,
  createSettingsNotificationsEmailSinglePath,
  createSettingsNotificationsPath,
  createSettingsNotificationsPushPath,
  createSettingsNotificationsPushSinglePath,
  createSettingsTemplateEditPath,
  createSettingsTemplatesPath,
} from '../../routes/paths/settings';
import {
  getSettingsGroupsUrl,
  getSettingsLabelsUrl,
  getSettingsNotificationsEmailUrl,
  getSettingsNotificationsPushUrl,
  getSettingsPeopleUrl,
  getSettingsTemplatesUrl,
  getSettingsTopicsUrl,
} from '../../routes/urls/settings';
import { getCanViewTemplatesTable } from '../../utils/permissions/template';
import { getCanViewUsersTable } from '../../utils/permissions/user';
import { getIsReactNativeWebView } from '../../utils/webview/helpers';

type Link = {
  matchUrl?: string[];
  label: string;
  url: string;
  visibility?: 'desktop' | 'mobile' | 'both' | 'none';
};

/**
 * Links to serve in the Settings navigation
 *
 * @param hotelId  ID of the hotel
 * @param isMobile Whether the user is ona small screen device
 * @returns        Array of settings links
 */
export const generateLinks = (
  hotelId: Hotel['id'] | null,
  isMobile: boolean,
): Link[] => {
  const linksInternal: Link[] = [
    {
      label: 'SETTINGS__TOPICS',
      url: getSettingsTopicsUrl(hotelId),
    },
    {
      label: 'SETTINGS__TEMPLATES',
      matchUrl: [
        createSettingsTemplateEditPath(),
        createSettingsTemplatesPath(),
      ],
      url: getSettingsTemplatesUrl(hotelId),
      visibility: getCanViewTemplatesTable() ? 'both' : 'none',
    },
    {
      label: 'SETTINGS__PEOPLE',
      url: getSettingsPeopleUrl(hotelId),
      visibility: getCanViewUsersTable() ? 'both' : 'none',
    },
    {
      label: 'SETTINGS__GROUPS',
      url: getSettingsGroupsUrl(hotelId),
    },
    {
      label: 'SETTINGS__LABELS',
      url: getSettingsLabelsUrl(hotelId),
    },
    {
      label: 'SETTINGS__NOTIFICATIONS',
      matchUrl: [
        createSettingsNotificationsEmailPath(),
        createSettingsNotificationsEmailSinglePath(),
        createSettingsNotificationsPath(),
        createSettingsNotificationsPushPath(),
        createSettingsNotificationsPushSinglePath(),
      ],
      url: getSettingsNotificationsPushUrl(hotelId),
      visibility: 'desktop',
    },
    {
      label: 'SETTINGS__NOTIFICATIONS__EMAIL',
      url: getSettingsNotificationsEmailUrl(hotelId),
      visibility: 'mobile',
    },
    {
      label: 'SETTINGS__NOTIFICATIONS__PUSH',
      url: getSettingsNotificationsPushUrl(hotelId),
      visibility: 'mobile',
    },
  ];

  const showFor = new Set(['both', isMobile ? 'mobile' : 'desktop']);
  return linksInternal.filter(({ visibility = 'both' }) =>
    showFor.has(visibility),
  );
};

/**
 * Generate external links to show on the settings page
 *
 * @returns The links to show
 */
export const generateLinksExternal = (): Link[] => [
  {
    label: 'SETTINGS__LEGAL__NOTICE',
    url: getIsReactNativeWebView()
      ? 'SETTINGS__LEGAL__NOTICE__URL__APP'
      : 'SETTINGS__LEGAL__NOTICE__URL__WEB',
  },
  {
    label: 'SETTINGS__PRIVACY__POLICY',
    url: getIsReactNativeWebView()
      ? 'SETTINGS__PRIVACY__POLICY__URL__APP'
      : 'SETTINGS__PRIVACY__POLICY__URL__WEB',
  },
];
