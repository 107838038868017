import { ChatMessageBell } from '@roq/ui-react';
import React, { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import { getChatUrl } from '../../routes/urls/chat';

import { useIsChatEnabled } from './useIsChatEnabled';

type Props = {
  children: ReactNode;
};

/**
 * Chat bubble icon used in mobile to open the chat conversations list.
 *
 * @param props          Props passed to the component
 * @param props.children Bubble icon
 * @returns              Chat bubble
 */
export const ChatBubble: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const hotelId = useParamHotelId();
  const isEnabled = useIsChatEnabled();

  if (isEnabled !== true) {
    return null;
  }

  return (
    <ChatMessageBell
      icon={children}
      onClick={() => navigate(getChatUrl(hotelId))}
    />
  );
};
