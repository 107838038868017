import React, { FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import useParamHotelId from '../../hooks/router/params/useParamHotelId';

import useIsMobile from '../../hooks/useIsMobile';

import translate from '../../i18n/translate';
import { getSettingsUrl } from '../../routes/urls/settings';

import Chevron from '../Common/Chevron';
import NavigationLink from '../Common/NavigationLink';

import { generateLinks, generateLinksExternal } from './links';
import * as Styled from './styled';
import { ExternalLink } from './styled';

/**
 * Settings navigation element
 *
 * @returns Settings navigation component
 */
const SettingsNavigation: FC = () => {
  /**
   * Used to match the url to find if link should be selected or not
   */
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const hotelId = useParamHotelId();

  const settingsHome = getSettingsUrl(hotelId);
  const linksInternal = generateLinks(hotelId, isMobile);
  const linksExternal = generateLinksExternal();

  /**
   * Tells if currently iterated link is selected.
   * If we want to mark link as selected for only one url
   * than we pas single url that is type of string, else if we
   * want to mark link as selected for multiple urls than we pass
   * array of urls to match with.
   *
   * @param matchUrl String or array of strings to match with
   * @returns        Returns boolean
   */
  const getCurrentlySelected = (matchUrl: string | string[]): boolean => {
    // We're on settings homepage, no left sidebar link should be active
    if (pathname === settingsHome) {
      return false;
    }

    if (typeof matchUrl === 'string') {
      return matchPath(matchUrl, pathname) !== null;
    } else {
      return matchUrl.some(url => matchPath(url, pathname));
    }
  };

  return (
    <nav>
      <Styled.Ul>
        {linksInternal.map(({ label, url, matchUrl = url }) => {
          return (
            <Styled.Li key={label}>
              <NavigationLink
                isSelected={getCurrentlySelected(matchUrl)}
                label={translate(label)}
                url={url}
              />
            </Styled.Li>
          );
        })}

        {linksExternal.map(({ label, url }) => {
          /**
           * Renders external links for legal notice
           * and privacy policy and opens a new tab,
           * on mobile we add an additional chevron
           */
          return (
            <Styled.Li key={label}>
              <ExternalLink
                href={translate(url)}
                rel="noreferrer"
                target="_blank"
              >
                {translate(label)}
                {isMobile && <Chevron direction="right" size={15} />}
              </ExternalLink>
            </Styled.Li>
          );
        })}
      </Styled.Ul>
    </nav>
  );
};

export default SettingsNavigation;
