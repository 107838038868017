import React, { FC } from 'react';

import { PREFERENCE__SEARCH_RESULTS__TRIGGER } from '../../constants/pagination';
import { MessageSearch } from '../../models/message';
import SkeletonFeedWrapper from '../Loading/FeedWrapper';

import SearchResultElement from './SearchResultElement';
import useScrollRestore from './hooks/useScrollRestore';
import * as Styled from './styled';

type Props = {
  isLoading: boolean;
  messages: MessageSearch[] | undefined;
  requestLoadMore: (() => void) | null;
};

/**
 * Search results component
 *
 * @param props                 Props passed to the component
 * @param props.isLoading       Whether the data is being loaded
 * @param props.messages        Topic description shown in the header
 * @param props.requestLoadMore Request load more messages callback
 * @returns                     The component itself
 */
const SearchResults: FC<Props> = ({
  isLoading,
  messages = [],
  requestLoadMore,
}) => {
  const { scrollToIndex, scrollToRef } = useScrollRestore(messages.length);

  return (
    <SkeletonFeedWrapper
      isInitialLoad={false}
      shouldShowAbove={isLoading}
      shouldShowBelow={false}
    >
      <Styled.Ul>
        {messages.map((message, index) => {
          // Load more only if second to last element is visible and all results aren't loaded
          const shouldLoadMore =
            index === messages.length - PREFERENCE__SEARCH_RESULTS__TRIGGER;

          const shouldSetRef = scrollToIndex === index;

          return (
            <li key={message.id} ref={shouldSetRef ? scrollToRef : undefined}>
              <SearchResultElement
                message={message}
                requestLoadMore={shouldLoadMore ? requestLoadMore : null}
              />
            </li>
          );
        })}
      </Styled.Ul>
    </SkeletonFeedWrapper>
  );
};

export default SearchResults;
