import React, { ComponentProps, FC, ReactNode, useId } from 'react';
import { LinkProps } from 'react-router-dom';

import { ComposeNodeType } from '../../containers/compose/props';
import {
  User,
  UserBasicFragment,
  UserGroup,
  UserGroupBasicFragment,
} from '../../generated/graphql';
import translate from '../../i18n/translate';
import intlPluralFormatter from '../../utils/intlPluralFormat';
import userGetName from '../../utils/user/userGetName';
import MessageAssigneesForm from '../Forms/MessageAssigneesForm';
import MetaContainerInline from '../MetaContainerInline';
import MetaContainerPopup from '../MetaContainerPopup';

type Props = {
  className?: string;
  dataGroupsAll: UserGroupBasicFragment[];
  dataGroupsAssigned: Set<UserGroup['id']>;
  dataGroupsSaved: Set<UserGroup['id']>;
  dataMembersAll: UserBasicFragment[];
  dataMembersAssigned: Set<User['id']>;
  dataMembersSaved: Set<User['id']>;
  dataSearchValue: string;
  inputCustom?: ReactNode;
  isActive: boolean;
  isLoading: boolean;
  linkToggleProps?: LinkProps;
  nodeType?: ComposeNodeType;
  onAssignUpdateGroup: (groupId: UserGroup['id'], state: boolean) => void;
  onAssignUpdateMember: (memberId: User['id'], state: boolean) => void;
  onSearch: (term: string) => void;
  requestAssignClose?: () => void;
  requestAssignSave: () => void;
};

/**
 * Assignment popup for the compose block
 *
 * @param props                      Props passed to the component
 * @param props.className            styled-components generated class name, needed for styling
 * @param props.dataGroupsAll        All available groups
 * @param props.dataGroupsAssigned   Groups checked
 * @param props.dataGroupsSaved      Groups saved
 * @param props.dataMembersAll       All available members
 * @param props.dataMembersAssigned  Members checked
 * @param props.dataMembersSaved     Members saved
 * @param props.dataSearchValue      <input type="search" /> value attribute
 * @param props.inputCustom          Component to use for overview in mobile view
 * @param props.isActive             Whether the component should be visible
 * @param props.isLoading            Whether the data is still being loaded
 * @param props.linkToggleProps      Props for the toggle link
 * @param props.nodeType             Whether the compose form is being used for messages or templates
 * @param props.onAssignUpdateGroup  Callback to invoke when a group is (un)checked
 * @param props.onAssignUpdateMember Callback to invoke when a member is (un)checked
 * @param props.onSearch             Callback to invoke when search term is changed
 * @param props.requestAssignClose   Callback to be invoked when a label is a/detached
 * @param props.requestAssignSave    Request that the current assignment is saved
 * @returns                          The component itself
 */
const MetaAssignment: FC<Props> = ({
  className,
  dataGroupsAll,
  dataGroupsAssigned,
  dataGroupsSaved = dataGroupsAssigned,
  dataMembersAll,
  dataMembersAssigned,
  dataMembersSaved = dataMembersAssigned,
  dataSearchValue,
  inputCustom,
  isActive,
  isLoading,
  linkToggleProps = null,
  nodeType = 'MESSAGE',
  onAssignUpdateGroup,
  onAssignUpdateMember,
  onSearch,
  requestAssignSave,
  requestAssignClose,
}) => {
  /**
   * We need to check if currently assigned groups/members are equal to draft/saved groups/members
   * so we can disable save button if they are equal.
   */
  const isSaveDisabled =
    dataGroupsSaved.size === dataGroupsAssigned.size &&
    dataMembersSaved.size === dataMembersAssigned.size &&
    Array.from(dataGroupsSaved).every(value => dataGroupsAssigned.has(value)) &&
    Array.from(dataMembersSaved).every(value => dataMembersAssigned.has(value));

  const idForm = useId();
  const idHeading = useId();

  // Popup top buttons' labels
  const labelConfirm = translate('GENERAL__SAVE');
  const labelClose = translate('GENERAL__CLOSE');

  const textPlaceholder = translate('ASSIGNMENT__EDIT__PLACEHOLDER');
  const textHeading = textPlaceholder;

  // Props used for both inline and popup meta
  const propsCommon: ComponentProps<
    typeof MetaContainerInline | typeof MetaContainerPopup
  > = {
    className,
    hasMobilePadding: false,
    idForm,
    idHeading,
    isSaveDisabled,
    labelClose,
    labelConfirm,
    onClose: requestAssignClose,
    textHeading,
  };

  const children = (
    <MessageAssigneesForm
      dataGroupsAll={dataGroupsAll}
      dataGroupsAssigned={dataGroupsAssigned}
      dataMembersAll={dataMembersAll}
      dataMembersAssigned={dataMembersAssigned}
      dataSearchValue={dataSearchValue}
      idForm={idForm}
      isLoading={isLoading}
      nodeType={nodeType}
      onAssignUpdateGroup={onAssignUpdateGroup}
      onAssignUpdateMember={onAssignUpdateMember}
      onSearch={onSearch}
      requestAssignSave={requestAssignSave}
    />
  );

  if (linkToggleProps === null) {
    return isActive ? (
      <MetaContainerInline {...propsCommon}>{children}</MetaContainerInline>
    ) : null;
  }

  const namesAssignees = dataMembersAll
    .filter(member => dataMembersSaved.has(member.id))
    .map(member => userGetName(member));

  const namesGroups = dataGroupsAll
    .filter(group => dataGroupsSaved.has(group.id))
    .map(group => group.title);

  const namesSaved = [...namesAssignees, ...namesGroups];

  return (
    <MetaContainerPopup
      {...propsCommon}
      inputCustom={inputCustom}
      linkProps={linkToggleProps}
      placeholder={textPlaceholder}
      shouldShowPopup={isActive}
      value={intlPluralFormatter(namesSaved)}
    >
      {children}
    </MetaContainerPopup>
  );
};

export default MetaAssignment;
