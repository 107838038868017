import { Stack, styled } from '@mui/material';

import breakpoints from '../../constants/breakpoints';

export const Wrapper = styled(Stack)(({ theme }) => ({
  '&:not(:has([data-is-dragging="true"]))': {
    scrollSnapType: 'x mandatory',
  },
  alignItems: 'flex-start',
  flexDirection: 'row',
  gap: theme.spacing(1),
  height: '100%',
  overflowX: 'auto',
  padding: theme.spacing(1),
  scrollPadding: theme.spacing(1),
  [theme.breakpoints.up(breakpoints.tabletS)]: {
    overflowX: 'hidden',
    scrollPadding: 0,
    scrollSnapType: 'none',
  },
}));
