/**
 * @file Redux mappers for leftSidebarFeed container
 */

import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import AppState from '../../models/state';

import { scrollRememberSidebar } from '../../store/actions/scroll';

import { DispatchProps, OwnProps, StateProps } from './types';

/**
 * Map Redux state to component props
 *
 * @param state Current Redux state
 * @returns     Mapped state
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  scrollPosition: state.scroll.sidebar,
});

/**
 * Map Redux action dispatch to component props
 *
 * @param dispatch Redux action dispatcher
 * @returns        Mapped state
 */
export const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => ({
  setScrollPosition: position => dispatch(scrollRememberSidebar(position)),
});
