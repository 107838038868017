import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

import ContentDesktop from '../../components/Dashboard/ContentDesktop';
import ContentMobile from '../../components/Dashboard/ContentMobile';
import ContentWrapper from '../../components/Dashboard/ContentWrapper';
import DashboardError from '../../components/Errors/DashboardError';
import DashboardSkeleton from '../../components/Loading/DashboardSkeleton';
import { DashboardKpiDocument } from '../../generated/graphql';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useIsMobile from '../../hooks/useIsMobile';
import translate from '../../i18n/translate';

import { getFormattedData } from './helpers';

/**
 * Dashboard component with KPIs
 *
 * @returns The component itself
 */
const DashboardContent: FC = () => {
  const [{ data, error, fetching }] = useQuery({
    query: DashboardKpiDocument,
    requestPolicy: 'cache-and-network',
    variables: {},
  });

  const hotelId = useParamHotelId();
  const isMobile = useIsMobile();
  const location = useLocation();

  const dataMissingText = translate(
    'PLACEHOLDER__DATA_UNAVAILABLE',
    translate('DASHBOARD__TITLE'),
  );
  const description = translate('DASHBOARD__DESCRIPTION');
  const title = translate('DASHBOARD__OVERVIEW');

  if (fetching) {
    return (
      <ContentWrapper description={description} title={title}>
        <DashboardSkeleton isMobile={isMobile} />
      </ContentWrapper>
    );
  }

  if (error) {
    return (
      <ContentWrapper description={description} title={title}>
        <DashboardError />
      </ContentWrapper>
    );
  }

  if (!data) {
    return <div>{dataMissingText}</div>;
  }
  const { dashboardKpi } = data;

  const formattedData = getFormattedData({
    dashboardKpi,
    hotelId,
    location,
  });

  const content = isMobile ? (
    <ContentMobile data={formattedData} />
  ) : (
    <ContentDesktop data={formattedData} />
  );

  return (
    <ContentWrapper description={description} title={title}>
      {content}
    </ContentWrapper>
  );
};

export default DashboardContent;
