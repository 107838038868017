/**
 * @file Contains components customization for Material UI
 */

import { Components } from '@mui/material';

import MuiAvatar from './MuiAvatar';
import MuiButton from './MuiButton';
import MuiChip from './MuiChip';
import MuiIconButton from './MuiIconButton';

const components: Components = {
  MuiAvatar,
  MuiButton,
  MuiChip,
  MuiIconButton,
};

export default components;
