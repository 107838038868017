import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import { PREFERENCE__COMMENTS__LIMIT } from '../../constants/pagination';
import {
  CommentCreateDocument,
  CommentsDocument,
} from '../../generated/graphql';
import useTranslatedMessages from '../../hooks/store/useTranslatedMessages';
import { Comment } from '../../models/comment';

/**
 * Hook for handling comments updates
 *
 * @param messageId        The message to show the comments for
 * @param numberOfComments Total number of comments
 * @param pause            Flag that indicate whether query should be paused
 * @returns                Object containing variables and functions necessary for updating comments
 */
const useComments = (
  messageId: string,
  numberOfComments: number,
  pause: boolean,
) => {
  const [lastCursor, setLastCursor] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    after: '',
    first: PREFERENCE__COMMENTS__LIMIT,
  });

  const isTranslated = useTranslatedMessages().has(messageId);

  const [, commentCreateMutation] = useMutation(CommentCreateDocument);

  const [{ data, error }, refetchComments] = useQuery({
    // Backend doesn't allow fetching of comments for archived messages
    pause,
    query: CommentsDocument,
    variables: {
      ...paginationOptions,
      includeTranslations: isTranslated,
      messageId,
    },
  });

  const hasMoreComments = data?.comments?.pageInfo?.hasNextPage === true;

  useEffect(() => {
    // Do not translate if no comments
    if (isTranslated && numberOfComments > 0) {
      setPaginationOptions({
        after: '',
        first: data?.comments?.nodes?.length ?? PREFERENCE__COMMENTS__LIMIT,
      });
    }
    // Fetch translated comments only when translate button is hit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTranslated]);

  useEffect(() => {
    // Set end cursor on each data update in order to have start cursor for load more
    setLastCursor(data?.comments?.pageInfo?.endCursor ?? '');
  }, [data?.comments?.pageInfo?.endCursor]);

  /**
   * !!This is a monkey patch!!.
   * What is the purpose of this?
   * When creating a new topic, and a new message, and creating a new reply,
   * the commentInfo subscription doesn't arrive to the FE (even tho it's active).
   * Since messageInfo is active, we can measure numberOfComments and use that
   * to refetch the comments query. This way works both for the initiator and the user looking at messages.
   * This solution is not ideal as every time new comment arrives we will have 1 extra fetch.
   *
   * @todo replace this with working sub when subscriptions are investigated.
   */
  useEffect(() => {
    // Override the original cache-only policy
    refetchComments({ requestPolicy: 'cache-and-network' });
  }, [numberOfComments, refetchComments]);

  /**
   * Request that a message is submitted
   *
   * @param isForExternal Whether the message is intended for team members or the guest that posted the message
   * @param text          Content of the comment
   * @returns             Comment create mutation
   */
  const createComment = useCallback(
    (isForExternal: boolean, text: string) =>
      commentCreateMutation({
        includeTranslations: isTranslated,
        isForExternal,
        messageId,
        text,
      }),
    [commentCreateMutation, isTranslated, messageId],
  );

  /**
   * The user has clicked to load more comments,
   * so we set the pagination cursor to reflect that
   */
  const loadMoreComments = useCallback(() => {
    if (hasMoreComments === true) {
      setPaginationOptions({
        ...paginationOptions,
        after: lastCursor,
      });
    }
  }, [hasMoreComments, lastCursor, paginationOptions]);

  const comments = sortBy(data?.comments.nodes as Comment[], 'createdAt');

  // Since urql does not change fetching state for paginated queries,
  // loading more comments will be indicated by different cursor values.
  // When next comments are loaded cursor will be set to new value.
  // If no comments, there's nothing to load.
  const isLoadingNewComments =
    lastCursor === paginationOptions.after && numberOfComments > 0;

  return {
    comments,
    createComment,
    error,
    hasMoreComments,
    isLoadingNewComments,
    loadMoreComments,
  };
};

export default useComments;
