import React, { FC, useId } from 'react';
import { useQuery } from 'urql';

import Select from '../../components/Common/Select';
import HotelInfo from '../../components/HotelInfo';
import { MeDocument } from '../../generated/graphql';
import useUserSelectedHotel from '../../hooks/hotel/useUserSelectedHotel';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import { Item } from '../../hooks/useSelect';
import translate from '../../i18n/translate';
import { getDashboardUrl } from '../../routes/urls/dashboard';
import { reportError } from '../../services/reporting';
import { getHotelPermissionsById } from '../../utils/hotel';

import HotelInfoLinkBack from './HotelInfoLinkBack';

import * as Styled from './styled';

/**
 * The container for the hotel and user info in the left sidebar
 * Fetches the currently selected topic
 *
 * @returns The component itself
 */
const HotelInfoContainer: FC = () => {
  const [{ data, error, fetching }] = useQuery({
    query: MeDocument,
    variables: {},
  });

  const selectId = useId();
  const hotelId = useParamHotelId();
  useUserSelectedHotel(hotelId);

  /**
   * Selected hotel change
   *
   * @param value Id of the selected hotel
   */
  const handleOnChange = (value: string): void => {
    const isCurrentHotel = hotelId === value;

    if (isCurrentHotel) {
      return;
    }

    // Redirect with reload to clear cache
    // In order to check permissions of selected hotel and not previous one,
    // user is redirected to dashboard url where check is performed
    // with correct values
    window.location.href = getDashboardUrl(value);
  };

  if (!data || fetching === true) {
    // Data should be in cache by this point, this is just for type correctness
    return null;
  }

  if (error) {
    reportError(error);
    return <div>{translate('PLACEHOLDER__ERROR')}</div>;
  }

  const { hotelPermissions } = data.me;

  const hotelPermission = getHotelPermissionsById(hotelId, hotelPermissions);

  if (hotelPermission === null) {
    /** @todo handle this error somehow */
    reportError('HotelInfo: Could not find your hotel.');
    return null;
  }

  const { id, imageUrl, name } = hotelPermission.hotel;

  const defaultItem = {
    label: <HotelInfo imageUrl={imageUrl} title={name} />,
    labelText: name,
    value: id,
  };

  const items: Item[] = hotelPermissions.map(({ hotel }) => {
    return {
      label: <HotelInfo imageUrl={hotel.imageUrl} title={hotel.name} />,
      labelText: hotel.name,
      value: hotel.id,
    };
  });

  if (hotelPermissions.length === 1) {
    return (
      <Styled.Wrapper>
        <HotelInfoLinkBack />
        <Styled.HotelInfo imageUrl={imageUrl} title={name} />
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <HotelInfoLinkBack />
      <Select
        defaultItem={defaultItem}
        items={items}
        name="switch-current-hotel"
        onChange={handleOnChange}
        placeholder={translate('HOTEL__DROPDOWN__PLACEHOLDER')}
        selectId={selectId}
      />
    </Styled.Wrapper>
  );
};

export default HotelInfoContainer;
