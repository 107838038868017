import { styled } from 'styled-components';

import breakpoints from '../../constants/breakpoints';

import devices from '../../styles/devices';
import Icon from '../Common/Icon';
import LinkIcon from '../Common/LinkIcon';

// Wrapper around the entire component
export const Wrapper = styled.div`
  align-items: center;
  background-color: var(--color-primary-lighter);
  color: var(--color-text-alt);
  display: none;
  grid-template-columns: 1fr minmax(100px, min(35vw, 800px)) 1fr;
  padding: var(--spacing-small) var(--spacing-medium);
  width: 100%;

  @media ${devices.tabletS} {
    display: grid;
  }
`;

// A single side column within the header
// (left: logo+CMS links; right: reservations+settings+help+profile links)
export const Column = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spacing-medium);

  &:first-child {
    padding-left: var(--spacing-tiny);
  }

  &:last-child {
    justify-content: flex-end;
  }
`;

// Gastfreund logo
export const Logo = styled(Icon)`
  /** Needs to be exact 4px, so we cannot use tiny here */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  margin-top: 4px;
`;

// Reservations, Settings, Help and Profile links
const SecondaryLink = styled(LinkIcon)`
  background-color: var(--color-primary);
  border-radius: var(--border-radius-small);
  transition: background-color var(--preference-transition-duration-normal);

  & svg {
    padding: var(--spacing-tiny);
    stroke: var(--color-background);
  }

  &[aria-current='page'] {
    background-color: var(--color-hover-icon);
  }

  &[aria-current='page'] svg {
    stroke: var(--color-primary);
  }

  /**
   * Because of useIsTablet hook and bug fix regarding empty screen we need to add -1 here so breakpoints are in sync
   */
  @media (max-width: ${breakpoints.laptopS - 1}px) {
    display: none;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-hover-icon);
      color: var(--color-primary);

      & svg {
        stroke: var(--color-primary);
      }
    }
  }
`;

// Link to the reservations table
export const LinkReservations = styled(SecondaryLink)`
  & svg {
    padding: var(--spacing-small);
  }
`;

// Link to the settings page
export const LinkSettings = styled(SecondaryLink)`
  & svg {
    padding: var(--spacing-tiny);
  }
`;

// Link to the help page
export const LinkHelp = styled(SecondaryLink)`
  & svg {
    padding: var(--spacing-small);
  }
`;
