import { styled } from 'styled-components';

import { visuallyHidden } from '../../../styles/common';

import SkeletonLine from '../SkeletonLine';

// Wrapper around both the title and the description
export const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-small);
  min-height: var(--preference-topic-header-height);
  position: relative;
`;

// A single skeleton line; Not exported, but reused for title and description
const Line = styled(SkeletonLine)`
  min-height: var(--spacing-medium);
`;

// Used for accessibility, annotating what the skeleton is for
export const LoadingDescription = styled.p`
  ${visuallyHidden};
`;

// Title line
export const SkeletonTitle = styled(Line)`
  width: 25%;
`;

// Description line
export const SkeletonDescription = styled(Line)`
  width: 35%;
`;
