/**
 * @file Hook for deciding whether settings header should be hidden on mobile
 */
import useIsComposeOpen from '../../../../hooks/router/search/useIsComposeOpen';
import useRecurringMode from '../../../../hooks/router/search/useRecurringMode';
import {
  getIsTemplatesPage,
  getIsTemplatesRecurringPage,
} from '../../../../routes/helpers/pages';

/**
 * Calculate whether header should be hidden on mobile based on current page and compose/recurring mode
 *
 * @returns Whether header should be hidden on mobile
 */
const useShouldHideLayoutHeader = () => {
  const isComposeOpen = useIsComposeOpen();
  const isTemplates = getIsTemplatesPage();
  const isTemplatesRecurring = getIsTemplatesRecurringPage();
  const recurringMode = useRecurringMode();

  const isTemplateComposeOpen = isComposeOpen === true && isTemplates === true;
  const isRecurringPopupOpen =
    recurringMode !== null && isTemplatesRecurring === true;

  return isTemplateComposeOpen || isRecurringPopupOpen;
};

export default useShouldHideLayoutHeader;
