import { EditorContent } from '@tiptap/react';
import { styled } from 'styled-components';

import Device from '../../../models/device';
import { borderFocusStyle, visuallyHidden } from '../../../styles/common';
import { getQueryByDevice } from '../../../styles/getQuery';
import CommonInput from '../Input';

import { EditorUsage } from './types';

type TextareaProps = {
  'data-breakpoint-min': Device | null;
  'data-editor-usage': EditorUsage;
};

type WrapperProps = {
  'data-invalid': boolean;
};

/**
 * Get media query to use for the textarea
 *
 * @param props Props passed to the textarea
 * @returns     The media query to use
 */
const getQuery = (props: TextareaProps): string => {
  const min = props['data-breakpoint-min'] ?? props['data-breakpoint-min'];
  return getQueryByDevice(min, null);
};

/** @todo Replace data-invalid='true' when :user-invalid is supported on chrome */
export const Wrapper = styled.div<WrapperProps>`
  border: var(--border-dark);
  border-radius: var(--border-radius-small);

  &:focus-within {
    border-color: var(--color-outline);
  }

  &[data-invalid='true'] {
    border-color: var(--color-button-danger);
  }
`;

export const Editor = styled(EditorContent)<TextareaProps>`
  border: none;
  border-radius: inherit;
  font-size: var(--font-baseline);
  max-height: 20vh;
  max-width: 100%;
  min-height: ${props =>
    props['data-editor-usage'] === 'comment' ||
    props['data-editor-usage'] === 'commentExternal'
      ? '3vh'
      : '18vh'};
  ${borderFocusStyle};
  overflow: auto;
  transition: background-color var(--preference-transition-duration-normal);
  word-break: break-word;

  @media ${getQuery} {
    display: flex;
    min-height: 3vh;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  & .ProseMirror {
    height: 100%;
    padding: var(--spacing-small);
    transition: inherit;
    width: 100%;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  & .ProseMirror:focus-visible {
    background-color: var(--color-background);
    border: none;
    box-shadow: none;
    outline: none;
    resize: vertical;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  & .ProseMirror p.is-editor-empty:first-child::before {
    color: var(--color-placeholder);
    content: attr(data-placeholder);
    pointer-events: none;
    position: absolute;
  }

  & a {
    color: var(--color-primary-light);

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  & dl,
  & ol,
  & ul {
    margin: 0 var(--spacing-normal);
    padding-left: var(--spacing-large);
  }

  & ol {
    list-style-type: decimal;
  }

  & dl,
  & ul {
    list-style-type: disc;
  }
`;

export const Input = styled(CommonInput)`
  display: block;
  padding: var(--spacing-small);
  width: 100%;
`;

export const Textarea = styled.textarea`
  display: block;
  ${visuallyHidden};
`;
