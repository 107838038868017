import React, { FC } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';
import { CssVariable } from '../../../styles/cssTypes';

import * as Styled from './styled';

export type Props = {
  action?: {
    color?: CssVariable;
    label: string;
    onClick: () => void;
  } | null;
  className?: string;
  idHeading?: string;
  idSubheading?: string;
  isPopup?: boolean;
  labelClose?: string;
  onClose?: () => void;
  textHeading?: string;
  textSubheading?: string;
};

/**
 * Header for meta box
 *
 * @param props                Props passed to the component
 * @param props.action         Props for an additional button in the heading
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.idHeading      id attribute for the component heading
 * @param props.idSubheading   id attribute for the component subheading
 * @param props.isPopup        Is container popup or not (used for margin)
 * @param props.labelClose     The label for the close, x, button
 * @param props.onClose        Callback to be invoked on cancel
 * @param props.textHeading    The text to show for heading
 * @param props.textSubheading The text to show for subheading
 * @returns                    The component itself
 */
const MetaHeader: FC<Props> = ({
  action = null,
  className,
  idHeading,
  idSubheading,
  isPopup = false,
  labelClose,
  onClose = null,
  textHeading,
  textSubheading,
}) => {
  const isMobile = useIsMobile();

  return (
    <Styled.Header className={className} data-is-popup={isPopup}>
      {onClose !== null && isMobile && (
        <Styled.BackButton label={labelClose} onClick={onClose} />
      )}
      <Styled.Title id={idHeading}>{textHeading}</Styled.Title>
      <Styled.Subheading id={idSubheading}>{textSubheading}</Styled.Subheading>
      {action && (
        <Styled.ActionButton
          data-color={action.color}
          onClick={action.onClick}
          type="button"
        >
          {action.label}
        </Styled.ActionButton>
      )}
      {onClose !== null && !isMobile && (
        <Styled.CloseButton label={labelClose} onClick={onClose} />
      )}
    </Styled.Header>
  );
};

export default MetaHeader;
