import React, { FC } from 'react';

import SmartViews from '../../../components/CollapsibleLists/SmartViews';
import Topics from '../../../components/CollapsibleLists/Topics';
import DashboardLink from '../../../components/Dashboard/Link';
import { TopicsListQuery } from '../../../generated/graphql';
import { getCanViewDashboard } from '../../../utils/permissions/dashboard';

import ButtonTopicCreate from './ButtonTopicCreate';

import * as Styled from './styled';

export type Props = {
  getUrlCreate: string | null;
  isLoading: boolean;
  topics: TopicsListQuery['topics'];
};

/**
 * Shows a content for leftSidebarFeed component (TopicList, ButtonTopicCreate and SmartViews)
 *
 * @param props              props passed to the component
 * @param props.getUrlCreate Get the topic creation url (null if the user isn't allowed to create topics)
 * @param props.isLoading    Whether the topics are being fetched
 * @param props.topics       List of all topics
 * @returns                  The component itself
 */
const LeftSidebarFeedContent: FC<Props> = ({
  getUrlCreate,
  isLoading,
  topics,
}) => {
  const shouldShowDashboardLink = getCanViewDashboard();

  return (
    <Styled.Wrapper>
      {shouldShowDashboardLink && <DashboardLink />}
      <Topics isLoading={isLoading} topics={topics} />
      <ButtonTopicCreate getUrlCreate={getUrlCreate} />
      <SmartViews />
    </Styled.Wrapper>
  );
};

export default LeftSidebarFeedContent;
