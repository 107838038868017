import { Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import QuickFiltersContainer from '../../../containers/quickFilters';
import { TopicWithMembersFragment } from '../../../generated/graphql';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';

import { getIsFeedKanbanPage } from '../../../routes/helpers/pages';
import { getIsSearchParamFilterActive } from '../../../routes/helpers/searchParams';
import { getTopicKanbanUrl } from '../../../routes/urls/kanban';
import {
  getTopicDetailsUrl,
  getTopicFeedUrl,
} from '../../../routes/urls/topic';

import SegmentedButton from '../../MUI/SegmentedButton';

import TopicInfoSection from './TopicInfoSection/TopicInfoSection';
import * as Styled from './styled';

export type Props = {
  isLoading: boolean;
  isSearchFeed?: boolean;
  topic: TopicWithMembersFragment;
};

/**
 * Feed header component on the feed
 *
 * @param props              Props passed to the component
 * @param props.isLoading    Whether the data is being fetched
 * @param props.isSearchFeed Whether the FeedHeader is used for SearchFeed/SmartViews or not
 * @param props.topic        Current topic
 * @returns                  The component itself
 */
const FeedHeader: FC<Props> = ({ isLoading, isSearchFeed = false, topic }) => {
  const [quickFiltersVisible, setQuickFiltersVisible] = useState(false);
  const isMobile = useIsMobile();
  const location = useLocation();
  const hotelId = useParamHotelId();
  const topicId = useParamTopicId();

  const isKanban = getIsFeedKanbanPage();
  const isFilter = getIsSearchParamFilterActive(location);

  const labelFeed = translate('GENERAL__FEED');
  const labelTasks = translate('GENERAL__TASKS');

  const { title } = topic;
  const topicDetailsUrl = getTopicDetailsUrl(hotelId, location, topicId);

  if (isSearchFeed) {
    return (
      <Styled.Wrapper data-is-kanban={isKanban}>
        <Typography variant="h2">{title}</Typography>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper data-is-kanban={isKanban}>
      {isMobile === false && (
        <TopicInfoSection
          isLoading={isLoading}
          topic={topic}
          topicDetailsUrl={topicDetailsUrl}
        />
      )}
      <Styled.SegmentedButtonGroupWrapper>
        <Styled.SegmentedButtonGroup size="small">
          <SegmentedButton
            aria-label={labelFeed}
            isActive={!isKanban}
            to={getTopicFeedUrl(hotelId, location, topicId)}
          >
            {labelFeed}
          </SegmentedButton>
          <SegmentedButton
            aria-label={labelTasks}
            isActive={isKanban}
            to={getTopicKanbanUrl(hotelId, location, topicId)}
          >
            {labelTasks}
          </SegmentedButton>
        </Styled.SegmentedButtonGroup>
      </Styled.SegmentedButtonGroupWrapper>
      <Styled.QuickFiltersButton
        badgeCount={isFilter ? 1 : 0}
        isSelected={quickFiltersVisible}
        requestChange={() => setQuickFiltersVisible(!quickFiltersVisible)}
      />
      {quickFiltersVisible && (
        <Styled.QuickFiltersWrapper>
          <QuickFiltersContainer />
        </Styled.QuickFiltersWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default FeedHeader;
