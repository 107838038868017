import { ChatWindow } from '@roq/ui-react';
import React, { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import FloatingBottomNav from '../../../components/FloatingBottomNav';
import AppLayout from '../../../components/layouts/AppLayout';
import FeedLeftSidebarContainer from '../../../containers/leftSidebarFeed';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useIsLeftSidebarOpen from '../../../hooks/router/search/useIsLeftSidebarOpen';
import useIsMobile from '../../../hooks/useIsMobile';
import useIsTablet from '../../../hooks/useIsTablet';
import { getHomeUrl } from '../../../routes/urls/home';

import { useIsChatEnabled } from '../useIsChatEnabled';

import ChatConversationPageHeader from './Header';

/**
 * App layout containing the chat window (conversation)
 * as the main component.
 *
 * @returns Chat page
 */
export const ChatConversationPage: FC = () => {
  const { conversationId } = useParams();
  const isMobile = useIsMobile();
  const isLeftSidebarOpen = useIsLeftSidebarOpen();
  const isTablet = useIsTablet();
  const isEnabled = useIsChatEnabled();
  const hotelId = useParamHotelId();

  if (isEnabled === null) {
    return null;
  }

  if (isEnabled === false || conversationId === undefined) {
    return <Navigate to={getHomeUrl(hotelId)} />;
  }

  return (
    <AppLayout
      footer={isMobile ? <FloatingBottomNav /> : null}
      header={<ChatConversationPageHeader />}
      leftSidebar={{
        clickOutsideOptions: {
          stopExec: !isTablet || !isLeftSidebarOpen,
        },
        component: (
          <FeedLeftSidebarContainer shouldShowDesktop={isLeftSidebarOpen} />
        ),
        shouldShowDesktop: isLeftSidebarOpen,
        shouldShowMobile: false,
      }}
    >
      <ChatWindow conversationId={conversationId} />
    </AppLayout>
  );
};
