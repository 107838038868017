import { styled } from 'styled-components';

import Button from '../../Common/ButtonSingle';

export const ButtonHeader = styled(Button)`
  background-color: var(--color-primary-light);
  color: var(--color-text-alt);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  height: fit-content;
  margin-top: var(--spacing-medium);
  transition:
    background-color var(--preference-transition-duration-normal),
    color var(--preference-transition-duration-normal);

  &:not(:disabled) {
    border-color: var(--color-primary-light);

    &:hover {
      background-color: var(--color-primary-light);
      border-color: var(--color-text-light);
      color: var(--color-text-alt);
    }
  }
`;
