/**
 * @file Palette Configuration for Material UI
 */

export const colorError = {
  50: '#fdeaee',
  100: '#fccad2',
  200: '#ea949a',
  300: '#df6a74',
  500: '#df6a74', // 500 is required (we do not have it in figma, there we use 300)
  contrastText: '#000',
} as const;

export const colorSuccess = {
  100: '#c9e5d2',
  200: '#a8d4b7',
  300: '#86c59c',
  500: '#df6a74', // 500 is required (we do not have it in figma, there we use 300)
  contrastText: '#000',
} as const;

export const colorNeutral = {
  300: '#eeeeee',
  400: '#cfcfcf',
  500: '#b2b2b2',
  600: '#878787',
  700: '#727272',
  800: '#525252',
  900: '#303030',
  contrastText: '#fff',
} as const;

export const colorPrimary = {
  50: '#e5eefd',
  100: '#c4d4e6',
  200: '#a4b7cd',
  300: '#849ab4',
  400: '#6c85a0',
  500: '#374f67', // Required by material ui -> same like 700 (from figma)
  600: '#46627d',
  700: '#374f67',
  800: '#293d51',
  900: '#17293a',
  contrastText: '#fff',
} as const;

export const colorSecondary = {
  500: '#00aae8',
  600: '#009cda',
  700: '#0089c7',
  A400: '#00aae8', // Same like 500 (from figma), but required in for the secondary palette
  contrastText: '#000',
} as const;

export const colorTertiary = {
  200: '#efeb8f',
  300: '#e9e462',
  400: '#e5df3d',
  500: '#e1da00',
  contrastText: colorPrimary[700],
} as const;
